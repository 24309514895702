import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ladda/dist/ladda.min.css';

// import 'ant-design-pro/dist/ant-design-pro.css';
import './index.scss';
import './assets/css/font-awesome.css';
import App from './App';

import './media.css';

import configureStore from 'appRedux/store';
import { Provider } from 'react-redux';

// import {persistStore, persistReducer} from 'redux-persist';
// import {PersistGate} from 'redux-persist/lib/integration/react';

export const store = configureStore();
// const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    {/*<PersistGate loading={<h3>Loading...</h3>} persistor={persistor}>*/}
    <App />
    {/*</PersistGate>*/}
  </Provider>,
  document.getElementById('root'),
);
