import api from '../api';
import {
  FETCH_AIRTIME_FAILED,
  FETCH_AIRTIME_START,
  FETCH_AIRTIME_SUCCESS,
  FETCH_SINGLE_AIRTIME_FAILED,
  FETCH_SINGLE_AIRTIME_START,
  FETCH_SINGLE_AIRTIME_SUCCESS,
  GET_AIRTIME,
  SEND_FRIEND_AIRTME_FAILED,
  SEND_FRIEND_AIRTME_START,
  SEND_FRIEND_AIRTME_SUCCESS,
  SEND_SELF_AIRTME_FAILED,
  SEND_SELF_AIRTME_START,
  SEND_SELF_AIRTME_SUCCESS,
} from '../constants';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { openNotificationWithIcon } from './Common';
import { fetchCart } from './Cart';

export const getAirtimes = params => {
  return dispatch => {
    dispatch({ type: FETCH_AIRTIME_START });

    api
      .get(GET_AIRTIME, { params })
      .then(({ data }) => {
        dispatch({ type: FETCH_AIRTIME_SUCCESS, payload: data });
        // return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_AIRTIME_FAILED });
        // callback({ error: true, ...e });
      });
  };
};

export const getSingleAirtime = data => {
  return dispatch => {
    dispatch({ type: FETCH_SINGLE_AIRTIME_START });

    api
      .get(GET_AIRTIME + `/${data}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SINGLE_AIRTIME_SUCCESS, payload: data });
        // return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_SINGLE_AIRTIME_FAILED });
        // callback({ error: true, ...e });
      });
  };
};

export const sendAirtimeItemToFriend = (data, callback) => {
  return dispatch => {
    dispatch({ type: SEND_FRIEND_AIRTME_START });
    dispatch(showLoading());
    const url = `/Orders/SendAirtimeToFriend`;
    const params = JSON.stringify(data, null, 2);

    api
      .post(url, params)
      .then(resp => {
        if (resp.status === 200) {
          openNotificationWithIcon(
            'Please continue shopping or move to checkout ',
            `Airtime added`,
            'success',
          );
          const data = resp.data;
          dispatch({ type: SEND_FRIEND_AIRTME_SUCCESS });
          dispatch(fetchCart());
          dispatch(hideLoading());
          return callback();
        }
      })
      .catch(error => {
        openNotificationWithIcon(error.message, `Error!`, 'error');
        dispatch({ type: SEND_FRIEND_AIRTME_FAILED });
        dispatch(hideLoading());
        return callback();
      });
  };
};

export const sendAirtimeItemToSelf = (data, callback) => {
  return dispatch => {
    dispatch({ type: SEND_SELF_AIRTME_START });

    dispatch(showLoading());
    const url = `/Orders/SendAirtimeToSelf`;
    const params = JSON.stringify(data, null, 2);

    api
      .post(url, params)
      .then(resp => {
        if (resp.status === 200) {
          openNotificationWithIcon(
            'Please continue shopping or move to checkout ',
            `Airtime added`,
            'success',
          );
          const data = resp.data;
          dispatch({ type: SEND_SELF_AIRTME_SUCCESS });
          dispatch(fetchCart());
          dispatch(hideLoading());
          return callback();
        }
      })
      .catch(error => {
        openNotificationWithIcon(error.message, `Error!`, 'error');
        dispatch({ type: SEND_SELF_AIRTME_FAILED });
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};
