import {
  FETCH_GIFT_CARDS_FAILED,
  FETCH_GIFT_CARDS_START,
  FETCH_GIFT_CARDS_SUCCESS,
  GET_GIFT_CARDS,
  SEND_FRIEND_GCARD_FAILED,
  SEND_FRIEND_GCARD_START,
  SEND_FRIEND_GCARD_SUCCESS,
  SEND_SELF_GCARD_FAILED,
  SEND_SELF_GCARD_START,
  SEND_SELF_GCARD_SUCCESS,
  FETCH_GIFT_CARDS_CATEGORIES_START,
  GET_GIFT_CARDS_CATEGORIES,
  FETCH_GIFT_CARDS_CATEGORIES_SUCCESS,
  FETCH_GIFT_CARDS_CATEGORIES_FAILED,
  FETCH_SINGLE_GIFT_CARDS_START,
  FETCH_SINGLE_GIFT_CARDS_SUCCESS,
  FETCH_SINGLE_GIFT_CARDS_FAILED,
  ADD_GCARD_TO_WLIST_START,
  ADD_GCARD_TO_WLIST_FAILED,
  ADD_GCARD_TO_WLIST_SUCCESS,
  FETCH_GIFT_CARDS_FEATURE_START,
  FETCH_GIFT_CARDS_FEATURE_SUCCESS,
  FETCH_GIFT_CARDS_FEATURE_FAILED,
  GET_FEATURE_GIFT_CARDS,
  FETCH_GIFT_CARDS_LOCATIONS_START,
  GET_GIFT_CARDS_LOCATIONS,
  FETCH_GIFT_CARDS_LOCATIONS_SUCCESS,
  FETCH_GIFT_CARDS_LOCATIONS_FAILED,
} from '../constants';

import api from '../api';
import { openNotificationWithIcon } from './Common';
import { fetchCart } from './Cart';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const getGiftCards = params => {
  return dispatch => {
    dispatch({ type: FETCH_GIFT_CARDS_START });

    api
      .get(GET_GIFT_CARDS, { params })
      .then(({ data }) => {
        dispatch({ type: FETCH_GIFT_CARDS_SUCCESS, payload: data });
        // return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_GIFT_CARDS_FAILED });
        // callback({ error: true, ...e });
      });
  };
};

export const getFeatureGiftCards = params => {
  return dispatch => {
    dispatch({ type: FETCH_GIFT_CARDS_FEATURE_START });

    api
      .get(GET_FEATURE_GIFT_CARDS, { params })
      .then(({ data }) => {
        dispatch({ type: FETCH_GIFT_CARDS_FEATURE_SUCCESS, payload: data });
        // return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_GIFT_CARDS_FEATURE_FAILED });
        // callback({ error: true, ...e });
      });
  };
};

export const getSingleGiftCard = data => {
  return dispatch => {
    dispatch({ type: FETCH_SINGLE_GIFT_CARDS_START });

    api
      .get(GET_GIFT_CARDS + `/${data}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SINGLE_GIFT_CARDS_SUCCESS, payload: data });
        // return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_SINGLE_GIFT_CARDS_FAILED });
        // callback({ error: true, ...e });
      });
  };
};

export const getGiftCardsCategories = () => {
  return dispatch => {
    dispatch({ type: FETCH_GIFT_CARDS_CATEGORIES_START });

    api
      .get(GET_GIFT_CARDS_CATEGORIES)
      .then(({ data }) => {
        dispatch({ type: FETCH_GIFT_CARDS_CATEGORIES_SUCCESS, payload: data });
        // return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_GIFT_CARDS_CATEGORIES_FAILED });
        // callback({ error: true, ...e });
      });
  };
};

export const getGiftCardsLocations = () => {
  return dispatch => {
    dispatch({ type: FETCH_GIFT_CARDS_LOCATIONS_START });

    api
      .get(GET_GIFT_CARDS_LOCATIONS)
      .then(({ data }) => {
        dispatch({ type: FETCH_GIFT_CARDS_LOCATIONS_SUCCESS, payload: data });
        // return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_GIFT_CARDS_LOCATIONS_FAILED });
        // callback({ error: true, ...e });
      });
  };
};

export const sendGiftItemToFriend = (data, callback) => {
  return dispatch => {
    dispatch({ type: SEND_FRIEND_GCARD_START });
    dispatch(showLoading());
    const url = `/Orders/SendGiftToFriend`;
    const params = JSON.stringify(data, null, 2);

    api
      .post(url, params)
      .then(resp => {
        if (resp.status === 200) {
          openNotificationWithIcon(
            'Please continue shopping',
            `Gift card added to cart!`,
            'success',
          ); //Gift Card sent to your Friend!
          const data = resp.data;
          dispatch({ type: SEND_FRIEND_GCARD_SUCCESS });
          dispatch(fetchCart());
          dispatch(hideLoading());
          // redirectDashboard()
          return callback();
        }
      })
      .catch(error => {
        openNotificationWithIcon(error.message, `Error!`, 'error');
        dispatch({ type: SEND_FRIEND_GCARD_FAILED });
        dispatch(hideLoading());
        return callback();
      });
  };
};

export const sendGiftItemToSelf = (data, callback) => {
  return dispatch => {
    dispatch({ type: SEND_SELF_GCARD_START });

    dispatch(showLoading());
    const url = `/Orders/SendGiftToSelf`;
    const params = JSON.stringify(data, null, 2);

    api
      .post(url, params)
      .then(resp => {
        // console.log(resp);
        if (resp.status === 200) {
          openNotificationWithIcon(
            'Please continue shopping',
            `Gift Card add to cart!`,
            'success',
          );
          const data = resp.data;
          dispatch({ type: SEND_SELF_GCARD_SUCCESS });
          dispatch(fetchCart());
          dispatch(hideLoading());
          return callback({ error: false });
        }
      })
      .catch(error => {
        openNotificationWithIcon(error.message, `Error!`, 'error');
        dispatch({ type: SEND_SELF_GCARD_FAILED });
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};

export const addGiftItemToWishlist = (data, callback) => {
  return dispatch => {
    dispatch({ type: ADD_GCARD_TO_WLIST_START });

    dispatch(showLoading());
    const url = `/Orders/AddToWish`;
    const params = JSON.stringify(data, null, 2);

    api
      .post(url, params)
      .then(resp => {
        if (resp.status === 200) {
          openNotificationWithIcon(
            'Please continue shopping',
            `Gift Card added to wishlist!`,
            'success',
          );
          const data = resp.data;
          dispatch({ type: ADD_GCARD_TO_WLIST_SUCCESS });
          dispatch(hideLoading());
          return callback();
        }
      })
      .catch(error => {
        openNotificationWithIcon(error.message, `Error!`, 'error');
        dispatch({ type: ADD_GCARD_TO_WLIST_FAILED });
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};
