import {
  FETCH_ORDERS_FAILED,
  FETCH_ORDERS_START,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDER_FAILED,
  FETCH_ORDER_START,
  FETCH_ORDER_SUCCESS,
} from '../constants';

const INIT_STATE = {
  orders: [],
  order: {},
  loading: false,
  loadingOrder: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ORDERS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_ORDERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        orders: action.payload,
      };
    }
    case FETCH_ORDERS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_ORDER_START: {
      return {
        ...state,
        loadingOrder: true,
      };
    }
    case FETCH_ORDER_SUCCESS: {
      return {
        ...state,
        loadingOrder: false,
        order: action.payload,
      };
    }
    case FETCH_ORDER_FAILED: {
      return {
        ...state,
        loadingOrder: false,
      };
    }
    default:
      return state;
  }
};
