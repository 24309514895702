import Cookies from 'js-cookie';
import { COOKIES_TIME } from 'appRedux/constants';
import _ from 'lodash';

export const setSession = (value, expiration, refreshToken) => {
  const cookieTime = _.isEmpty(getSessionWithKey('expiresIn'))
    ? COOKIES_TIME
    : getSessionWithKey('expiresIn');
  Cookies.set('___session', value, { expires: COOKIES_TIME });
  Cookies.set('___session_expiration', expiration, { expires: COOKIES_TIME });
  Cookies.set('___session_refreshToken', refreshToken, {
    expires: COOKIES_TIME,
  });
};

export const setSessionWithValue = (value, key) => {
  Cookies.set(key, value, { expires: COOKIES_TIME });
};

export const getSessionWithKey = key => {
  return Cookies.get(key);
};
export const getSessionJsonWithKey = key => {
  return Cookies.getJSON(key);
};

export const getPinFromSession = () => {
  // set pin here
};

export const getSession = () => {
  const jwt = Cookies.get('___session');

  let session = '';
  try {
    if (jwt) {
      session = jwt;
      // const base64Url = jwt.split('.')[1]
      // console.log('jwt', base64Url)
      //
      // const base64 = base64Url.replace('-', '+').replace('_', '/')
      // // what is window.atob ?
      // // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      // session = JSON.parse(window.atob(base64))
    }
  } catch (error) {}

  return session;
};

export const clearSessionWithParams = key => {
  return Cookies.remove(key);
};

export const clearSession = () => {
  Cookies.remove('user');
  Cookies.remove('__checkInUse');
  Cookies.remove('___session');
  Cookies.remove('___order_purchase');
  Cookies.remove('___session_expiration');
  Cookies.remove('___session_refreshToken');
};
