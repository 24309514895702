import { GLOBAL_FETCH_START, OPEN_MENU } from '../constants';

const INIT_STATE = {
  display: false,
  error: '',
  loading: false,
  message: '',
  global_loading: false,
  menu: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GLOBAL_FETCH_START: {
      return { ...state, global_loading: true };
    }
    case OPEN_MENU: {
      return { ...state, menu: true };
    }
    default:
      return state;
  }
};
