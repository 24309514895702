import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Input, notification, Spin } from 'antd';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';

import { AccountsVerticalMenu, Loading, PointBalance } from 'components';

import './Profile.scss';
import { connect } from 'react-redux';
import {
  getProfile,
  handleUpdateProfile,
  handleChangePassword,
} from '../../appRedux/action/Auth';
import _ from 'lodash';
import {
  handleFieldError,
  handleNotify,
  handleNotifyOnly,
  encryptedPassword,
} from 'appRedux/api';
import { FETCH_ERROR_MESSAGE, NOTIFICATION_DURATION } from 'appRedux/constants';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const sideMenu = [
  {
    title: 'Summary',
    isActive: false,
    url: '/summary',
  },
  {
    title: 'Point History',
    isActive: false,
    url: '/points-history',
  },
  {
    title: ' Order History',
    isActive: false,
    url: '/order-history',
  },
  {
    title: 'Profile',
    isActive: true,
    url: '/profile',
  },
  {
    title: 'Wishlist',
    isActive: false,
    url: '/wishlist',
  },
  {
    title: 'Logout',
    isActive: false,
    url: '/logout',
  },
];

const validationSchema = Yup.object({
  // homeAddress: Yup.string()
  //   .nullable()
  //   .required('home address is required'),
  // .min(2, 'Too Short!')
  //     .max(50, 'Too Long!'),
  email: Yup.string()
    .email('Invalid email')
    .nullable()
    .required('Email address is required'),
  phone: Yup.string()
    .min(11, 'Too Short!')
    .max(15, 'Too Long!')
    .nullable()
    .required('Contact phone number number is required'),
});

const Profile = props => {
  const {
    userId,
    points,
    fullName,
    accountNumber,
    email,
    homeAddress,
    phoneNumber,
  } = props.authUser;

  const [menu, setMenu] = useState(false);
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);

  const [laddaLoadingSec, setLaddaLoadingSec] = useState(false);
  const [laddaProgressSec, setLaddaProgressSec] = useState(0);

  useEffect(() => {
    props.getProfile(response => {
      //response.error && handleNotifyOnly({message: FETCH_ERROR_MESSAGE}, () => {});
    });
  }, []);

  const handleIn = type => {
    notification['success']({
      message: 'Account!',
      description: 'Account updated successfully',
      duration: NOTIFICATION_DURATION,
    });

    if (type === 'first') {
      props.getProfile(response => {
        //response.error && handleNotifyOnly({message: FETCH_ERROR_MESSAGE}, () => {});
      });
      setLaddaProgress(0.9);
      setLaddaLoading(false);
    } else if (type === 'sec') {
      setLaddaProgressSec(0.9);
      setLaddaLoadingSec(false);
    }
  };

  const { profile_loading } = props;

  return (
    <main className="giftcard-view-section">
      <div className="accounts-container">
        <Button
          onClick={() => setMenu(true)}
          color="secondary"
          className="tstyle"
          size={'large'}
          shape="round">
          {' '}
          Account Side Menu{' '}
        </Button>
        <Row justify="end">
          <Col>
            <PointBalance />
          </Col>
        </Row>

        <Row className="account-columns" gutter={64}>
          <Col
            className={`account-column-1 ${menu &&
              'mobile_menu_profile_holder'}`}
            span={6}>
            <AccountsVerticalMenu
              menuList={sideMenu}
              setMenuOff={() => setMenu(false)}
            />
          </Col>
          <Col className="account-column-2" sm={{ span: 24 }} md={{ span: 18 }}>
            {profile_loading ? (
              <Loading />
            ) : (
              <Row className="content-wrapper">
                <Col span={24}>
                  <div className="profile-name-container">
                    <Row style={{ width: '100%' }} gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <div className="profile-name-wrapper">
                          <h3 className="desc">Name:</h3>
                          <h2 className="title">{fullName}</h2>
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <div className="profile-name-wrapper">
                          <h3 className="desc">Account Number:</h3>
                          <h2 className="title">{accountNumber}</h2>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Formik
                    initialValues={{
                      homeAddress: homeAddress,
                      email: email,
                      phone: phoneNumber,
                      // currentPassword: '',
                      // newPassword: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                      setLaddaLoading(true);
                      setLaddaProgress(0.5);
                      const data = { ...values, userId: userId };
                      props.handleUpdateProfile(data, response =>
                        _.isEmpty(response.error) && response.error !== true
                          ? handleIn('first')
                          : handleNotify(response, setSubmitting, () => {
                              setLaddaProgress(1.0);
                              setLaddaLoading(false);
                              handleFieldError(
                                response.validationErrors,
                                setErrors,
                              );
                            }),
                      );
                    }}>
                    {({
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      values,
                      errors,
                      touched,
                      resetForm,
                      isSubmitting,
                    }) => (
                      <>
                        <Form layout="vertical" onSubmit={handleSubmit}>
                          <div className="stats-wrapper pb-1">
                            <Row gutter={16}>
                              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item
                                  label="Email Address"
                                  colon={false}
                                  style={{ width: '100%', marginRight: 0 }}
                                  validateStatus={
                                    errors.email && touched.email
                                      ? 'error'
                                      : null
                                  }
                                  help={
                                    errors.email && touched.email
                                      ? errors.email
                                      : null
                                  }>
                                  <Input
                                    readOnly={true}
                                    className="fd-input"
                                    size="large"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    placeholder="Enter Email Address"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item
                                  label="Mobile No."
                                  colon={false}
                                  validateStatus={
                                    errors.phone && touched.phone
                                      ? 'error'
                                      : null
                                  }
                                  help={
                                    errors.phone && touched.phone
                                      ? errors.phone
                                      : null
                                  }>
                                  <Input
                                    readOnly={true}
                                    className="fd-input"
                                    size="large"
                                    name="phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phone}
                                    placeholder="Enter Mobile No."
                                  />
                                </Form.Item>
                              </Col>
                              {/*<Col span={24}>*/}
                              {/*  <Form.Item*/}
                              {/*    label="House Address"*/}
                              {/*    colon={false}*/}
                              {/*    validateStatus={*/}
                              {/*      errors.homeAddress && touched.homeAddress*/}
                              {/*        ? 'error'*/}
                              {/*        : null*/}
                              {/*    }*/}
                              {/*    help={*/}
                              {/*      errors.homeAddress && touched.homeAddress*/}
                              {/*        ? errors.homeAddress*/}
                              {/*        : null*/}
                              {/*    }>*/}
                              {/*    <Input*/}
                              {/*      className="fd-input"*/}
                              {/*      size="large"*/}
                              {/*      name="homeAddress"*/}
                              {/*      onBlur={handleBlur}*/}
                              {/*      onChange={handleChange}*/}
                              {/*      value={values.homeAddress}*/}
                              {/*      placeholder="Enter House Address"*/}
                              {/*    />*/}
                              {/*  </Form.Item>*/}
                              {/*</Col>*/}
                            </Row>
                          </div>
                          <div className="summary-footer-wrapper">
                            {/*<LaddaButton*/}
                            {/*  loading={laddaLoading}*/}
                            {/*  progress={laddaProgress}*/}
                            {/*  data-style={SLIDE_UP}*/}
                            {/*  data-spinner-size={30}*/}
                            {/*  data-spinner-color="#fff"*/}
                            {/*  data-spinner-lines={12}*/}
                            {/*  className="fd-button primary mr-1 ant-btn-round"*/}
                            {/*  type="submit"*/}
                            {/*  onClick={handleSubmit}*/}
                            {/*  disabled={isSubmitting}>*/}
                            {/*  Save Details*/}
                            {/*</LaddaButton>*/}
                            {/*<Button*/}
                            {/*    className="fd-button primary mr-1"*/}
                            {/*    type="link"*/}
                            {/*    shape="round"*/}
                            {/*    size={'large'}*/}
                            {/*    disabled={isSubmitting}*/}
                            {/*    onClick={handleSubmit}>*/}
                            {/*    Save Details*/}
                            {/*</Button>*/}
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                  <div className="earnings-title">
                    <h4 className="earnings">Change Password:</h4>
                  </div>
                  <Formik
                    initialValues={{
                      currentPassword: '',
                      password: '',
                      confirmPassword: '',
                    }}
                    validationSchema={Yup.object({
                      currentPassword: Yup.string().required(
                        'Password is required',
                      ),
                      password: Yup.string()
                        .required('Password is required')
                        .matches(
                          /^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
                        ),
                      confirmPassword: Yup.string()
                        .required('Confirm Password is required')
                        .when('password', {
                          is: val => (val && val.length > 0 ? true : false),
                          then: Yup.string().oneOf(
                            [Yup.ref('password')],
                            'Both password need to be the same',
                          ),
                        }),
                    })}
                    onSubmit={(
                      values,
                      { setSubmitting, setErrors, resetForm },
                    ) => {
                      setLaddaLoadingSec(true);
                      setLaddaProgressSec(0.5);
                      values.newPassword = values.password;

                      values.currentPassword = encryptedPassword(
                        values.currentPassword,
                      );
                      values.newPassword = encryptedPassword(
                        values.newPassword,
                      );

                      props.handleChangePassword(values, response => {
                        _.isEmpty(response.error) && response.error !== true
                          ? handleIn('sec')
                          : handleNotify(response, setSubmitting, () => {
                              setLaddaProgressSec(1.0);
                              setLaddaLoadingSec(false);
                              handleFieldError(
                                response.validationErrors,
                                setErrors,
                              );
                            });
                        resetForm();
                      });
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <>
                        <Form layout="vertical" onSubmit={handleSubmit}>
                          <div className="stats-wrapper pb-1">
                            <Row gutter={16}>
                              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                <Form.Item
                                  label="Current Password"
                                  colon={false}
                                  style={{ width: '100%', marginRight: 0 }}
                                  validateStatus={
                                    errors.currentPassword &&
                                    touched.currentPassword
                                      ? 'error'
                                      : null
                                  }
                                  help={
                                    errors.currentPassword &&
                                    touched.currentPassword
                                      ? errors.currentPassword
                                      : null
                                  }>
                                  <Input.Password
                                    className="fd-input"
                                    size="large"
                                    type="password"
                                    name="currentPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.currentPassword}
                                    placeholder="current password"
                                    iconRender={visible =>
                                      visible ? (
                                        <EyeTwoTone />
                                      ) : (
                                        <EyeInvisibleOutlined />
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                <Form.Item
                                  label="New Password"
                                  colon={false}
                                  style={{ width: '100%', marginRight: 0 }}
                                  validateStatus={
                                    errors.password && touched.password
                                      ? 'error'
                                      : null
                                  }
                                  help={
                                    errors.password && touched.password
                                      ? errors.password
                                      : null
                                  }>
                                  <Input.Password
                                    className="fd-input"
                                    size="large"
                                    type="password"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    placeholder="password"
                                    iconRender={visible =>
                                      visible ? (
                                        <EyeTwoTone />
                                      ) : (
                                        <EyeInvisibleOutlined />
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                <Form.Item
                                  label="Confirm Password"
                                  colon={false}
                                  validateStatus={
                                    errors.confirmPassword &&
                                    touched.confirmPassword
                                      ? 'error'
                                      : null
                                  }
                                  help={
                                    errors.confirmPassword &&
                                    touched.confirmPassword
                                      ? errors.confirmPassword
                                      : null
                                  }>
                                  <Input.Password
                                    className="fd-input"
                                    size="large"
                                    type="password"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    placeholder="confirm password"
                                    iconRender={visible =>
                                      visible ? (
                                        <EyeTwoTone />
                                      ) : (
                                        <EyeInvisibleOutlined />
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                        <div className="summary-footer-wrapper">
                          {/* <p className="text">Have another Account?</p> */}

                          <LaddaButton
                            loading={laddaLoadingSec}
                            progress={laddaProgressSec}
                            data-style={SLIDE_UP}
                            data-spinner-size={30}
                            data-spinner-color="#fff"
                            data-spinner-lines={12}
                            className="fd-button primary mr-1 ant-btn-round"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={isSubmitting}>
                            Change Password
                          </LaddaButton>
                          {/*<Button*/}
                          {/*    className="fd-button primary mr-1"*/}
                          {/*    type="link"*/}
                          {/*    shape="round"*/}
                          {/*    size={'large'}*/}
                          {/*    disabled={isSubmitting}*/}
                          {/*    onClick={handleSubmit}>*/}
                          {/*    Change Password*/}
                          {/*</Button>*/}
                        </div>
                      </>
                    )}
                  </Formik>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </main>
  );
};

const mapStateToProps = ({ auth }) => {
  const { authUser, profile_loading } = auth;
  return { profile_loading, authUser };
};

const mapDispatchToProps = {
  getProfile,
  handleUpdateProfile,
  handleChangePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
