import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Tabs,
  Checkbox,
  Image,
  Radio,
  Slider,
  Divider,
  Button,
  Form,
  Input,
  Empty,
  Tag,
  InputNumber,
} from 'antd';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToCart, redirectToGiftCards } from 'route/Redirect';
import validationSchema from './validationSchema';
import {
  sendGiftItemToFriend,
  sendGiftItemToSelf,
} from 'appRedux/action/GiftCards';

import './GiftCardView.scss';
import './media.scss';
import {
  getSingleGiftCard,
  addGiftItemToWishlist,
  getGiftCardsLocations,
} from '../../appRedux/action/GiftCards';
import { handleError } from '../../appRedux/action/Common';
import _ from 'lodash';
import { Loading } from '../../components';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

const { TabPane } = Tabs;
const { TextArea } = Input;

const GiftCardView = props => {
  const dispatch = useDispatch();
  const {
    giftCard,
    loadingGiftCard,
    giftCardsLocations,
    loadingLocations,
  } = useSelector(state => state.giftCards);
  const btnLoading = loadingGiftCard;
  const giftCardDetails = giftCard;
  const giftsCardsDetailLocation = giftCard.locations || [];

  // console.log(giftsCardsDetailLocation);

  const [checked, setChecked] = useState(true);
  const [giftMedium, setGiftMedium] = useState(1);

  const [isSubmittingBtn, setIsSubmittingBtn] = useState(false);
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);

  const [isSubmittingBtnWlist, setIsSubmittingBtnWlist] = useState(false);
  const [laddaLoadingWlist, setLaddaLoadingWlist] = useState(false);
  const [laddaProgressWlist, setLaddaProgressWlist] = useState(0);

  const [giftRecipient, setGiftRecipient] = useState(true);
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const onChange = () => {
    setChecked(!checked);
  };
  const changeRecipient = () => {
    setGiftRecipient(!giftRecipient);
  };

  const shareUrl = 'https://suregifts.com.ng/';
  const shareTitle = giftCardDetails.name;

  useEffect(() => {
    const giftId = parseInt(props.id);
    if (_.isNumber(giftId)) {
      dispatch(getSingleGiftCard(giftId));
      dispatch(getGiftCardsLocations());
    } else {
      handleError('Invalid or no gift details sent');
      redirectToGiftCards();
    }
  }, []);

  useEffect(() => {
    if (
      typeof giftCardDetails.minimumPoints != 'undefined' &&
      typeof giftCardDetails.pointToNaira != 'undefined'
    ) {
      setGiftCardAmount(
        giftCardDetails.minimumPoints * giftCardDetails.pointToNaira,
      );
    }
  }, [giftCardDetails]);

  const handleGiftMedium = event => {
    setGiftMedium(event.target.value);
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      personalMessage: '',
      phoneNumber: '',
    },
    isSubmitting: false,
    validationSchema,
    onSubmit(values) {
      setIsSubmittingBtn(true);
      setLaddaLoading(true);
      setLaddaProgress(0.5);
      const data = {
        ...values,
        giftId: giftCardDetails.giftId,
        amount: giftCardAmount,
      };
      dispatch(
        sendGiftItemToFriend(data, () => {
          setIsSubmittingBtn(false);
          setLaddaLoading(false);
          setLaddaProgress(1.0);
          redirectToCart();
        }),
      );
      resetForm();
      setSubmitting(false);
    },
  });

  const handleSendToSelf = () => {
    setIsSubmittingBtn(true);
    setLaddaLoading(true);
    setLaddaProgress(0.5);
    dispatch(
      sendGiftItemToSelf(
        {
          giftId: giftCardDetails.giftId,
          amount: giftCardAmount,
          medium: giftMedium,
        },
        response => {
          if (_.isEmpty(response.error) && response.error !== true) {
            redirectToCart();
          } else {
            setIsSubmittingBtn(false);
            setLaddaLoading(false);
            setLaddaProgress(1.0);
          }
        },
      ),
    );
  };

  const handleAddToWishlist = () => {
    setIsSubmittingBtnWlist(true);
    setLaddaLoadingWlist(true);
    setLaddaProgressWlist(0.5);
    dispatch(
      addGiftItemToWishlist(
        {
          giftCardId: giftCardDetails.giftId,
        },
        () => {
          setIsSubmittingBtnWlist(false);
          setLaddaLoadingWlist(false);
          setLaddaProgressWlist(1.0);
        },
      ),
    );
  };

  function formatter(value) {
    return (
      <NumberFormat
        value={value}
        displayType={'text'}
        thousandSeparator={true}
        // className="giftcard-pricetag"
      />
    );
  }

  const locationId = [];
  giftsCardsDetailLocation.map(lId => {
    locationId.push(lId);
  });

  function handlePointsSlide(points) {
    let temp = points * giftCardDetails.pointToNaira;
    setGiftCardAmount(temp);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loadingGiftCard ? (
        <Loading />
      ) : (
        <main className="giftcard-view-section">
          {!giftCardDetails ? (
            <Empty
              description={<span>You have not selected any product</span>}
            />
          ) : (
            <Card className="giftcard-card">
              <Row gutter={50} className={'off-margin'}>
                <Col md={{ span: 14 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  {' '}
                  <h2 className="giftcard-title">{giftCardDetails.name}</h2>
                </Col>
                <Col md={{ span: 14 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <div className="giftcard-image">
                    <img
                      src={giftCardDetails.imageUrl}
                      alt={`${giftCardDetails.name} gift card`}
                    />
                  </div>

                  <div className="giftcard-product-details">
                    <Tabs
                      defaultActiveKey="Description"
                      className="giftcard-tabs">
                      <TabPane
                        tab={<h4 className="tabs-title">Description</h4>}
                        key="Description">
                        {giftCardDetails.description}
                      </TabPane>
                      <TabPane
                        tab={<h4 className="tabs-title">Redemption</h4>}
                        key="Redemption">
                        Content of Redemption
                      </TabPane>
                      <TabPane
                        tab={<h4 className="tabs-title">Locations</h4>}
                        key="Locations">
                        {!_.isEmpty(locationId) && (
                          <>
                            {locationId.map((locationId, index) => {
                              const locationData = giftCardsLocations
                                .filter(item => item.id === locationId)
                                .reduce((prev, current) => current);
                              return <Tag key={index}>{locationData.name}</Tag>;
                            })}
                          </>
                        )}
                      </TabPane>
                      {/*<TabPane*/}
                      {/*  tab={<h4 className="tabs-title">Share</h4>}*/}
                      {/*  key="Share">*/}
                      {/*  <div>*/}
                      {/*    <FacebookShareButton*/}
                      {/*      url={shareUrl}*/}
                      {/*      quote={shareTitle}>*/}
                      {/*      <FacebookIcon size={45} className="share-icons" />*/}
                      {/*    </FacebookShareButton>*/}

                      {/*    <TwitterShareButton url={shareUrl} quote={shareTitle}>*/}
                      {/*      <TwitterIcon size={45} className="share-icons" />*/}
                      {/*    </TwitterShareButton>*/}

                      {/*    <WhatsappShareButton*/}
                      {/*      url={shareUrl}*/}
                      {/*      quote={shareTitle}>*/}
                      {/*      <WhatsappIcon size={45} className="share-icons" />*/}
                      {/*    </WhatsappShareButton>*/}
                      {/*  </div>*/}
                      {/*</TabPane>*/}
                    </Tabs>
                  </div>
                </Col>

                {/* section 2 */}
                <Col
                  className="giftcard-section-2"
                  md={{ span: 10 }}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}>
                  <>
                    <Radio.Group
                      className="fd-radios"
                      name="radiogroup"
                      onChange={changeRecipient}
                      defaultValue={true}>
                      <Radio value={true}>Send to friend</Radio>
                      <Radio value={false}>Redeem for self</Radio>
                    </Radio.Group>
                    <div>
                      <div className="giftcard-slider-container">
                        <Row>
                          <Col span={18}>
                            <h4 className="giftcard-pricetag">
                              <NumberFormat
                                value={giftCardAmount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'₦ '}
                                className="giftcard-pricetag"
                              />
                            </h4>
                            <Slider
                              marks={{
                                0: {
                                  style: {
                                    color: '#ffb200',
                                    left: '10px',
                                    top: '15px',
                                    width: '100%',
                                    // transform: 'translateX(0%) !important',
                                  },
                                  label: (
                                    <strong>
                                      <NumberFormat
                                        value={giftCardDetails.minimumPoints}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        suffix={' pts'}
                                      />
                                    </strong>
                                  ),
                                },

                                // ⚠️  must be greater than minimumValue else will
                                100: {
                                  style: {
                                    color: '#ffb200',
                                    // right: '0',
                                    top: '15px',
                                    // width: '100%',
                                    transform: 'translateX(150px)',
                                  },
                                  label: (
                                    <strong>
                                      <NumberFormat
                                        value={giftCardDetails.maximumPoints}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        suffix={' pts'}
                                      />
                                    </strong>
                                  ),
                                },
                              }}
                              range={false}
                              step={50}
                              defaultValue={giftCardDetails.minimumPoints}
                              min={giftCardDetails.minimumPoints}
                              max={giftCardDetails.maximumPoints}
                              onChange={handlePointsSlide}
                              tipFormatter={formatter}
                              disabled={false}
                            />
                          </Col>
                          <Col span={6}>
                            <div className="holder-m-l">
                              <InputNumber
                                size="small"
                                min={giftCardDetails.minimumPoints}
                                max={giftCardDetails.maximumPoints}
                                className="g-amount-fd-input "
                                onChange={handlePointsSlide}
                                value={giftCardAmount}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <br />
                    <Divider />

                    {giftRecipient ? (
                      <div className="redeem-container">
                        <h3 className="title">Recipient Information</h3>
                        <h4 className="subtitle">
                          Details of the friend you want to gift
                        </h4>

                        <>
                          <Form>
                            <Form.Item
                              validateStatus={
                                errors.fullName && touched.fullName
                                  ? 'error'
                                  : null
                              }
                              help={
                                errors.fullName && touched.fullName
                                  ? errors.fullName
                                  : null
                              }>
                              <Input
                                className="fd-input"
                                size="large"
                                name="fullName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.fullName}
                                placeholder="Full Name"
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                errors.email && touched.email ? 'error' : null
                              }
                              help={
                                errors.email && touched.email
                                  ? errors.email
                                  : null
                              }>
                              <Input
                                className="fd-input"
                                size="large"
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                            </Form.Item>
                            <Form.Item
                              // label="Phone Number"
                              // name="phoneNumber"
                              validateStatus={
                                errors.phoneNumber && touched.phoneNumber
                                  ? 'error'
                                  : null
                              }
                              help={
                                errors.phoneNumber && touched.phoneNumber
                                  ? errors.phoneNumber
                                  : null
                              }>
                              <Input
                                className="fd-input"
                                size="large"
                                name="phoneNumber"
                                placeholder="Phone number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phoneNumber}
                              />
                            </Form.Item>
                            <Form.Item>
                              <TextArea
                                className="fd-input"
                                rows={3}
                                size="large"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.personalMessage}
                                name="personalMessage"
                                placeholder="Personal Message"
                              />
                            </Form.Item>
                            <Form.Item>
                              <LaddaButton
                                loading={laddaLoading}
                                progress={laddaProgress}
                                data-style={SLIDE_UP}
                                data-spinner-size={30}
                                data-spinner-color="#fff"
                                data-spinner-lines={12}
                                className="btn-submit btn btn-primary btn-style fd-button primary"
                                type="submit"
                                onClick={handleSubmit}
                                disabled={isSubmittingBtn}>
                                Add To Cart
                              </LaddaButton>
                              <LaddaButton
                                loading={laddaLoadingWlist}
                                progress={laddaProgressWlist}
                                data-style={SLIDE_UP}
                                data-spinner-size={30}
                                data-spinner-color="#fff"
                                data-spinner-lines={12}
                                className="btn-submit btn btn-primary btn-style fd-button grey"
                                type="submit"
                                onClick={handleAddToWishlist}
                                disabled={isSubmittingBtnWlist}>
                                Add to Wishlist
                              </LaddaButton>
                            </Form.Item>
                          </Form>
                        </>
                      </div>
                    ) : (
                      <div className="redeem-container">
                        <h3 className="title">Delivery Information</h3>
                        <h4 className="subtitle">
                          Details of your gift card delivery
                        </h4>

                        <div className="checkbox-wrapper">
                          <Radio.Group
                            className="fd-radios"
                            name="medium"
                            onChange={e => handleGiftMedium(e)}
                            defaultValue={giftMedium}>
                            <Radio className="fd-radios" value={1}>
                              Send to email address
                            </Radio>
                            <br />
                            <br />
                            <Radio className="fd-radios" value={0}>
                              Send to phone number
                            </Radio>
                          </Radio.Group>
                        </div>
                        <div className="mt-5">
                          <LaddaButton
                            loading={laddaLoading}
                            progress={laddaProgress}
                            data-style={SLIDE_UP}
                            data-spinner-size={30}
                            data-spinner-color="#fff"
                            data-spinner-lines={12}
                            className="btn-submit btn btn-primary btn-style fd-button primary"
                            type="submit"
                            onClick={handleSendToSelf}
                            disabled={isSubmittingBtn}>
                            Add to cart
                          </LaddaButton>
                          {/*<Button*/}
                          {/*    className="fd-button primary"*/}
                          {/*    type="submit"*/}
                          {/*    shape="round"*/}
                          {/*    size={'large'}*/}
                          {/*    disabled={btnLoading}*/}
                          {/*    loading={btnLoading}*/}
                          {/*    block>*/}
                          {/*    Add to cart*/}
                          {/*</Button>*/}
                          <LaddaButton
                            loading={laddaLoadingWlist}
                            progress={laddaProgressWlist}
                            data-style={SLIDE_UP}
                            data-spinner-size={30}
                            data-spinner-color="#fff"
                            data-spinner-lines={12}
                            className="btn-submit btn btn-primary btn-style fd-button grey"
                            type="submit"
                            onClick={handleAddToWishlist}
                            disabled={isSubmittingBtnWlist}>
                            Add to Wishlist
                          </LaddaButton>
                        </div>
                      </div>
                    )}
                  </>
                </Col>
              </Row>
            </Card>
          )}
        </main>
      )}
    </>
  );
};

export default GiftCardView;
