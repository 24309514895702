import { applyMiddleware, compose, createStore } from 'redux';
import reducers from 'appRedux/reducers';
import thunk from 'redux-thunk';

const middlewares = [thunk];
// https://dev.to/finallynero/redux-action-stack-trace-1m0d
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      // traceLimit: 25
    })) ||
  compose;

export default function configureStore(initialState) {
  return createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
}
