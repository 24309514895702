import React, { useState, useEffect } from 'react';
import { Row, Col, Radio, Input, Button, Alert, notification } from 'antd';
import { Link } from '@reach/router';

import activationImg from '../../images/activation-img.jpg';
import { Formik } from 'formik';
import _ from 'lodash';
import { FormFeedback } from 'reactstrap';
import LinkComponent from '../../route/LinkComponent';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { handleActivation, handleValidation } from '../../appRedux/action/Auth';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { NOTIFICATION_DURATION } from '../../appRedux/constants';
import {
  redirectVerification,
  redirectToActivation,
  redirectToCreatePassword,
} from '../../route/Redirect';
import { handleFieldError, handleNotify } from '../../appRedux/api';

function Verification(props) {
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState('');
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(false);
  const [notificationType, setNotificationType] = useState('error');

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(props);
    if (_.isEmpty(props.activationAccountNumber)) {
      notification['error']({
        message: 'Invalid account activation!',
        description: 'Please request for activation with your account number',
        duration: NOTIFICATION_DURATION,
      });
      redirectToActivation();
    }
  }, []);

  const onClose = e => {};

  const handleIn = () => {
    notification['success']({
      message: 'Account verification!',
      description: 'Account verified successfully',
      duration: NOTIFICATION_DURATION,
    });
    setLaddaProgress(0.9);
    redirectToCreatePassword();
  };

  const activationCodeSchema = Yup.object().shape({
    ActivationCode: Yup.number()
      // .test(
      //     'len',
      //     'Activation code must be exactly 6 characters',
      //     val => val && val.toString().length === 6,
      // )
      // .positive('Activation code must be greater than zero')
      .typeError('Activation code must be a number')
      .required('Activation code is required'),
  });

  const resendOtp = () => {
    setMessage('Processing...');
    dispatch(
      handleActivation(props.activationAccountNumber, response =>
        _.isEmpty(response.error) && response.error !== true
          ? (() => {
              setMessage('OTP sent');
              notification['success']({
                message: '',
                description: response.message,
                duration: NOTIFICATION_DURATION,
              });
              // console.log(response)
              setTimeout(() => {
                setMessage('');
              }, 400);
            })()
          : (() => {
              const message = response.message;
              setMessage('');
              notification['error']({
                message: 'Error!',
                description: _.isEmpty(message)
                  ? 'Unable to process request. Please try again later'
                  : message,
                duration: NOTIFICATION_DURATION,
              });
            })(),
      ),
    );
  };

  return (
    <>
      <>
        <div className="hero-box">
          <Row className={'hero-box-row'}>
            <Col
              sm={{ span: 24 }}
              md={{ span: 12 }}
              className={'padding_inner'}>
              <div>
                <>
                  <div className="top-form">
                    <div>
                      <h2 className="card_inner_title"> Verification </h2>
                      {/*<p className="card_inner_sub_title">*/}
                      {/*    {' '}*/}
                      {/*    Enter the 6 digits code sent to the phone number*/}
                      {/*    attached to the provided account{' '}*/}
                      {/*</p>*/}
                      <p className="card_inner_sub_title">
                        Passcode will expire in 5mins
                      </p>
                    </div>
                    <div>
                      <Formik
                        initialValues={{ ActivationCode: '' }}
                        validationSchema={activationCodeSchema}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                          setLaddaLoading(true);
                          setLaddaProgress(0.5);

                          props.handleValidation(
                            values.ActivationCode,
                            props.activationAccountNumber,
                            response =>
                              _.isEmpty(response.error) &&
                              response.error !== true
                                ? handleIn()
                                : handleNotify(response, setSubmitting, () => {
                                    setLaddaProgress(1.0);
                                    setLaddaLoading(false);
                                    handleFieldError(
                                      response.validationErrors,
                                      setErrors,
                                    );
                                  }),
                          );
                        }}>
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            className={'login_form '}>
                            <div style={{ position: 'relative' }}>
                              <div
                                className={
                                  'form_holder float_label form-group'
                                }>
                                <div className="form-label-group-holder">
                                  <div className="form-label-group">
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="ActivationCode"
                                      id="inputAccount"
                                      placeholder="Password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.ActivationCode}
                                    />
                                    <label htmlFor="inputAccount">
                                      {' '}
                                      Enter Verification Code{' '}
                                    </label>
                                    {errors.ActivationCode &&
                                      touched.ActivationCode && (
                                        <FormFeedback>
                                          {errors.ActivationCode}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </div>
                              </div>

                              <Button type="text" className={'btn-otp'}>
                                {_.isEmpty(message) && (
                                  <strong
                                    style={{ cursor: 'pointer' }}
                                    onClick={resendOtp}>
                                    Resend OTP
                                  </strong>
                                )}
                                {!_.isEmpty(message) && (
                                  <strong> {message} </strong>
                                )}
                              </Button>
                            </div>

                            <LaddaButton
                              loading={laddaLoading}
                              progress={laddaProgress}
                              data-style={SLIDE_UP}
                              data-spinner-size={30}
                              data-spinner-color="#fff"
                              data-spinner-lines={12}
                              className="btn-submit btn btn-primary btn-style"
                              type="submit"
                              disabled={isSubmitting}>
                              Continue
                            </LaddaButton>
                          </form>
                        )}
                      </Formik>
                    </div>

                    <div className={'text-center'}>
                      <div className="dot" />
                      <div className="text-center" style={{ marginTop: 28 }}>
                        <p>
                          {' '}
                          Have an account?{' '}
                          <LinkComponent
                            to={'/login'}
                            className="forget_password_link">
                            {' '}
                            Login{' '}
                          </LinkComponent>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Col>
            <Col
              sm={{ span: 0 }}
              md={{ span: 12 }}
              className={'ver_side_image access_img'}
            />
          </Row>
        </div>
      </>
    </>
  );
}

export default connect(
  ({ auth }) => {
    const { activationAccountNumber } = auth;
    return { activationAccountNumber };
  },
  { handleValidation },
)(Verification);
