import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Tabs,
  Divider,
  Button,
  Form,
  Input,
  Alert,
} from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RegionDropdown } from 'react-country-region-selector';
import _ from 'lodash';
import numberFormatter from 'number-formatter';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import LaddaButton, { SLIDE_UP } from 'react-ladda';

import './ProductView.scss';
import './media.scss';

import { addProductToCart, getSingleProduct } from 'appRedux/action/Products';
import { handleError } from 'appRedux/action/Common';
import { redirectToProducts } from 'route/Redirect';
import { phoneRegExp, pointFormat } from 'helpers';
import { Loading } from 'components';

const { TabPane } = Tabs;

const validationSchema = Yup.object({
  fullName: Yup.string().required('Full name is empty!'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required("'Phone Number' is required."),
  homeAddress: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!'),
  city: Yup.string().required(),
  state: Yup.string().required(),
});

const ProductView = props => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);

  const loading = useSelector(state => state.products.loadingSingleProduct);
  const productDetails = useSelector(state => state.products.singleProduct);

  const shareUrl = 'https://suregifts.com.ng/';
  const shareTitle = productDetails.name;

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      fullName: '',
      phoneNumber: '',
      homeAddress: '',
      city: '',
      state: '',
    },
    validationSchema,
    onSubmit(values) {
      setBtnLoading(true);
      setLaddaLoading(true);
      setLaddaProgress(0.5);
      const data = {
        ...values,
        productId: productDetails.productId,
      };
      dispatch(
        addProductToCart(data, () => {
          setBtnLoading(false);
          setLaddaLoading(false);
          setLaddaProgress(1.0);
        }),
      );
      resetForm();
      setSubmitting(false);
    },
  });

  useEffect(() => {
    const productId = parseInt(props.id);
    if (_.isNumber(productId)) {
      dispatch(
        getSingleProduct(props.id, error => {
          if (error.error) {
            handleError(error.message);
            redirectToProducts();
          }
        }),
      );
    } else {
      handleError('Invalid or no product details sent');
      redirectToProducts();
    }
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="giftcard-view-section">
        {loading ? (
          <Loading />
        ) : (
          <Card className="giftcard-card">
            <Row
              gutter={{ xs: 25, sm: 25, md: 25, lg: 50 }}
              className={'off-margin'}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }}>
                <div className="d-block d-md-none">
                  <div className="redeem-container">
                    <h3 className="title">{productDetails.name}</h3>
                  </div>
                  <div>
                    <h4 className="giftcard-pricetag">
                      {numberFormatter(pointFormat, productDetails.amount)} pts
                    </h4>
                  </div>
                </div>
                <div className="productcard-image">
                  <img
                    src={`${productDetails.imageUrl}`}
                    alt={`${productDetails.name} product`}
                  />
                </div>

                <div className="giftcard-product-details">
                  <Tabs
                    defaultActiveKey="Description"
                    className="giftcard-tabs">
                    <TabPane
                      tab={<h4 className="tabs-title">Description</h4>}
                      key="Description">
                      {productDetails.description}
                    </TabPane>
                    <TabPane
                      tab={<h4 className="tabs-title">Specifications</h4>}
                      key="Specifications">
                      {productDetails.specification}
                    </TabPane>
                    {/*<TabPane*/}
                    {/*  tab={<h4 className="tabs-title">Share</h4>}*/}
                    {/*  key="Share">*/}
                    {/*  <div>*/}
                    {/*    <FacebookShareButton url={shareUrl} quote={shareTitle}>*/}
                    {/*      <FacebookIcon size={45} className="share-icons" />*/}
                    {/*    </FacebookShareButton>*/}

                    {/*    <TwitterShareButton url={shareUrl} quote={shareTitle}>*/}
                    {/*      <TwitterIcon size={45} className="share-icons" />*/}
                    {/*    </TwitterShareButton>*/}

                    {/*    <WhatsappShareButton url={shareUrl} quote={shareTitle}>*/}
                    {/*      <WhatsappIcon size={45} className="share-icons" />*/}
                    {/*    </WhatsappShareButton>*/}
                    {/*  </div>*/}
                    {/*</TabPane>*/}
                  </Tabs>
                </div>
              </Col>

              {/* section 2 */}
              <Col
                className="giftcard-section-2 product-order-section"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 10 }}>
                <div className="d-none d-md-block">
                  <div className="redeem-container">
                    <h3 className="title">{productDetails.name}</h3>
                  </div>
                  <div>
                    <h4 className="giftcard-pricetag">
                      {numberFormatter(pointFormat, productDetails.amount)} pts
                    </h4>
                  </div>
                </div>

                <Divider></Divider>

                <div className="redeem-container">
                  <h3 className="title">Delivery Information</h3>
                  <h4 className="subtitle">
                    How do you want to receive your product
                  </h4>

                  <Form>
                    <Form.Item
                      validateStatus={
                        errors.fullName && touched.fullName ? 'error' : null
                      }
                      help={
                        errors.fullName && touched.fullName
                          ? errors.fullName
                          : null
                      }>
                      <Input
                        className="fd-input"
                        name="fullName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.fullName}
                        placeholder="Full Name"
                      />
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.phoneNumber && touched.phoneNumber
                          ? 'error'
                          : null
                      }
                      help={
                        errors.phoneNumber && touched.phoneNumber
                          ? errors.phoneNumber
                          : null
                      }>
                      <Input
                        className="fd-input"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phoneNumber}
                        placeholder="Phone number"
                      />
                    </Form.Item>

                    <Form.Item
                      validateStatus={
                        errors.homeAddress && touched.homeAddress
                          ? 'error'
                          : null
                      }
                      help={
                        errors.homeAddress && touched.homeAddress
                          ? errors.homeAddress
                          : null
                      }>
                      <Input
                        className="fd-input"
                        name="homeAddress"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.homeAddress}
                        placeholder="Home Address"
                      />
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.city && touched.city ? 'error' : null
                      }
                      help={errors.city && touched.city ? errors.city : null}>
                      <Input
                        className="fd-input"
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.city}
                        placeholder="City"
                      />
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.state && touched.state ? 'error' : null
                      }
                      help={
                        errors.state && touched.state ? errors.state : null
                      }>
                      <RegionDropdown
                        className="ant-input fd-input"
                        defaultOptionLabel="Select State"
                        country={'Nigeria'}
                        value={values.state}
                        onChange={e => setFieldValue('state', e)}
                      />
                    </Form.Item>
                    {productDetails.amount <= 0 ? (
                      <Form.Item>
                        <Alert
                          message="Low Points!"
                          description="You cannot add items with Zero points to Cart. Return and pick a product with points"
                          type="info"
                          showIcon
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item>
                        {/* <Button
                          className="fd-button primary"
                          type="submit"
                          shape="round"
                          loading={btnLoading}
                          disabled={btnLoading}
                          onClick={handleSubmit}
                          block>
                          Add to cart
                        </Button> */}
                        <LaddaButton
                          loading={laddaLoading}
                          progress={laddaProgress}
                          data-style={SLIDE_UP}
                          data-spinner-size={30}
                          data-spinner-color="#fff"
                          data-spinner-lines={12}
                          className="btn-submit btn btn-primary btn-style fd-button primary"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={btnLoading}>
                          Add to cart
                        </LaddaButton>
                        {/* <Button
                          className="fd-button grey"
                          type="submit"
                          shape="round"
                          block>
                          Add to wishlist
                        </Button> */}
                      </Form.Item>
                    )}
                  </Form>
                </div>
              </Col>
            </Row>
          </Card>
        )}
      </main>
    </>
  );
};

export default ProductView;
