import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Table, Input, DatePicker, Button } from 'antd';

import { AccountsVerticalMenu, PointBalance } from 'components';
import { connect } from 'react-redux';
import { getTransactions } from '../../appRedux/action/Transactions';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import './PointsHistory.scss';
import { pointFormat } from 'helpers';
import { LIMIT } from '../../appRedux/constants';
import _ from 'lodash';

const columns = [
  {
    title: 'Activity',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Type',
    dataIndex: 'transactionType',
    key: 'transactionType',
  },
  {
    title: 'Points',
    dataIndex: 'amount',
    key: 'amount',
    render: (record, text) => (
      <>
        {text.direction.toLowerCase() === 'credit' ? (
          <span className="green-points">{`${numberFormatter(
            pointFormat,
            text.amount,
          )} pts`}</span>
        ) : (
          <span className="red-points">{`${numberFormatter(
            pointFormat,
            text.amount,
          )} pts`}</span>
        )}
      </>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'timeStamp',
    key: 'timeStamp',
    render: date => <>{moment(date).format('YYYY-MM-DD, h:mm:ss a')}</>,
  },
];

const sideMenu = [
  {
    title: 'Summary',
    isActive: false,
    url: '/summary',
  },
  {
    title: 'Point History',
    isActive: true,
    url: '/points-history',
  },
  {
    title: ' Order History',
    isActive: false,
    url: '/order-history',
  },
  {
    title: 'Profile',
    isActive: false,
    url: '/profile',
  },
  {
    title: 'Wishlist',
    isActive: false,
    url: '/wishlist',
  },
  {
    title: 'Logout',
    isActive: false,
    url: '/logout',
  },
];

const { Search } = Input;
const { RangePicker } = DatePicker;

const PointsHistory = props => {
  const [menu, setMenu] = useState(false);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [pagination, setPagination] = useState({});
  const [paginationSortCurrent, setPaginationSortCurrent] = useState(1);
  const { userId, points } = props.authUser;

  let { transactions, loading } = props.transactions;
  let dataSource = transactions.items || [];

  useEffect(() => {
    props.getTransactions({ UserId: userId, Take: LIMIT });
  }, []);

  const handleSearch = params => {
    if (_.isEmpty(params)) {
      props.getTransactions({ UserId: userId, Take: LIMIT });
    } else {
      props.getTransactions({ UserId: userId, Search: params, Take: LIMIT });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPaginationSortCurrent(pagination.current);
    pagination.current -= 1;
    paginateSort(start, end, pagination);
    setPagination(pager);
  };

  const paginateSort = (s, e, pag, pageSize) => {
    if (_.isEmpty(s) && _.isEmpty(e)) {
      props.getTransactions({
        UserId: userId,
        Take: LIMIT,
        Skip: LIMIT * pag.current,
        //Skip: pag.current,
      });
    } else {
      const pagCurrent = pag.current - 1;
      props.getTransactions({
        UserId: userId,
        Take: LIMIT,
        Skip: pag.current === 1 ? 0 : LIMIT * pagCurrent,
        //Skip: pag.current,
        DateFrom: s,
        DateTo: e,
      });
    }
  };

  // const paginateSort = (s, e, pag, pageSize) => {
  //   if (_.isEmpty(s) && _.isEmpty(e)) {
  //     props.getOrders({
  //       UserId: userId,
  //       // Take: pageSize,
  //       Take: LIMIT,
  //       Skip: LIMIT * pag.current,
  //       //Skip: pag.current,
  //     });
  //   } else {
  //     const pagCurrent = pag.current - 1;
  //     props.getOrders({
  //       UserId: userId,
  //       // Take: pageSize,
  //       Take: LIMIT,
  //       Skip: (pag.current === 1) ? 0 : LIMIT * pagCurrent,
  //       //Skip: LIMIT * current,
  //       //Skip: pag.current,
  //       DateFrom: s,
  //       DateTo: e,
  //     });
  //   }
  // };

  function onDateChange(value, dateString) {
    setStart(dateString[0]);
    setEnd(dateString[1]);
    paginateSort(dateString[0], dateString[1], 1, LIMIT);
  }

  const pagData = {
    // position: ['topRight'],
    showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
    total: transactions.total,
    current: paginationSortCurrent,
    pageSize: LIMIT,
  };

  return (
    <main className="giftcard-view-section">
      <div className="accounts-container">
        <Button
          onClick={() => setMenu(true)}
          color="secondary"
          className="tstyle"
          size={'large'}
          shape="round">
          {' '}
          Account Side Menu{' '}
        </Button>
        <Row justify="end">
          <Col>
            <PointBalance />
          </Col>
        </Row>

        <Row className="account-columns" gutter={64}>
          <Col
            className={`account-column-1 ${menu &&
              'mobile_menu_profile_holder'}`}
            span={6}>
            <AccountsVerticalMenu
              menuList={sideMenu}
              setMenuOff={() => setMenu(false)}
            />
          </Col>
          <Col
            className="account-column-2"
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 18 }}>
            <Row className="content-wrapper">
              <Col span={24}>
                <div className="history-header">
                  <Search
                    className="search"
                    placeholder="input search text"
                    onSearch={value => handleSearch(value)}
                  />
                  <div className={'date_range_holder'}>
                    <RangePicker
                      format="YYYY-MM-DD HH:mm"
                      onChange={onDateChange}
                    />
                  </div>
                </div>

                <Table
                  className="history-table table-responsive"
                  rowClassName="history-table-row"
                  rowKey={record => record.id}
                  size="middle"
                  dataSource={dataSource}
                  columns={columns}
                  onChange={handleTableChange}
                  pagination={pagData}
                  loading={loading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </main>
  );
};

const mapStateToProps = ({ auth, transactions }) => ({
  authUser: auth.authUser,
  transactions,
});

const mapDispatchToProps = {
  getTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsHistory);
