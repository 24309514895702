import React from 'react';
import { Link } from '@reach/router';
import { Logo } from 'components';

import './FrontHeader.scss';
import NavInterface from '../../NavInterface';
import { Navbar } from 'reactstrap';

function FrontHeader(props) {
  return (
    <>
      {/*<header className="container header mt-5">*/}
      {/*    <Logo/>*/}
      {/*    <NavInterface/>*/}
      {/*</header>*/}

      <Navbar className="navbar-light" expand="md">
        <div className="container header mt-5">
          <NavInterface />
        </div>
      </Navbar>
    </>
  );
}

export default FrontHeader;
