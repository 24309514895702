import {
  FETCH_ACCOUNT_SUMMARY_FAILED,
  FETCH_ACCOUNT_SUMMARY_START,
  FETCH_ACCOUNT_SUMMARY_SUCCESS,
  GET_ACCOUNT_SUMMARY,
} from '../constants';
import api from '../api';

export const getAccountSummary = () => {
  return dispatch => {
    dispatch({ type: FETCH_ACCOUNT_SUMMARY_START });

    api
      .get(GET_ACCOUNT_SUMMARY)
      .then(({ data }) => {
        dispatch({ type: FETCH_ACCOUNT_SUMMARY_SUCCESS, payload: data });
      })
      .catch(e => {
        dispatch({ type: FETCH_ACCOUNT_SUMMARY_FAILED });

        // callback({ error: true, ...e });
      });
  };
};
