import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, Checkbox, Col, Layout, Row, Input, Spin } from 'antd';
import Road from '../../assets/img/road.png';
import LinkComponent from '../../route/LinkComponent';
import { FrontHeader } from '../../components/Header';
import { connect } from 'react-redux';
import {
  getGiftCards,
  getGiftCardsCategories,
} from '../../appRedux/action/GiftCards';
import _ from 'lodash';
import {
  handleFieldError,
  handleNotify,
  handleNotifyOnly,
} from '../../appRedux/api';
import { FETCH_ERROR_MESSAGE } from '../../appRedux/constants';
import { Loading } from '../../components';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { getAirtimes } from '../../appRedux/action/Airtime';

const { Meta } = Card;
const { Search } = Input;

function Airtimes(props) {
  const [filterCats, setFilterCats] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [giftCardsData, setGiftCardsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState();

  let {
    airtimes,
    airtime_loading,
    giftCardsCategories,
    loadingCategories,
  } = props.airtimes;

  useEffect(() => {
    props.getAirtimes();
  }, []);

  // useEffect(() => {
  //     setGiftCardsData(giftCards);
  //     setFilterCats([]);
  //     setSearchTerm();
  // }, [giftCards]);

  // useEffect(() => {
  //     setFiltering(true);
  //     let filteredList = giftCards;
  //     if (filterCats.length > 0) {
  //         filteredList = filteredList.filter(item =>
  //             filterCats.includes(item.category),
  //         );
  //     }
  //
  //     if (!_.isEmpty(searchTerm)) {
  //         let term = searchTerm.toLowerCase();
  //         filteredList = filteredList.filter(item => {
  //             return (
  //                 item.name.toLowerCase().includes(term) ||
  //                 item.shortDesc.toLowerCase().includes(term) ||
  //                 item.description.toLowerCase().includes(term)
  //             );
  //         });
  //     }
  //
  //     setGiftCardsData(filteredList);
  //     setTimeout(() => {
  //         setFiltering(false);
  //     }, 100);
  // }, [filterCats, searchTerm]);

  // const handleRes = useCallback(response => {
  //     _.isEmpty(response.error) && response.error !== true
  //         ? (function () {
  //             // const gfCat = giftCardsCat;
  //             // const setGiftCardsData = response.map(giftCard => {
  //             //   gfCat.push(giftCard.category);
  //             //   return giftCard;
  //             // });
  //             // gfCat.filter(gfCatData => (gfCatData !== gfCatData) && gfCatData);
  //             // console.log(gfCat);
  //             // setGiftCardsCat(gfCat);
  //             // setGiftCards(setGiftCardsData);
  //         })()
  //         : handleNotifyOnly({message: FETCH_ERROR_MESSAGE}, () => {
  //         });
  // });

  const giftList = airtimes.map((airtime, index) => (
    <Col
      className="gutter-row"
      xs={{ span: 12 }}
      sm={{ span: 12 }}
      md={{ span: 6 }}
      lg={{ span: 6 }}
      key={index}>
      <Card
        hoverable
        style={{ width: 'auto' }}
        cover={<img src={airtime.imageUrl} style={{ height: '173px' }} />}>
        <LinkComponent to={'/airtime/' + airtime.id}>
          <Button type="primary" shape="round" className="btn-b fid">
            Redeem
          </Button>
        </LinkComponent>
        {/*<Meta title={airtime.name} description={`airtime recharge from ${airtime.minimumValue} pts`}/>*/}
        <Meta title={airtime.name} />
      </Card>
    </Col>
  ));

  return (
    <>
      <Layout className=" mt-n1 gift_img_">
        <div>
          {/*<FrontHeader/>*/}
          <div className={'header_ti'}>
            <div className="container text-center">
              <h3 className="blue"> Convert Your Points to Airtime </h3>
              <p className="px-5 blue">Airtime recharge from 100 points</p>
            </div>
          </div>
        </div>
      </Layout>

      <div className="container">
        <div>
          <div className="gift_holder" style={{ paddingTop: 0 }}>
            <Row>
              <Col
                className="gutter-row"
                xs={{ sapn: 24, order: 1 }}
                md={{ span: 24, order: 1 }}
                lg={{ span: 24, order: 2 }}>
                {airtime_loading ? (
                  <Loading />
                ) : (
                  <div>
                    <div className={'gift_list_holder'}>
                      <Spin spinning={filtering}>
                        <Row>{giftList}</Row>
                      </Spin>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ airtimes }) => ({
  airtimes,
});

const mapDispatchToProps = { getAirtimes };

export default connect(mapStateToProps, mapDispatchToProps)(Airtimes);
