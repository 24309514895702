import React, { useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { Footer, FrontHeader, Loading } from '../../components';

import './Access.scss';

import EkedcImage from 'images/ekedc.png';
import GenesisImage from 'images/genesis.png';
import JumiaImage from 'images/jumia.png';
import SamsungImage from 'images/samsung.png';
import SparImage from 'images/spar.png';
import StartimesImage from 'images/startimes.png';
import InnerHeader from '../../components/Header/InnerHeader';
import { getFeatureGiftCards } from '../../appRedux/action/GiftCards';

const { Content } = Layout;

const Access = (Component, props) => innerProps => {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //     setTimeout(function() {
  //         setLoading(false)
  //     }.bind(this), 2000)
  // }, []);

  return (
    <>
      <InnerHeader />
      {/*<Loading/>*/}
      <Layout className={'top-fixed'} style={{ minHeight: '100vh' }}>
        {/*<Layout className="mask mt-n1">*/}
        {/*<FrontHeader/>*/}
        {/*</Layout>*/}

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Content className="content">
          <div className={'container'}>
            <Row>
              <Col
                className="formstyle"
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 20, offset: 2 }}>
                <Component {...props} {...innerProps} />
              </Col>
            </Row>
          </div>
          <br />
          <br />
          <br />

          {/*<Row className="my-5 partners">*/}
          {/*    <Col md={{span: 16, offset: 4}} sm={{span: 24, offset: 0}} style={{textAlign: 'center'}}>*/}
          {/*        <div>*/}
          {/*            <h3>Some Partners</h3>*/}
          {/*            <p className="px-5">*/}
          {/*                To be successful you’ve got to work hard, to make history, simple,*/}
          {/*                you’ve got to make it. I told you all this before, when you have a*/}
          {/*                swimming pool.*/}
          {/*            </p>*/}
          {/*            <div className="partners_list">*/}
          {/*                <>*/}
          {/*                    <div className="container">*/}
          {/*                        <div className="partners-card-group">*/}
          {/*                            <Row className="logo-wrapper">*/}
          {/*                                <Col lg={{span: 4}} md={{span: 6}} sm={{span: 4}}><img*/}
          {/*                                    className="partners-logo"*/}
          {/*                                    src={JumiaImage}*/}
          {/*                                    alt="Jumiaimage"*/}
          {/*                                /></Col>*/}
          {/*                                <Col lg={{span: 4}} md={{span: 6}} sm={{span: 4}}><img*/}
          {/*                                    className="partners-logo"*/}
          {/*                                    src={SparImage}*/}
          {/*                                    alt="SparImage"*/}
          {/*                                /></Col>*/}
          {/*                                <Col lg={{span: 4}} md={{span: 6}} sm={{span: 4}}><img*/}
          {/*                                    className="partners-logo"*/}
          {/*                                    src={SamsungImage}*/}
          {/*                                    alt="SamsungImage "*/}
          {/*                                /></Col>*/}
          {/*                                <Col lg={{span: 4}} md={{span: 6}} sm={{span: 4}}><img*/}
          {/*                                    className="partners-logo"*/}
          {/*                                    src={StartimesImage}*/}
          {/*                                    alt="StartimesImage"*/}
          {/*                                /></Col>*/}
          {/*                                <Col lg={{span: 4}} md={{span: 6}} sm={{span: 4}}><img*/}
          {/*                                    className="partners-logo"*/}
          {/*                                    src={GenesisImage}*/}
          {/*                                    alt="GenesisImage"*/}
          {/*                                /></Col>*/}
          {/*                                <Col lg={{span: 4}} md={{span: 6}} sm={{span: 4}}><img*/}
          {/*                                    className="partners-logo"*/}
          {/*                                    src={EkedcImage}*/}
          {/*                                    alt="EkedcImage"*/}
          {/*                                /></Col>*/}
          {/*                            </Row>*/}
          {/*                        </div>*/}
          {/*                    </div>*/}
          {/*                </>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </Col>*/}
          {/*</Row>*/}
        </Content>

        <Footer />
      </Layout>
    </>
  );
};

export default Access;
