import {
  FETCH_TRANSACTIONS_FAILED,
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS,
} from '../constants';
import api from '../api';

export const getTransactions = payload => {
  return dispatch => {
    dispatch({ type: FETCH_TRANSACTIONS_START });

    api
      .get(GET_TRANSACTIONS, {
        params: payload,
      })
      .then(({ data }) => {
        dispatch({ type: FETCH_TRANSACTIONS_SUCCESS, payload: data });
      })
      .catch(e => {
        dispatch({ type: FETCH_TRANSACTIONS_FAILED });

        // callback({ error: true, ...e });
      });
  };
};
