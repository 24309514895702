import * as Yup from 'yup';
import { phoneRegExp } from 'helpers';

const validationSchema = Yup.object({
  fullName: Yup.string().required('Fullname is required'),
  email: Yup.string()
    .email('The input is not valid Email!')
    .required('Email address is required'),
  personalMessage: Yup.string(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required("'Phone Number' is required."),
});

export default validationSchema;
