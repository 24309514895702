import {
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCESS,
  GET_PRODUCTS,
  ADD_PRODUCT_TO_CART,
  GLOBAL_FETCH_START,
  GLOBAL_FETCH_DONE,
  GET_PRODUCTS_CATEGORIES,
  FETCH_PRODUCTS_CATEGORIES_START,
  FETCH_PRODUCTS_CATEGORIES_SUCCESS,
  FETCH_PRODUCTS_CATEGORIES_FAILED,
  FETCH_SINGLE_PRODUCT_START,
  FETCH_SINGLE_PRODUCT_SUCCESS,
  FETCH_SINGLE_PRODUCT_FAILED,
} from '../constants';
import api from '../api';
import { openNotificationWithIcon } from './Common';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { fetchCart } from './Cart';

export const getProducts = params => {
  return dispatch => {
    dispatch({ type: FETCH_PRODUCTS_START });

    api
      .get(GET_PRODUCTS, { params })
      .then(({ data }) => {
        dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: data });
        // return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_PRODUCTS_FAILED });

        // callback({ error: true, ...e });
      });
  };
};

export const getSingleProduct = (data, callback) => {
  return dispatch => {
    dispatch({ type: FETCH_SINGLE_PRODUCT_START });
    dispatch(fetchCart());

    // const params = JSON.stringify(data, null, 2);
    const url = `/Orders/GetProducts/${data}`;

    api
      .get(url)
      .then(({ data }) => {
        dispatch({ type: FETCH_SINGLE_PRODUCT_SUCCESS, payload: data });
        return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_SINGLE_PRODUCT_FAILED });

        // callback({ error: true, ...e });
      });
  };
};

export const getProductsCategories = () => {
  return dispatch => {
    dispatch({ type: FETCH_PRODUCTS_CATEGORIES_START });

    api
      .get(GET_PRODUCTS_CATEGORIES)
      .then(({ data }) => {
        dispatch({ type: FETCH_PRODUCTS_CATEGORIES_SUCCESS, payload: data });
        // return callback(data);
      })
      .catch(e => {
        dispatch({ type: FETCH_PRODUCTS_CATEGORIES_FAILED });

        // callback({ error: true, ...e });
      });
  };
};

export const addProductToCart = (data, callback) => {
  return dispatch => {
    dispatch({ type: GLOBAL_FETCH_START });
    dispatch(showLoading());
    const url = `/Orders/AddProduct`;
    const params = JSON.stringify(data, null, 2);

    api
      .post(url, params)
      .then(resp => {
        if (resp.status === 200) {
          openNotificationWithIcon(
            'Please continue shopping or move to checkout ',
            `Product added to Cart!`,
            'success',
          );
          const data = resp.data;
          dispatch({ type: ADD_PRODUCT_TO_CART });
          dispatch({ type: GLOBAL_FETCH_DONE });
          dispatch(fetchCart());
          dispatch(hideLoading());
          return callback(true);
        }
      })
      .catch(error => {
        openNotificationWithIcon(error.message, `Error!`, 'error');
        dispatch({ type: GLOBAL_FETCH_DONE });
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};
