import React, { useState, useEffect } from 'react';
import LinkComponent from '../../route/LinkComponent';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { Formik } from 'formik';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { Alert, Col, notification, Row } from 'antd';
import {
  redirectActivation,
  redirectToCreatePassword,
  redirectToHome,
  redirectToParams,
  redirectToProfile,
} from '../../route/Redirect';
import _ from 'lodash';
import * as Yup from 'yup';
import Cookies from 'js-cookie';

import loginImg from '../../images/login-img.jpg';
import { connect } from 'react-redux';
import {
  clearPreUser,
  handleLoginLogic,
  userSignOut,
} from '../../appRedux/action/Auth';
import {
  clearSessionWithParams,
  getSessionWithKey,
} from '../../appRedux/store/cookies';
import { NOTIFICATION_DURATION } from '../../appRedux/constants';
import {
  encryptedPassword,
  handleFieldError,
  handleNotify,
} from '../../appRedux/api';

function Login(props) {
  const [rememberMe, setRememberMe] = useState(
    !!getSessionWithKey('remember_me'),
  );
  const [account, setAccount] = useState('');
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);

  useEffect(() => {
    const username = getSessionWithKey('remember_me');
    setAccount(!!username ? username : '');
    setRememberMe(!!username);
    props.userSignOut();
  }, []);

  Cookies.remove('__checkInUse');

  const handleIn = () => {
    notification['success']({
      message: 'Account!',
      description: 'Account sign in successful',
      duration: NOTIFICATION_DURATION,
    });
    setLaddaProgress(0.9);

    if (_.isEmpty(getSessionWithKey('urlKeep'))) {
      redirectToHome();
    } else {
      redirectToParams(getSessionWithKey('urlKeep'));
    }
    //redirectToProfile();
  };

  const validate = Yup.object({
    accountNumber: Yup.string()
      .length(
        10,
        'Account Number must be exactly 10 digits or Enter a valid Account Number',
      )
      .matches(/[0-9]+/gi, 'Account number must be a number')
      //.positive('Activation code must be greater than zero')
      .required('Account number is required'),
    // accountNumber: Yup.number()
    //     .test(
    //         'len',
    //         'Account number must be exactly 10 characters',
    //         val => val && val.toString().length === 10,
    //     )
    //     .positive('Account number must be greater than zero')
    //     .typeError('Account number must be a number')
    //     .required('Account number is required'),
    password: Yup.string().required('User password required.'),
  });

  return (
    <>
      <div className="hero-box">
        <Row className={'hero-box-row'}>
          <Col sm={{ span: 24 }} md={{ span: 12 }} className={'padding_inner'}>
            <div>
              <>
                <div className="top-form">
                  <div>
                    <h2 className="card_inner_title"> Welcome Back </h2>
                    <p className="card_inner_sub_title">
                      {' '}
                      Enter your login details to continue{' '}
                    </p>
                  </div>
                  <div>
                    <Formik
                      initialValues={{
                        accountNumber: account,
                        password: '',
                        remember_me: rememberMe,
                      }}
                      validationSchema={validate}
                      onSubmit={(values, { setSubmitting, setErrors }) => {
                        setLaddaLoading(true);
                        setLaddaProgress(0.5);

                        values.password = encryptedPassword(values.password);

                        props.handleLoginLogic(values, response =>
                          _.isEmpty(response.error) && response.error !== true
                            ? handleIn()
                            : handleNotify(response, setSubmitting, () => {
                                setLaddaProgress(1.0);
                                setLaddaLoading(false);
                                handleFieldError(
                                  response.validationErrors,
                                  setErrors,
                                );
                              }),
                        );
                      }}>
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit} className={'login_form '}>
                          <div className={'form_holder float_label form-group'}>
                            <div className="form-label-group-holder">
                              <div className="form-label-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  name="accountNumber"
                                  id="inputAccount"
                                  placeholder="Account Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.accountNumber}
                                />
                                <label htmlFor="inputAccount">
                                  {' '}
                                  Account No
                                </label>
                                {errors.accountNumber &&
                                  touched.accountNumber && (
                                    <FormFeedback>
                                      {errors.accountNumber}
                                    </FormFeedback>
                                  )}
                              </div>
                            </div>
                            <div className="form-label-group-holder">
                              <div className="form-label-group">
                                <input
                                  placeholder="Password"
                                  className="form-control"
                                  type="password"
                                  name="password"
                                  id="inputPassword"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                <label htmlFor="inputPassword">
                                  {' '}
                                  Password{' '}
                                </label>
                                {errors.password && touched.password && (
                                  <FormFeedback>{errors.password}</FormFeedback>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                            <div>
                              <div className="checkbox mb-3">
                                <FormGroup check>
                                  <Label className={'remember-me'} check>
                                    <input
                                      checked={values.remember_me}
                                      name="remember_me"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="checkbox"
                                    />{' '}
                                    Remember me
                                  </Label>
                                </FormGroup>
                              </div>
                            </div>
                            <div>
                              <LinkComponent
                                className={'forgot-password'}
                                to={'/forgot-password'}>
                                Forgot Password
                              </LinkComponent>
                            </div>
                          </div>

                          <LaddaButton
                            loading={laddaLoading}
                            progress={laddaProgress}
                            data-style={SLIDE_UP}
                            data-spinner-size={30}
                            data-spinner-color="#fff"
                            data-spinner-lines={12}
                            className="btn-submit btn btn-primary btn-style"
                            type="submit"
                            disabled={isSubmitting}>
                            Login
                          </LaddaButton>

                          <div style={{ marginTop: 10 }}>
                            <p className={'text-center'}>
                              By signing in you consent to Fidelity's <br />{' '}
                              <strong>
                                {' '}
                                <a
                                  href="https://sqlvae7svwgvdevoto.blob.core.windows.net/loyaltydocs/Data-Privacy-Policy.pdf"
                                  target="_BLANK">
                                  {' '}
                                  Privacy Policy{' '}
                                </a>{' '}
                              </strong>{' '}
                              Notice.
                            </p>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>

                  <div className={'text-center'}>
                    <div className="dot" />
                    <div className="text-center" style={{ marginTop: 28 }}>
                      <p>
                        {' '}
                        Don’t have an account?{' '}
                        <LinkComponent
                          to={'/activation'}
                          className="forget_password_link">
                          {' '}
                          Get Activated{' '}
                        </LinkComponent>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </Col>
          <Col
            sm={{ span: 0 }}
            md={{ span: 12 }}
            className={'login_side_image access_img'}>
            {/*<img*/}
            {/*  className="img-fluid hero-box-img-fluid"*/}
            {/*  alt="example"*/}
            {/*  src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"*/}
            {/*/>*/}
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  handleLoginLogic,
  clearPreUser,
  userSignOut,
})(Login);
