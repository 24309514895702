import React, { useEffect, useState } from 'react';
import { Button, Card, Carousel, Col, Layout, Row } from 'antd';
import { CaretRightOutlined, PoweroffOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Power } from '../../assets/svg/power_btn.svg';
import Road from '../../assets/img/road.png';
import LinkComponent from '../../route/LinkComponent';
import { HomepageFAQ, Loading } from '../../components';

import HowImage from 'images/new-how-it-works.png';
import HowIcon1 from 'images/how-icon-1.svg';
import HowIcon2 from 'images/how-icon-2.svg';
import HowIcon3 from 'images/how-icon-3.svg';
import ShopImage from 'images/shoponline.png';
import LandingImage1 from 'images/landingpage.jpg';
import _ from 'lodash';

import './Home.scss';

import { getFeatureGiftCards } from '../../appRedux/action/GiftCards';
import { FrontHeader } from '../../components/Header';

const { Meta } = Card;

function Home(props) {
  const dispatch = useDispatch();
  const { authUser } = useSelector(state => state.auth);
  // console.log(authUser);
  const { featureGiftCards, featureloading } = useSelector(
    state => state.giftCards,
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getFeatureGiftCards());

    setTimeout(
      function() {
        setLoading(false);
      }.bind(this),
      3000,
    );
  }, []);

  const header_settings = {
    arrows: true,
    dots: false,
    infinite: true,
    swipeToSlide: true,
    slidesToScroll: 1,
    autoplay: false,
    effect: 'fade',
    speed: 200,
    autoplaySpeed: 2000,
    // autoplaySpeed: 2000,
    dotsClass: 'slick-dots slick-thumb',
    slidesToShow: 1,
  };

  const product_settings = {
    arrows: true,
    dots: false,
    infinite: true,
    swipeToSlide: true,
    autoplay: true,
    // speed: 1000,
    speed: 800,
    lazyLoad: true,
    centerMode: false,
    autoplaySpeed: 2000,
    dotsClass: 'slick-dots slick-thumb',
    slidesToScroll: 1,
    slidesToShow: 3,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    // className: 'center',
  };

  // console.log('featureGiftCards',featureGiftCards);

  const giftList = featureGiftCards.map((gift, index) => (
    <div key={index}>
      <Card
        hoverable
        style={{ padding: 0, width: 230, height: 240 }}
        cover={
          <img
            className="gift_home_img"
            src={gift.imageUrl}
            style={{ height: '130px' }}
          />
        }>
        <LinkComponent to={'/gift-card/' + gift.giftId}>
          <Button type="primary" shape="round" className="btn-b fid">
            Redeem
          </Button>
        </LinkComponent>
        <Meta className="epl" title={gift.name} description={gift.shortDesc} />
      </Card>
    </div>
  ));

  return (
    <>
      <div style={{ background: '#fbfbfb' }}>
        {loading ? (
          <Loading />
        ) : (
          <div className="">
            <Layout className="mt-n1 white-background">
              {/*<div className="home_header_holder">*/}
              {/*  <FrontHeader />*/}
              {/*</div>*/}
              <div className="pos-rel home_header_style">
                <div>
                  <div className={'container_slick'}>
                    <div className={'container_slick_holder'}>
                      <Carousel {...header_settings}>
                        <div className="carol-overlay">
                          <div className={'over'} />
                          <div className={'container rel'}>
                            <div className={'over-container'}>
                              <p className="lead_small_top">
                                <span className="extra">
                                  FIDELITY GREEN REWARDS
                                </span>
                                Thank you for banking with us.
                              </p>
                              <h2 className="lead_top lead_style">
                                Treat yourself to something <br /> special.
                                You’ve earned it!
                              </h2>
                              {_.isEmpty(authUser) && (
                                <LinkComponent to={'/activation'}>
                                  <Button
                                    className="lead_btn"
                                    type="primary"
                                    shape="round"
                                    icon={
                                      <span className="oval">
                                        <PoweroffOutlined
                                          style={{ fontSize: '23px' }}
                                          className="power-icon"
                                        />
                                      </span>
                                    }>
                                    activate now
                                  </Button>
                                </LinkComponent>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="carol-overlay carol-overlay-sec">
                          <div className={'over'} />
                          <div className={'container rel'}>
                            <div className={' over-container'}>
                              <p className="lead_small_top">
                                <span className="extra">
                                  FIDELITY GREEN REWARDS
                                </span>
                                We care for our customers
                              </p>
                              <h2 className="lead_top lead_style">
                                Get rewarded for using our wide range of
                                innovative banking services
                              </h2>
                              {_.isEmpty(authUser) && (
                                <LinkComponent to={'/activation'}>
                                  <Button
                                    className="lead_btn"
                                    type="primary"
                                    shape="round"
                                    icon={
                                      <span className="oval">
                                        <PoweroffOutlined
                                          style={{ fontSize: '23px' }}
                                          className="power-icon"
                                        />
                                      </span>
                                    }>
                                    {/*activate now*/} Get Started
                                  </Button>
                                </LinkComponent>
                              )}
                            </div>
                          </div>
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
                <div className="orange-circle"></div>
              </div>

              <section className="container new-how-it-works">
                <div className="part-1">
                  <h4 className="subtitle">REWARDS</h4>
                  <h4 className="title">How it Works</h4>

                  <p className="description">
                    Use several of our banking channels/touchpoints and earn
                    reward points. Redeem your points for dozens of products and
                    services like shopping vouchers and mobile airtime
                  </p>
                </div>

                <div className="part-2">
                  <Row gutter={20}>
                    <Col
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 14 }}
                      className="image-col">
                      <img
                        src={HowImage}
                        alt="how it works"
                        className="how-it-works-image"
                      />
                    </Col>
                    <Col
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 10 }}
                      className="details-col">
                      <div className="wrapper">
                        <h3 className="title">
                          <img
                            src={HowIcon1}
                            alt="how it works"
                            className="how-icons"
                          />
                          Activate
                        </h3>
                        <p className="description">
                          Use your Fidelity Account Number to activate your
                          rewards account. Confirm your activation via the
                          e-mail address registered to your account and create a
                          unique password.
                        </p>
                      </div>
                      <div className="wrapper">
                        <h3 className="title">
                          <img
                            src={HowIcon2}
                            alt="how it works 2"
                            className="how-icons 2"
                          />
                          Earn Points
                        </h3>
                        <p className="description">
                          Enjoy easier banking and earn points that will accrue
                          to your account.
                        </p>
                      </div>
                      <div className="wrapper">
                        <h3 className="title">
                          <img
                            src={HowIcon3}
                            alt="how it works 2"
                            className="how-icons 3"
                          />
                          Redeem Points
                        </h3>
                        <p className="description">
                          Spend your earned points for shopping vouchers,
                          airtime and other amazing products and services.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>

              <div className="pos-rel">
                <div className={'under_style'} style={{ marginBottom: 130 }}>
                  <div className="container">
                    <div className="product_slider_holder pos-rel">
                      <Row gutter={32}>
                        <Col
                          sm={{ span: 24 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          <p className="subtitle">Products</p>

                          <h2 className={'title'}>
                            Some top reward gift cards
                          </h2>
                          <p className="description">
                            Here are some of our customers’ favourite rewards
                            gift cards.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="pro_slider_side">
                  <div className="blue-bg">
                    <>
                      {featureloading ? (
                        <Loading />
                      ) : (
                        <div className="prod_slider">
                          <div>
                            <Carousel {...product_settings}>
                              {giftList}
                            </Carousel>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>

              <div className="footer-bg-wrap">
                <section className="shop-online">
                  <Row>
                    <Col
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      className="image-col">
                      <img
                        src={ShopImage}
                        alt="girls shopping"
                        className="shop-image"
                      />
                    </Col>
                    <Col
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      className="shop-desc">
                      <h2 className={'title'}>
                        Shop Online and <br /> Offline
                      </h2>
                      <p className="description">
                        Shop at your favourite e-commerce and physical stores
                        across Nigeria and internationally. From fashion to food
                        and electronics, there is something for everyone.
                      </p>

                      <LinkComponent to={'/gift-cards'}>
                        <Button
                          icon={<CaretRightOutlined />}
                          className="learn-more-btn">
                          Shop Now
                        </Button>
                      </LinkComponent>
                    </Col>
                  </Row>
                </section>

                <div className={'faq'}>
                  <div
                    className="text-center"
                    style={{ marginBottom: 61, marginTop: 61 }}>
                    <h2>Frequently Asked Questions</h2>
                    <p>We are always here to assist you</p>
                  </div>
                  <HomepageFAQ />
                </div>
              </div>
            </Layout>
          </div>
        )}
      </div>
    </>
  );
}

export default Home;
