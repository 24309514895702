import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import Common from 'appRedux/reducers/Common';
import Cart from 'appRedux/reducers/Cart';
import Auth from 'appRedux/reducers/Auth';
import Products from 'appRedux/reducers/Products';
import GiftCards from 'appRedux/reducers/GiftCards';
import Orders from 'appRedux/reducers/Orders';
import Transactions from 'appRedux/reducers/Transactions';
import AccountSummary from 'appRedux/reducers/AccountSummary';
import Wishlist from 'appRedux/reducers/Wishlist';
import Airtimes from 'appRedux/reducers/Airtimes';
import Payments from 'appRedux/reducers/Payments';
import Main from 'appRedux/reducers/Main';

const reducers = combineReducers({
  common: Common,
  cart: Cart,
  auth: Auth,
  main: Main,
  products: Products,
  giftCards: GiftCards,
  orders: Orders,
  transactions: Transactions,
  accountSummary: AccountSummary,
  loadingBar: loadingBarReducer,
  wishlist: Wishlist,
  airtimes: Airtimes,
  payments: Payments,
});

export default reducers;
