import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Empty,
  Input,
  Layout,
  Row,
  Spin,
} from 'antd';
import { FrontHeader } from '../../components/Header';
import Road from '../../assets/img/road.png';
import LinkComponent from '../../route/LinkComponent';
import { connect } from 'react-redux';
import {
  getProducts,
  getProductsCategories,
} from '../../appRedux/action/Products';
import { Loading } from 'components';
import { handleNotifyOnly } from 'appRedux/api';
import { FETCH_ERROR_MESSAGE } from 'appRedux/constants';
import _ from 'lodash';
import numberFormatter from 'number-formatter';
import { pointFormat } from 'helpers';
import './media.scss';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { Search } = Input;

function Products(props) {
  const [filterCats, setFilterCats] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [filtered, setFiltered] = useState(false);

  let {
    loading,
    products,
    loadingCategories,
    productsCategories,
  } = props.products;

  useEffect(() => {
    props.getProducts({});
    props.getProductsCategories();
  }, []);

  useEffect(() => {
    setProductsData(products);
    setFilterCats([]);
    setSearchTerm();
  }, [products]);

  useEffect(() => {
    setFiltering(true);
    let filteredList = products;
    if (filterCats.length > 0) {
      filteredList = filteredList.filter(item =>
        filterCats.includes(item.category),
      );
    }

    if (!_.isEmpty(searchTerm)) {
      let term = searchTerm.toLowerCase();
      filteredList = filteredList.filter(item => {
        return (
          item.name.toLowerCase().includes(term) ||
          item.description.toLowerCase().includes(term)
        );
      });
    }

    setProductsData(filteredList);
    setTimeout(() => {
      setFiltering(false);
      setFiltered(true);
    }, 100);
  }, [filterCats, searchTerm]);

  const handleRes = useCallback(response => {
    _.isEmpty(response.error) && response.error !== true
      ? (function() {
          //
        })()
      : handleNotifyOnly({ message: FETCH_ERROR_MESSAGE }, () => {});
  });

  const handleSearch = data => {
    setSearchTerm(data);
  };

  const handleCategoryChange = checkedList => {
    setFilterCats(checkedList);
  };

  const CategoriesOptions = productsCategories.map(cat => cat.name);

  const productList = productsData.map((product, index) => (
    <Col
      className="gutter-row"
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 8 }}
      key={index}>
      <div className="item_pro">
        <LinkComponent to={'/product/' + product.productId}>
          <div className="img">
            <div style={{ backgroundImage: 'url(' + product.imageUrl + ')' }} />
          </div>
          <Button type="primary" shape="round" className="btn-b">
            Buy Now
          </Button>
        </LinkComponent>
        <div>
          <p>{product.name}</p>
          <p style={{ marginTop: '10px' }}>
            <small>
              <strong>
                {numberFormatter(pointFormat, product.amount)} pts
              </strong>
            </small>
          </p>
        </div>
      </div>
    </Col>
  ));

  const searchSuffix = (
    <span>
      {searchTerm && searchTerm.length > 0 ? (
        <CloseOutlined onClick={() => handleSearch('')} />
      ) : (
        ''
      )}
    </span>
  );

  return (
    <>
      <div>
        <Layout className="mask mt-n1 prod_img">
          {/*<FrontHeader />*/}
          <div className={'header_ti'}>
            <div className="container text-center">
              <h3> Electronics Gadgets </h3>
              <p className="px-5">
                Redeem your loyalty points for exciting products for yourself or
                loved ones.
              </p>
            </div>
          </div>
        </Layout>

        <div className="container">
          <div>
            <Row>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 20, offset: 2 }}
                md={{ span: 16, offset: 4 }}>
                <div className="search">
                  <Search
                    placeholder="input search text"
                    enterButton={
                      <>
                        <SearchOutlined
                          className="d-block d-md-none"
                          style={{ marginTop: '-12px' }}
                        />
                        <span className="d-none d-md-block">Search</span>
                      </>
                    }
                    size="large"
                    onSearch={value => handleSearch(value)}
                    onChange={event => handleSearch(event.target.value)}
                    suffix={searchSuffix}
                    value={searchTerm}
                  />
                </div>
              </Col>
            </Row>

            <div className="gift_holder">
              <Row gutter={16}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24, order: 2 }}
                  md={{ span: 24, order: 2 }}
                  lg={{ span: 6, order: 1 }}>
                  <div className={'check_list'}>
                    <div className="holder">
                      <div>
                        <h3> Categories </h3>
                      </div>
                      <div>
                        <Spin spinning={loadingCategories}>
                          <Checkbox.Group
                            options={CategoriesOptions}
                            value={filterCats}
                            onChange={handleCategoryChange}
                          />
                        </Spin>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ sapn: 24, order: 1 }}
                  md={{ span: 24, order: 1 }}
                  lg={{ span: 18, order: 2 }}>
                  {loading ? (
                    <Loading />
                  ) : (
                    <div>
                      <div className={'gift_list_holder pro_list'}>
                        <Spin spinning={filtering}>
                          <Row>
                            {filtered === true && _.isEmpty(productsData) ? (
                              <Empty
                                style={{
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }}
                              />
                            ) : (
                              productList
                            )}
                          </Row>
                        </Spin>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ products }) => ({
  products,
});

const mapDispatchToProps = {
  getProducts,
  getProductsCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
