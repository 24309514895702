import {
  ACTIVATION_ACCOUNT_NO,
  SEND_ACTIVATION_CODE,
  VALIDATE_ACCOUNT_CODE,
  SIGNIN_USER_SUCCESS,
  VALIDATION,
  REGISTER,
  REGISTER_ACCOUNT,
  GET_TOKEN,
  GLOBAL_FETCH_START,
  SIGNOUT_USER_SUCCESS,
  GET_PROFILE,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  PROFILE_LOADING,
  PROFILE_DONE,
  FORGOT_PASSWORD,
  GET_REFRESH_TOKEN,
  MAIN_LOADING_ON,
  MAIN_LOADING_OFF,
} from '../constants';
import api, { setToken } from '../api';
import {
  clearSession,
  clearSessionWithParams,
  getSession,
  getSessionWithKey,
  setSession,
  setSessionWithValue,
} from '../store/cookies';
import _ from 'lodash';
import Cookies from 'js-cookie';
import {
  redirectToHome,
  redirectToLogin,
  redirectToProfile,
} from '../../route/Redirect';

/**
 * {
 * "accessToken":"ZU",
 * "accessTokenExpiration":"2020-09-19T08:51:36.1040362+00:00",
 * "expiresIn":299881,
 * "firstName":"Firstname4","lastName":"Lastname4","accountNumber":"1234567891","userId":4,"phone":"2348175589659"}
 */
const handleUserAccessGranted = (data, type, callback) => {
  if (type === 'reg') {
    return callback({ type: REGISTER_ACCOUNT, payload: data });
  } else {
    return callback({ type: SIGNIN_USER_SUCCESS, payload: data });
  }
};

export const handleLoginLogic = (value, callback) => {
  return dispatch => {
    const { accountNumber, password } = value;

    if (value.remember_me) {
      setSessionWithValue(accountNumber, 'remember_me');
    } else {
      clearSessionWithParams('remember_me');
    }

    api
      .post(GET_TOKEN, { accountNumber, password })
      .then(({ data }) => {
        setToken(data.accessToken);
        setSession(
          data.accessToken,
          data.accessTokenExpiration,
          data.refreshToken,
        );
        // setSession(data.accessToken, 'expiresIn');
        delete data.accessToken;
        delete data.accessTokenExpiration;
        delete data.expiresIn;

        handleUserAccessGranted(data, 'login', dispatchAbleResponse => {
          dispatch(dispatchAbleResponse);
          return callback(data);
        });
      })
      .catch(e => callback({ error: true, ...e }));
  };
};

export const handleForgotPasswordLogic = (accountNumber, callback) => {
  return dispatch => {
    api
      .post(FORGOT_PASSWORD, { accountNumber })
      .then(({ data }) => {
        return callback(data);
      })
      .catch(e => callback({ error: true, ...e }));
  };
};

export const handleActivation = (accountNumber, callback) => {
  return dispatch => {
    api
      .post(SEND_ACTIVATION_CODE, { accountNumber })
      .then(({ data }) => {
        dispatch({ type: ACTIVATION_ACCOUNT_NO, payload: accountNumber });
        return callback(data);
      })
      .catch(e => callback({ error: true, ...e }));
  };
};

export const handleValidation = (activationCode, accountNumber, callback) => {
  return dispatch => {
    api
      .post(VALIDATION, { accountNumber, activationCode })
      .then(({ data }) => {
        dispatch({
          type: VALIDATE_ACCOUNT_CODE,
          payload: { accountNumber, activationCode },
        });
        return callback(data);
      })
      .catch(e => callback({ error: true, ...e }));
  };
};

export const handleRegister = (
  { accountNumber, activationCode },
  password,
  callback,
) => {
  return dispatch => {
    api
      .post(REGISTER, { accountNumber, activationCode, password })
      .then(({ data }) => {
        setToken(data.accessToken);
        setSession(
          data.accessToken,
          data.accessTokenExpiration,
          data.refreshToken,
        );
        //setSession(data.accessToken, 'expiresIn');
        delete data.accessToken;
        delete data.accessTokenExpiration;
        delete data.expiresIn;

        handleUserAccessGranted(data, 'reg', dispatchAbleResponse => {
          dispatch(dispatchAbleResponse);
          return callback(data);
        });
      })
      .catch(e => callback({ error: true, ...e }));
  };
};

export const handleUpdateProfile = (
  { userId, email, phone, homeAddress },
  callback,
) => {
  return dispatch => {
    api
      .post(UPDATE_PROFILE, { userId, email, phone, homeAddress })
      .then(({ data }) => {
        return callback(data);
      })
      .catch(e => callback({ error: true, ...e }));
  };
};

export const handleChangePassword = (
  { currentPassword, newPassword },
  callback,
) => {
  return dispatch => {
    api
      .post(CHANGE_PASSWORD, { currentPassword, newPassword })
      .then(({ data }) => {
        return callback(data);
      })
      .catch(e => callback({ error: true, ...e }));
  };
};

export const getProfile = callback => {
  return dispatch => {
    dispatch({ type: PROFILE_LOADING });
    api
      .get(GET_PROFILE)
      .then(({ data }) => {
        handleUserAccessGranted(data, 'login', dispatchAbleResponse => {
          dispatch(dispatchAbleResponse);
          dispatch({ type: PROFILE_DONE });
          return callback(data);
        });
      })
      .catch(e => callback({ error: true, ...e }));
  };
};

export const getProfileRefreshTokens = callback => {
  api
    .post(GET_REFRESH_TOKEN, {
      refreshToken: getSessionWithKey('___session_refreshToken'),
      token: getSession(),
    })
    .then(({ data }) => {
      setToken(data.accessToken);
      setSession(
        data.accessToken,
        data.accessTokenExpiration,
        data.refreshToken,
      );
      return callback(true);
    })
    .catch(e => {
      return callback(false);
    });
};

export const getProfileRefreshToken = () => {
  // return dispatch => {
  //   dispatch({ type: MAIN_LOADING_ON });
  //   api.post(GET_REFRESH_TOKEN).then(({ data }) => {
  //     console.log('----getProfileRefreshToken', data);
  //     setToken(data.accessToken);
  //     setSession(data.accessToken, data.accessTokenExpiration, data.refreshToken);
  //   }).catch(e => {
  //     dispatch({ type: MAIN_LOADING_OFF });
  //     redirectToLogin();
  //   });
  // };
};

export const clearPreUser = () => {
  return dispatch => {
    return dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};

export const userSignOut = () => {
  return dispatch => {
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    // dispatch({type: GLOBAL_FETCH_START});
    localStorage.removeItem('user');
    clearSession();
  };
};

export const onUnauthenticated = () => {
  setSessionWithValue(true, '__checkInUse');
  api
    .post(GET_REFRESH_TOKEN, {
      refreshToken: getSessionWithKey('___session_refreshToken'),
      token: getSession(),
    })
    .then(({ data }) => {
      setToken(data.accessToken);
      setSession(
        data.accessToken,
        data.accessTokenExpiration,
        data.refreshToken,
      );
      redirectToHome();
      Cookies.remove('__checkInUse');
    })
    .catch(e => {
      Cookies.remove('__checkInUse');
      redirectToLogin();
    });
};
