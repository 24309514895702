import {
  Home,
  Login,
  Register,
  ForgotPassword,
  GiftCardView,
  AirtimeView,
  Airtime,
  Activation,
  Verification,
  Summary,
  Profile,
  GiftCards,
  PointsHistory,
  OrderHistory,
  OrderHistoryView,
  Products,
  ProductView,
  Cart,
  CreatePassword,
  Wishlist,
  Payment,
  CompletePayment,
  FailedPayment,
  VerifyPayment,
  OrderCompleted,
} from '../containers';
import { Access, Landing, Inner, ProfileInner } from '../layouts';
import { Test } from '../components';

const Routes = [
  { path: '/', component: Home, layout: Landing },
  { path: '/login', component: Login, layout: Access },
  { path: '/logout' },
  { path: '/register', component: Register, layout: Access },
  { path: '/activation', component: Activation, layout: Access },
  { path: '/verification', component: Verification, layout: Access },
  { path: '/forgot-password', component: ForgotPassword, layout: Access },
  { path: '/create-password', component: CreatePassword, layout: Access },
  { path: '/profile', component: Profile, layout: Inner, auth: true },
  {
    path: '/points-history',
    component: PointsHistory,
    layout: Inner,
    auth: true,
  },
  {
    path: '/order-history',
    component: OrderHistory,
    layout: Inner,
    auth: true,
  },
  {
    path: '/order/:orderNo',
    component: OrderHistoryView,
    layout: Inner,
    auth: true,
  },
  { path: '/airtimes', component: Airtime, layout: Landing },
  { path: '/test', component: Test, layout: Landing },
  { path: '/payment', component: Payment, layout: Landing, auth: true },
  { path: '/airtime/:id', component: AirtimeView, layout: Inner, auth: true },
  { path: '/gift-cards', component: GiftCards, layout: Landing },
  {
    path: '/gift-card/:id',
    component: GiftCardView,
    layout: Inner,
    auth: true,
  },
  { path: '/products', component: Products, layout: Landing },
  { path: '/product/:id', component: ProductView, layout: Inner },
  { path: '/summary', component: Summary, layout: Inner, auth: true },
  { path: '/cart', component: Cart, layout: Inner, auth: true },
  { path: '/wishlist', component: Wishlist, layout: Inner, auth: true },
  { path: '/verify-payment/:type', component: VerifyPayment, layout: Landing },
  { path: '/verify-payment', component: VerifyPayment, layout: Landing },
  { path: '/complete-payment', component: CompletePayment, layout: Landing },
  { path: '/failed-payment', component: FailedPayment, layout: Landing },
  { path: '/order-completed', component: OrderCompleted, layout: Landing },
  // { path: '/activation', component: myActivation },
];

export default Routes;
export { default as RouteComponent } from './RouteComponent';
