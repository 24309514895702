import * as LS from './localStorage';
import * as SS from './sessionStorage';
import useDebounce from './useDebounceHooks';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const pointFormat = '#,##0.00';

export { LS, SS, phoneRegExp, useDebounce, pointFormat };

export const BASE_PATH = '';
