import React, { useEffect, useState } from 'react';
import { Button, notification, Result } from 'antd';
import LinkComponent from 'route/LinkComponent';
import _ from 'lodash';
import { redirectToHome } from 'route/Redirect';
import {
  clearSessionWithParams,
  getSessionJsonWithKey,
} from 'appRedux/store/cookies';
import {
  NOTIFICATION_DURATION,
  PAYMENT_FAILED,
  PAYMENT_SUCCESSFUL,
} from 'appRedux/constants';
import { connect } from 'react-redux';
import { completePayment } from 'appRedux/action/Payments';
import { handleError } from 'appRedux/action/Common';
import { Loading } from 'components';

const VerifyPayment = props => {
  // const [loading, setLoading] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  let { payment, loading } = props.payments;

  useEffect(() => {
    window.scrollTo(0, 0);
    // const url = new URL(props.location.pathname);
    const link = new URL(window.location.href);
    const query = new URLSearchParams(link.href);
    //const query = new URLSearchParams(props.location.pathname);
    const urlSlice = link.href.split('?TransactionReference=');
    const urlSliced = urlSlice[1].split('&OrderID=');

    // const transactionRef = query.get('TransactionReference');
    // const orderId = query.get('OrderID');
    const transactionRef = urlSliced[0].trim();
    const orderId = urlSliced[1].trim();
    // const order = getSessionJsonWithKey('___order_purchase');

    if (_.isEmpty(transactionRef)) {
      // notification['error']({
      //   message: 'Invalid reference!',
      //   description: 'Please confirm payment',
      //   duration: NOTIFICATION_DURATION,
      // });
      // clearSessionWithParams('___order_purchase');
      redirectToHome();
      return;
    }

    if (_.isEmpty(orderId)) {
      // notification['error']({
      //   message: 'Invalid order parameter!',
      //   description: 'Please confirm payment',
      //   duration: NOTIFICATION_DURATION,
      // });
      // clearSessionWithParams('___order_purchase');
      redirectToHome();
      return;
    }

    props.completePayment(
      {
        transactionReference: transactionRef,
        orderId: orderId,
      },
      response => {
        console.log(response);

        if (_.isEmpty(response.error) && response.error !== true) {
          //   if (!_.isEmpty(response.transactionReference) && !_.isEmpty(response.orderId)) {
          //     setPaymentSuccess(true);
          //   }
          //   setProcessed(true);
          //   clearSessionWithParams('___order_purchase');
          if (response.transactionStatus === PAYMENT_SUCCESSFUL) {
            setPaymentSuccess(PAYMENT_SUCCESSFUL);
          }
          if (response.transactionStatus === PAYMENT_FAILED) {
            setPaymentSuccess(PAYMENT_FAILED);
          }
          // setLoading(false)
        } else {
          redirectToHome();
          handleError(response.message);
          //   setPaymentSuccess(false);
          //   setProcessed(true);
          //clearSessionWithParams('___order_purchase');
        }
        // setProcessed(true);
        //clearSessionWithParams('___order_purchase');
      },
    );
  }, []);

  return (
    <div>
      <div className="container error-container">
        <div style={{ marginTop: 30, display: 'block' }}>
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-6  text-center">
              <div>
                {loading === true ? (
                  <Loading />
                ) : (
                  <>
                    {paymentSuccess === PAYMENT_SUCCESSFUL && (
                      <Result
                        status="success"
                        title="Payment Verified Successful"
                        subTitle="Your payment was completed"
                        extra={
                          <LinkComponent to={'/'}>
                            <Button
                              className="fd-button primary"
                              type="link"
                              shape="round">
                              Back to Homepage
                            </Button>
                          </LinkComponent>
                        }
                      />
                    )}

                    {paymentSuccess === PAYMENT_FAILED && (
                      <Result
                        status="error"
                        title="Error processing payment"
                        subTitle="Sorry, Your payment failed!"
                        extra={
                          <LinkComponent to={'/'}>
                            <Button
                              className="fd-button primary"
                              type="link"
                              shape="round">
                              Back to Homepages
                            </Button>
                          </LinkComponent>
                        }
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ payments }) => ({
  payments,
});

const mapDispatchToProps = {
  completePayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPayment);
