import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import {
  FETCH_WISHLIST_FAILED,
  FETCH_WISHLIST_START,
  FETCH_WISHLIST_SUCCESS,
  REMOVE_WISHLIST_ITEM_FAILED,
  REMOVE_WISHLIST_ITEM_START,
  REMOVE_WISHLIST_ITEM_SUCCESS,
} from '../constants';

const INIT_STATE = {
  wishlist: [],
  loading: false,
};

const WishlistReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REMOVE_WISHLIST_ITEM_START: {
      return {
        ...state,
      };
    }

    case FETCH_WISHLIST_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_WISHLIST_SUCCESS: {
      return {
        ...state,
        wishlist: action.payload,
        loading: false,
      };
    }

    case REMOVE_WISHLIST_ITEM_SUCCESS:
    case REMOVE_WISHLIST_ITEM_FAILED:
    case FETCH_WISHLIST_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

// export default CartReducer;

const persistConfig = {
  key: 'wishlist',
  storage,
  whitelist: ['wishlist'],
};

export default persistReducer(persistConfig, WishlistReducer);
