import {
  FETCH_WISHLIST_FAILED,
  FETCH_WISHLIST_START,
  FETCH_WISHLIST_SUCCESS,
  REMOVE_WISHLIST_ITEM_FAILED,
  REMOVE_WISHLIST_ITEM_START,
  REMOVE_WISHLIST_ITEM_SUCCESS,
} from '../constants';
import api from '../api';
import { openNotificationWithIcon } from './Common';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const fetchWishlist = () => {
  return dispatch => {
    dispatch({ type: FETCH_WISHLIST_START });
    const url = `/Orders/GetWishes`;

    api
      .get(url)
      .then(({ data }) => {
        dispatch({
          type: FETCH_WISHLIST_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        openNotificationWithIcon(
          'Something went wrong. Try again',
          `Error!`,
          'error',
        );
        dispatch({ type: FETCH_WISHLIST_FAILED });
      });
  };
};

export const removeItemFromWishlist = (id, callback) => {
  return dispatch => {
    dispatch({ type: REMOVE_WISHLIST_ITEM_START });
    dispatch(showLoading());
    const url = `/Orders/RemoveFromWish`;
    const data = { giftCardId: id };
    const params = JSON.stringify(data, null, 2);

    api
      .post(url, params)
      .then(({ data }) => {
        openNotificationWithIcon(
          'Continue shopping or move to checkout',
          `Item was removed from wishlist!`,
          'success',
        );
        dispatch({ type: REMOVE_WISHLIST_ITEM_SUCCESS, payload: data });
        // dispatch(fetchWishlist());
        dispatch(hideLoading());
        return callback();
      })
      .catch(error => {
        openNotificationWithIcon(
          error.message,
          'Item cannot be removed. Try again',
          'error',
        );
        dispatch({ type: REMOVE_WISHLIST_ITEM_FAILED });
        dispatch(hideLoading());
        return callback();
      });
  };
};
