import React, { useState } from 'react';
import { Link } from '@reach/router';
import { CloseCircleOutlined } from '@ant-design/icons';

import './AccountsVerticalMenu.scss';
import { Button } from 'antd';

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: 'nav-menu-item active' } : {};
};

const AccountsVerticalMenu = ({ menuList, setMenuOff }) => {
  return (
    <nav className="accounts-menu">
      <CloseCircleOutlined onClick={setMenuOff} className={'close_menu'} />
      <h1 className="nav-title">My Account</h1>
      <ul className="nav-menu">
        {menuList.map((menu, index) => (
          <li
            key={index}
            className={`nav-list ${menu.isActive ? 'active' : ''}`}>
            <Link
              className="nav-menu-item"
              getProps={isPartiallyActive}
              to={menu.url}>
              {menu.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default AccountsVerticalMenu;
