import React, { useState } from 'react';
import LinkComponent from '../../route/LinkComponent';
import { Formik } from 'formik';
import { FormFeedback, FormGroup } from 'reactstrap';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { Alert, Col, notification, Row } from 'antd';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { handleForgotPasswordLogic } from '../../appRedux/action/Auth';
import { handleFieldError, handleNotify } from '../../appRedux/api';
import { NOTIFICATION_DURATION } from '../../appRedux/constants';
import { redirectToLogin, redirectToProfile } from '../../route/Redirect';
import * as Yup from 'yup';

function ForgotPassword(props) {
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);

  const handleIn = () => {
    notification['success']({
      message: 'Account Password!',
      description: 'Account password reset successfully',
      duration: NOTIFICATION_DURATION,
    });
    setLaddaProgress(0.9);
    setLaddaLoading(false);
    redirectToLogin();
  };

  const dispatch = useDispatch();

  const validate = Yup.object({
    AccountNumber: Yup.string()
      .length(
        10,
        'Account Number must be exactly 10 digits or Enter a valid Account Number',
      )
      .matches(/[0-9]+/gi, 'Account number must be a number')
      //.positive('Activation code must be greater than zero')
      .required('Account number is required'),
    // AccountNumber: Yup.number()
    //     .test(
    //         'len',
    //         'Account number must be exactly 10 characters',
    //         val => val && val.toString().length === 10,
    //     )
    //     .positive('Account number must be greater than zero')
    //     .typeError('Account number must be a number')
    //     .required('Account number is required'),
  });

  return (
    <>
      <>
        <div className="hero-box">
          <Row className={'hero-box-row'}>
            <Col
              sm={{ span: 24 }}
              md={{ span: 12 }}
              className={'padding_inner'}>
              <div>
                <>
                  <div className="top-form">
                    <div>
                      <h2 className="card_inner_title"> Forgot Password </h2>
                      <p className="card_inner_sub_title">
                        {' '}
                        Enter your account details to continue{' '}
                      </p>
                    </div>
                    <div>
                      <Formik
                        initialValues={{ AccountNumber: '' }}
                        validationSchema={validate}
                        onSubmit={(
                          values,
                          { setSubmitting, setErrors, resetForm },
                        ) => {
                          setLaddaLoading(true);
                          setLaddaProgress(0.5);

                          dispatch(
                            handleForgotPasswordLogic(
                              values.AccountNumber,
                              response =>
                                _.isEmpty(response.error) &&
                                response.error !== true
                                  ? handleIn()
                                  : handleNotify(
                                      response,
                                      setSubmitting,
                                      () => {
                                        setLaddaProgress(1.0);
                                        setLaddaLoading(false);
                                        handleFieldError(
                                          response.validationErrors,
                                          setErrors,
                                        );
                                        resetForm();
                                      },
                                    ),
                            ),
                          );
                        }}>
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            className={'login_form '}>
                            <div
                              className={'form_holder float_label form-group'}>
                              <div className="form-label-group-holder">
                                <div className="form-label-group">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="AccountNumber"
                                    id="inputAccount"
                                    placeholder="AccountNumber"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.AccountNumber}
                                  />
                                  <label htmlFor="inputAccount">
                                    {' '}
                                    Account Number{' '}
                                  </label>
                                  {errors.AccountNumber &&
                                    touched.AccountNumber && (
                                      <FormFeedback>
                                        {errors.AccountNumber}
                                      </FormFeedback>
                                    )}
                                </div>
                              </div>
                            </div>

                            <LaddaButton
                              loading={laddaLoading}
                              progress={laddaProgress}
                              data-style={SLIDE_UP}
                              data-spinner-size={30}
                              data-spinner-color="#fff"
                              data-spinner-lines={12}
                              className="btn-submit btn btn-primary btn-style"
                              type="submit"
                              disabled={isSubmitting}>
                              Reset Password
                            </LaddaButton>
                          </form>
                        )}
                      </Formik>
                    </div>

                    <div className={'text-center'}>
                      <div className="dot" />
                      <div className="text-center" style={{ marginTop: 28 }}>
                        <p>
                          {' '}
                          Have an account?{' '}
                          <LinkComponent
                            to={'/login'}
                            className="forget_password_link">
                            {' '}
                            Login{' '}
                          </LinkComponent>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Col>
            <Col
              sm={{ span: 0 }}
              md={{ span: 12 }}
              className={'pass_side_image access_img'}
            />
          </Row>
        </div>
      </>
    </>
  );
}

export default ForgotPassword;
