import React from 'react';
import { Row, Col } from 'antd';
import {
  InstagramOutlined,
  FacebookOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import FooterLogo from 'images/suregifts_logo_black.png';

import './Footer.scss';
import LinkComponent from '../../route/LinkComponent';
import { Logo } from '../index';
import CookieConsent from 'react-cookie-consent';

const Footer = () => {
  return (
    <>
      <div className={'footer_over elementor-motion-effects-element'}>
        <div className={'inner_over'}>
          <div className="container">
            <div>
              <h2>We Are Fidelity, We Keep Our Word</h2>
              <p>We are impacting over 5,000,000 customers across Nigeria</p>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <section className="footer-columns">
          <div className="container">
            <div>
              <>
                <Row gutter={[16, 16]} className="rex-mobile-footer">
                  <Col className="footer-column" xs={12} md={12} xl={4} xxl={4}>
                    <h3 className="footer-column-header "> Banking </h3>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/personal-banking/"
                        target="_blank">
                        Personal Banking
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/sme-banking/sme-academy/"
                        target="_blank">
                        SME Banking
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/digital-banking/"
                        target="_blank">
                        Digital Banking
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/corporate-banking/"
                        target="_blank">
                        Corporate Banking
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/private-banking/"
                        target="_blank">
                        Private Banking
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/personal-banking/diaspora-banking/"
                        target="_blank">
                        Diaspora Banking
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/agriculture-and-export/"
                        target="_blank">
                        Agric & Export Banking
                      </a>
                    </div>
                  </Col>
                  <Col className="footer-column" xs={12} md={12} xl={4} xxl={4}>
                    <h3 className="footer-column-header ">About Fidelity</h3>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/about-us/"
                        target="_blank">
                        About Us
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/careers/"
                        target="_blank">
                        Careers
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/investor-relations/"
                        target="_blank">
                        Investor Relations
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/csr/"
                        target="_blank">
                        CSR
                      </a>
                    </div>
                  </Col>
                  <Col className="footer-column" xs={12} md={12} xl={4} xxl={4}>
                    <h3 className="footer-column-header ">Help & Support</h3>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/contact-us/"
                        target="_blank">
                        True Serve
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/contact-us/frequently-asked-questions/"
                        target="_blank">
                        Partner with us
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/branch-locator/"
                        target="_blank">
                        Find a Branch
                      </a>
                    </div>
                    <div>
                      <a
                        className="footer-link"
                        href="https://www.fidelitybank.ng/contact-us/whistle-blower/"
                        target="_blank">
                        Whistle Blowing
                      </a>
                    </div>
                  </Col>
                  <Col className="footer-column" xs={12} md={12} xl={4} xxl={4}>
                    <h3 className="footer-column-header ">Quick Links</h3>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener"
                        className="footer-link"
                        href="https://online.fidelitybank.ng/">
                        Online Banking
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener"
                        className="footer-link"
                        href="https://www.fidelitybank.ng/sme-banking/loan-calculator/">
                        Loan Calculator
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener"
                        className="footer-link"
                        href="https://www.fidelitybank.ng/media-centre/">
                        Media
                      </a>
                    </div>
                  </Col>
                  <Col className="footer-column" xs={24} md={24} xl={8} xxl={8}>
                    <h3 className="footer-column-header ">Digital Channels</h3>
                    <div>
                      <Row className={'hov'}>
                        <Col span={10}>
                          <a target="_blank">
                            <img
                              width="140"
                              height="42"
                              src="https://www.fidelitybank.ng/wp-content/uploads/2020/07/Fidelity-Bank-USSD-770-1.png"
                              className="elementor-animation-float attachment-full size-full lazyloaded"
                              alt=""
                              data-was-processed="true"
                            />
                            <noscript>
                              <img
                                width="140"
                                height="42"
                                src="https://www.fidelitybank.ng/wp-content/uploads/2020/07/Fidelity-Bank-USSD-770-1.png"
                                className="elementor-animation-float attachment-full size-full"
                                alt=""
                              />
                            </noscript>
                          </a>
                        </Col>
                        <Col span={4} />
                        <Col span={10}>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.interswitchng.www&amp;hl=en"
                            target="_blank">
                            {' '}
                            <img
                              width="140"
                              height="42"
                              src="https://www.fidelitybank.ng/wp-content/uploads/2020/07/Fidelity-Bank-Mobile-App-Google.png"
                              className="elementor-animation-float attachment-full size-full lazyloaded"
                              alt=""
                              data-was-processed="true"
                            />
                            <noscript>
                              <img
                                width="140"
                                height="42"
                                src="https://www.fidelitybank.ng/wp-content/uploads/2020/07/Fidelity-Bank-Mobile-App-Google.png"
                                className="elementor-animation-float attachment-full size-full"
                                alt=""
                              />
                            </noscript>{' '}
                          </a>
                        </Col>
                        <Col span={10}>
                          <a href="https://wa.me/2349030000302" target="_blank">
                            {' '}
                            <img
                              width="140"
                              height="42"
                              src="https://www.fidelitybank.ng/wp-content/uploads/2020/07/Fidelity-Bank-whatsapp-IVY-1.png"
                              className="elementor-animation-float attachment-full size-full lazyloaded"
                              alt=""
                              data-was-processed="true"
                            />
                            <noscript>
                              <img
                                width="140"
                                height="42"
                                src="https://www.fidelitybank.ng/wp-content/uploads/2020/07/Fidelity-Bank-whatsapp-IVY-1.png"
                                className="elementor-animation-float attachment-full size-full"
                                alt=""
                              />
                            </noscript>{' '}
                          </a>
                        </Col>
                        <Col span={4} />
                        <Col span={10}>
                          <a
                            href="https://apps.apple.com/us/app/fidelity-online-banking/id1051038075"
                            target="_blank">
                            {' '}
                            <img
                              width="140"
                              height="42"
                              src="https://www.fidelitybank.ng/wp-content/uploads/2020/07/Fidelity-Bank-Mobile-App-Apple.png"
                              className="elementor-animation-float attachment-full size-full lazyloaded"
                              alt=""
                              data-lazy-src="https://www.fidelitybank.ng/wp-content/uploads/2020/07/Fidelity-Bank-Mobile-App-Apple.png"
                              data-was-processed="true"
                            />
                            <noscript>
                              <img
                                width="140"
                                height="42"
                                src="https://www.fidelitybank.ng/wp-content/uploads/2020/07/Fidelity-Bank-Mobile-App-Apple.png"
                                className="elementor-animation-float attachment-full size-full"
                                alt=""
                              />
                            </noscript>{' '}
                          </a>
                        </Col>
                      </Row>
                    </div>
                    {/*<p className="footer-text" style={{paddingRight: 0}}>*/}
                    {/*    For Points Enquiries*/}
                    {/*</p>*/}
                    {/*<p className="footer-contact-us">*/}
                    {/*    true.serve@fidelitybank.ng <br/>*/}
                    {/*    <small>0700 3433 5489</small>*/}
                    {/*</p>*/}
                    {/*<p className="footer-text" style={{paddingRight: 0}}>*/}
                    {/*    For Gift Card Orders*/}
                    {/*</p>*/}
                    {/*<p className="footer-contact-us">*/}
                    {/*    hello@suregifts.com.ng <br/>*/}
                    {/*    <small>0809 118 9900</small>*/}
                    {/*</p>*/}

                    {/*<hr/>*/}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 20 }}>
                    <div className="elementor-text-editor elementor-clearfix footerstyle">
                      <p>
                        <span className="footer-text-style">
                          RC 103022. Copyright {new Date().getFullYear()}{' '}
                          Fidelity Bank Plc. An authorised financial services
                          and credit provider. <br />{' '}
                          <a
                            href="https://sqlvae7svwgvdevoto.blob.core.windows.net/loyaltydocs/Data-Privacy-Policy.pdf"
                            // href="https://www.fidelitybank.ng/privacy-policy/"
                            target="_blank"
                            rel="noopener">
                            z Privacy Policy
                          </a>{' '}
                          |{' '}
                          <a
                            href="https://www.fidelitybank.ng/wp-content/uploads/2019/03/COMMUNICATIONS-POLICY-1.pdf"
                            target="_blank"
                            rel="noopener">
                            Communication Policy
                          </a>{' '}
                          |{' '}
                          <a href="https://www.fidelitybank.ng/terms-and-conditions/">
                            Terms and Conditions
                          </a>{' '}
                          |{' '}
                          <a href="https://www.fidelitybank.ng/site-map/">
                            Sitemap
                          </a>
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 4 }}
                    className="footer-column">
                    {/*<Logo style={{marginTop: 20, marginLeft: 0}}/>*/}
                    <h4 className="mini-text">Powered by</h4>

                    <img
                      className="footer-logo"
                      src={FooterLogo}
                      alt="suregifts logo"
                    />
                    {/*<p className="footer-text">*/}
                    {/*    We're brand strategy and digital design agency, building*/}
                    {/*    brands that matter in culture.*/}
                    {/*</p>*/}
                  </Col>
                </Row>
              </>
            </div>
          </div>
        </section>
      </footer>

      <div>
        <div className={'socializer'}>
          <div className="sr-facebook">
            <a
              rel="nofollow"
              href="https://www.facebook.com/FidelityBankplc"
              target="_blank"
              title="Facebook"
              className={'facebook'}>
              <i className="fa fa-facebook" />
            </a>
          </div>
          <div className="sr-instagram">
            <a
              rel="nofollow"
              href="https://instagram.com/fidelitybankplc/"
              target="_blank"
              title="Instagram"
              className={'instagram'}>
              <i className="fa fa-instagram" />
            </a>
          </div>
          <div className="sr-linkedin">
            <a
              rel="nofollow"
              href="https://www.linkedin.com/company/fidelitybankplc"
              target="_blank"
              title="Linkedin"
              className={'linkedin'}>
              <i className="fa fa-linkedin" />
            </a>
          </div>
          <div className="sr-twitter">
            <a
              rel="nofollow"
              href="https://twitter.com/fidelitybankplc"
              target="_blank"
              title="Twitter"
              className={'twitter'}>
              <i className="fa fa-twitter" />
            </a>
          </div>
          <div className="sr-youtube">
            <a
              rel="nofollow"
              href="https://www.youtube.com/channel/UCkAIpl3FNXA02ByMuW6-ILw"
              target="_blank"
              title="Youtube"
              className={'youtube'}>
              <i className="fa fa-youtube-play" />
            </a>
          </div>
          <div className="sr-email">
            <a
              rel="nofollow"
              href="mailto:true.serve@fidelitybank.ng"
              target="_blank"
              title="email"
              className={'email'}>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: '#202020' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}>
        {' '}
        This website uses cookies to provide you with the best browsing
        experience.{' '}
      </CookieConsent>
    </>
  );
};

export default Footer;
