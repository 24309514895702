import React, { useState, useEffect } from 'react';
import {
  Empty,
  Row,
  Col,
  Button,
  Table,
  InputNumber,
  Skeleton,
  Modal,
  Popconfirm,
  Select,
  notification,
  Card,
  Divider,
  Spin,
} from 'antd';
import {
  DeleteOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import _, { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';

// internal inputs
import {
  fetchWishlist,
  removeItemFromWishlist,
} from 'appRedux/action/Wishlist';

import './Wishlist.scss';
import { AccountsVerticalMenu, Loading, PointBalance } from '../../components';
import LinkComponent from '../../route/LinkComponent';
import { handleFieldError, handleNotify } from 'appRedux/api';
import { NOTIFICATION_DURATION } from 'appRedux/constants';

const { Option } = Select;
const { Meta } = Card;

const sideMenu = [
  {
    title: 'Summary',
    isActive: false,
    url: '/summary',
  },
  {
    title: 'Point History',
    isActive: false,
    url: '/points-history',
  },
  {
    title: ' Order History',
    isActive: false,
    url: '/order-history',
  },
  {
    title: 'Profile',
    isActive: false,
    url: '/profile',
  },
  {
    title: 'Wishlist',
    isActive: true,
    url: '/wishlist',
  },
  {
    title: 'Logout',
    isActive: false,
    url: '/logout',
  },
];

const Wishlist = props => {
  const [pointBalance, setPointBalance] = useState(0);
  const [removeItemProgress, setRemoveItemProgress] = useState(false);
  const [menu, setMenu] = useState(false);
  /**
   *
   *   FETCH WISHLIST LOGIC here
   *
   */

  const dispatch = useDispatch();
  const { wishlist, loading } = useSelector(state => state.wishlist);
  const { authUser, profile_loading } = useSelector(state => state.auth);

  useEffect(() => {
    setPointBalance(authUser.points);
  }, [authUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchWishlist());
  }, []);

  /**
   *
   *  TABLE COLUMN LOGIC STARTS here
   *
   */
  const columns = [
    {
      title: 'Item',
      dataIndex: 'decription',
      width: '50%',
      render: (text, record) => (
        <>
          <div className="item-container">
            <div className="item-img">
              {/* placeholder while image is loading */}
              {false ? (
                <span className="skeleton-container">
                  <Skeleton.Input
                    style={{ width: 140 }}
                    shape="square"
                    active
                    size="large"
                  />
                  <Skeleton.Input
                    style={{ width: 140 }}
                    shape="square"
                    active
                    size="large"
                  />
                </span>
              ) : (
                <img
                  className="order-image"
                  src={record.imageUrl}
                  alt="order image"
                />
              )}
            </div>
            <div className="item-details">
              <LinkComponent to={'/gift-card/' + record.giftId}>
                <h4 className="item-name"> {record.name} </h4>
              </LinkComponent>
              <p className="item-desc"> {record.shortDesc} </p>
            </div>
          </div>
        </>
      ),
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (record, item) => (
        <>
          {/* <span
            className="delete-icon"
            onClick={() => confirmDeleteCartItem(item)}>
            <DeleteOutlined />
          </span> */}
          <Popconfirm
            overlayClassName="rounded-pop"
            title={
              <span>
                Do you want to <strong>REMOVE</strong> item from Wishlist?
              </span>
            }
            onConfirm={() => confirmDeleteCartItemPop(item)}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
            cancelButtonProps={{
              style: {
                width: 75,
                height: 27,
                borderRadius: '4px',
              },
            }}
            okButtonProps={{
              style: {
                width: 75,
                height: 27,
                backgroundColor: '#e02020',
                border: 'none',
                borderRadius: '4px',
              },
            }}>
            <span className="delete-icon">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  /**
   *
   *  TABLE COLUMN LOGIC ENDS here
   *
   */

  /**
   * confirmm modal end here
   */

  // ⚠️  Tooltip solution to Delete from wishlist
  function confirmDeleteCartItemPop(item) {
    setRemoveItemProgress(true);
    dispatch(
      removeItemFromWishlist(item.giftId, () => {
        dispatch(fetchWishlist());
        setRemoveItemProgress(false);
      }),
    );
  }

  return (
    <>
      <main className="giftcard-view-section">
        <div className="accounts-container">
          <Button
            onClick={() => setMenu(true)}
            color="secondary"
            className="tstyle"
            size={'large'}
            shape="round">
            {' '}
            Account Side Menu{' '}
          </Button>
          <Row justify="end">
            <Col>
              <PointBalance />
            </Col>
          </Row>
          <Row className="account-columns" gutter={64}>
            <Col
              className={`account-column-1 ${menu &&
                'mobile_menu_profile_holder'}`}
              span={6}>
              <AccountsVerticalMenu menuList={sideMenu} />

              {/*<AccountsVerticalMenu*/}
              {/*      menuList={sideMenu}*/}
              {/*      setMenuOff={() => setMenu(false)}*/}
              {/*  />*/}
            </Col>
            <Col
              className="account-column-2"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}>
              {loading ? (
                <Loading />
              ) : _.isEmpty(wishlist) ? (
                <>
                  <Empty
                    imageStyle={{
                      height: 150,
                    }}
                    description={
                      <>
                        <span className="empty-card-header h4">
                          You have no items in your wishlist
                        </span>{' '}
                        <LinkComponent to={'/gift-cards'}>
                          <Button
                            className="fd-button primary"
                            type="link"
                            shape="round">
                            View Giftcards
                          </Button>
                        </LinkComponent>
                      </>
                    }
                  />
                </>
              ) : (
                <>
                  <BrowserView>
                    <Row className="content-wrapper">
                      <Col span={24}>
                        <div className="cart-title-container">
                          <h2 className="title">
                            Wishlist (
                            {wishlist
                              ? `${wishlist.length} item${
                                  wishlist.length > 1 ? 's' : ''
                                }`
                              : `0 items`}
                            )
                          </h2>
                        </div>
                        <div className="cart-table-wrapper">
                          <Table
                            className="cart-table"
                            rowKey={record => record.id}
                            pagination={false}
                            //   showHeader={false}
                            loading={removeItemProgress}
                            size="middle"
                            dataSource={wishlist}
                            columns={columns}
                          />
                        </div>
                      </Col>
                    </Row>
                  </BrowserView>
                  <MobileView>
                    <Row className="mt-4" justify="space-between">
                      <Col>
                        <h3 className="mobile-title">
                          Wishlist (
                          {wishlist
                            ? `${wishlist.length} item${
                                wishlist.length > 1 ? 's' : ''
                              }`
                            : `0 items`}
                          )
                        </h3>
                      </Col>
                    </Row>

                    <Row justify="center" gutter={[0, 8]}>
                      <Spin spinning={removeItemProgress}>
                        {wishlist.map((record, index) => (
                          <Col key={index} span={24}>
                            <Card className="mobile-cart-card">
                              <Row type="flex" justify="space-between">
                                <Col span={8}>
                                  <div className="item-img">
                                    <img
                                      className="mobile-cart-image"
                                      src={record.imageUrl}
                                      alt="order image"
                                    />
                                  </div>
                                </Col>
                                <Col span={16}>
                                  <LinkComponent
                                    to={'/gift-card/' + record.giftId}>
                                    <h4 className="mobile-item-name">
                                      {record.name}
                                    </h4>
                                  </LinkComponent>
                                  <h5 className="mobile-item-details">
                                    {record.shortDesc}
                                  </h5>
                                </Col>
                              </Row>
                              <Divider></Divider>
                              <div className="mobile-footer-wrapper">
                                <Popconfirm
                                  overlayClassName="rounded-pop"
                                  title={
                                    <span>
                                      Do you want to <strong>REMOVE</strong>{' '}
                                      item from Wishlist?
                                    </span>
                                  }
                                  onConfirm={() =>
                                    confirmDeleteCartItemPop(record)
                                  }
                                  placement="topRight"
                                  okText="Yes"
                                  cancelText="No"
                                  cancelButtonProps={{
                                    style: {
                                      width: 70,
                                      height: 24,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  okButtonProps={{
                                    style: {
                                      width: 70,
                                      height: 24,
                                      backgroundColor: '#e02020',
                                      border: 'none',
                                      borderRadius: '4px',
                                    },
                                  }}>
                                  <div className="mobile-delete">
                                    Delete
                                    <DeleteOutlined className="delete-icon" />
                                  </div>
                                </Popconfirm>
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Spin>
                    </Row>
                  </MobileView>
                </>
              )}
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

export default Wishlist;
