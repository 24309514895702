import React from 'react';
// import _ from 'lodash';
// import LinkComponent from '../../route/LinkComponent';
// import UserOutlined from "@ant-design/icons"

function Avatar(props) {
  // const {data, link} = props;
  // const [profileAvatar, setProfileAvatar] = useState(null);

  // useEffect(() => {
  //     const pAvatar =
  //         !_.isEmpty(data.avatar) && data.avatar ? (
  //             <img src={data.avatar} className="avatar-img" alt="user avatar"/>
  //         ) : (
  //             <span className="overlay__content text-uppercase text-center">
  //       {data.first_name.slice(0, 1)}
  //                 {data.last_name.slice(0, 1)}
  //     </span>
  //         );
  //
  //     setProfileAvatar(pAvatar);
  // }, [data.avatar, data.first_name, data.last_name]);

  return (
    <>
      fffffff
      {/*<Avatar size="large" icon={<UserOutlined />} />*/}
      {/*<div*/}
      {/*    className="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt">*/}
      {/*    {!_.isEmpty(link) && link ? (*/}
      {/*        <LinkComponent>*/}
      {/*            <>{profileAvatar}</>*/}
      {/*        </LinkComponent>*/}
      {/*    ) : (*/}
      {/*        <>{profileAvatar}</>*/}
      {/*    )}*/}
      {/*</div>*/}
    </>
  );
}

export default Avatar;
