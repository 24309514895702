import React from 'react';
import { Router } from '@reach/router';
import Routes from './index';
import AppComponent from './AppComponent';
import { Missing404 } from '../components/NotFound';

function RouteComponent(props) {
  const { location } = props;
  const RouteList = Routes.map((route, index) => {
    let routeComponent = null;
    let component = route.component;
    let layout = route.layout;

    if (typeof route.children !== 'undefined') {
      const childrens = route.children;
      const childrenComponent = childrens.map((route, index) => (
        <AppComponent
          key={index}
          route={route}
          path={route.path}
          auth={route.auth}
          component={component}
          layout={layout}
          location={location}
        />
      ));

      routeComponent = (
        <AppComponent
          route={route}
          key={index}
          path={route.path}
          auth={route.auth}
          component={component}
          location={location}
          layout={layout}>
          {childrenComponent}
        </AppComponent>
      );
    } else {
      routeComponent = (
        <AppComponent
          key={index}
          route={route}
          path={route.path}
          auth={route.auth}
          component={component}
          layout={layout}
          location={location}
        />
      );
    }
    return routeComponent;
  });

  return (
    <>
      <Router location={location}>
        {RouteList}
        <Missing404 default />
      </Router>
    </>
  );
}

export default RouteComponent;
