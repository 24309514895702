import React, { useState } from 'react';
import { Row, Col, Radio, Input, Button, Alert, notification } from 'antd';
import { Link } from '@reach/router';

import activationImg from '../../images/activation-img.jpg';
import { Formik } from 'formik';
import _ from 'lodash';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import LinkComponent from '../../route/LinkComponent';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { handleActivation } from '../../appRedux/action/Auth';
import { NOTIFICATION_DURATION } from '../../appRedux/constants';
import { redirectVerification } from '../../route/Redirect';
import { handleNotify } from '../../appRedux/api';

function Activation(props) {
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(false);
  const [notificationType, setNotificationType] = useState('error');
  const [radioChange, setRadioChange] = useState(1);

  const onClose = e => {};

  const handleIn = () => {
    // notification['success']({
    //     message: 'Account activation!',
    //     description: 'Activated successfully',
    //     duration: NOTIFICATION_DURATION,
    // });
    setLaddaProgress(0.9);
    redirectVerification();
  };

  const onRadioChange = e => {
    setRadioChange(e.target.value);
  };

  const validate = Yup.object({
    account_no: Yup.string()
      .length(
        10,
        'Account Number must be exactly 10 digits or Enter a valid Account Number',
      )
      .matches(/[0-9]+/gi, 'Account Number code must be a number')
      //.positive('Activation code must be greater than zero')
      .required('Account Number is required'),
    consent: Yup.boolean()
      .required('The terms must be accepted.')
      .oneOf([true], 'The terms must be accepted.'),
    // account_no: Yup.number()
    //     .test(
    //         'len',
    //         'Account Number must be exactly 10 digits or Enter a valid Account Number',
    //         // val => val && val.toString().length === 10,
    //         // val => val && val.toString() === 10,
    //     )
    //     .positive('Activation code must be greater than zero')
    //     .typeError('Activation code must be a number')
    //     .required('Activation code is required'),
  });

  return (
    <>
      <>
        <div className="hero-box">
          <Row className={'hero-box-row'}>
            <Col
              sm={{ span: 24 }}
              md={{ span: 12 }}
              className={'padding_inner'}>
              <div>
                <>
                  <div className="top-form">
                    <div>
                      <h2 className="card_inner_title"> Activation </h2>
                      <p className="card_inner_sub_title">
                        Enter your Fidelity account no. or debit/credit to
                        activate your membership
                      </p>
                    </div>
                    <div>
                      <Formik
                        initialValues={{ account_no: '', consent: false }}
                        validationSchema={validate}
                        onSubmit={(values, { setSubmitting }) => {
                          setLaddaLoading(true);
                          setLaddaProgress(0.5);

                          props.handleActivation(values.account_no, response =>
                            _.isEmpty(response.error) && response.error !== true
                              ? (() => {
                                  // setMessage('OTP sent');
                                  // setMessage(response.message);
                                  // handleIn()

                                  notification['success']({
                                    message: '',
                                    description: response.message,
                                    duration: NOTIFICATION_DURATION,
                                  });
                                  setLaddaProgress(0.9);
                                  redirectVerification();
                                })()
                              : handleNotify(response, setSubmitting, () => {
                                  setLaddaProgress(1.0);
                                  setLaddaLoading(false);
                                }),
                          );
                        }}>
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            className={'login_form '}>
                            <div>
                              <div className="">
                                <Radio.Group
                                  onChange={onRadioChange}
                                  value={radioChange}>
                                  <Radio value={1}>Account No</Radio>
                                  {/*<Radio value={2}>Debit/Credit Card</Radio>*/}
                                </Radio.Group>
                              </div>
                            </div>
                            <div
                              className={'form_holder float_label form-group'}>
                              <div className="form-label-group-holder">
                                <div className="form-label-group">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="account_no"
                                    id="inputAccount"
                                    placeholder="Enter Account Number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.account_no}
                                  />
                                  <label htmlFor="inputAccount">
                                    Enter Account Number
                                  </label>
                                  {errors.account_no && touched.account_no && (
                                    <FormFeedback>
                                      {errors.account_no}
                                    </FormFeedback>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="checkbox mb-3">
                                <FormGroup check>
                                  <Label className={'consent-me'} check>
                                    <input
                                      className="instyle"
                                      checked={values.consent}
                                      name="consent"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="checkbox"
                                    />{' '}
                                    <>
                                      {' '}
                                      I hereby consent to the collection,
                                      processing, use and transfer of my
                                      personal data, within or outside Nigeria,
                                      for the purpose stated{' '}
                                      <a
                                        href="https://sqlvae7svwgvdevoto.blob.core.windows.net/loyaltydocs/Data-Privacy-Policy.pdf"
                                        target="_BLANK">
                                        {' '}
                                        herein{' '}
                                      </a>
                                    </>
                                  </Label>
                                  {errors.consent && touched.consent && (
                                    <FormFeedback>
                                      {errors.consent}
                                    </FormFeedback>
                                  )}
                                </FormGroup>
                              </div>
                            </div>

                            <LaddaButton
                              loading={laddaLoading}
                              progress={laddaProgress}
                              data-style={SLIDE_UP}
                              data-spinner-size={30}
                              data-spinner-color="#fff"
                              data-spinner-lines={12}
                              className="btn-submit btn btn-primary btn-style"
                              type="submit"
                              disabled={isSubmitting}>
                              Continue
                            </LaddaButton>
                          </form>
                        )}
                      </Formik>
                    </div>

                    <div className={'text-center'}>
                      <div className="dot" />
                      <div className="text-center" style={{ marginTop: 28 }}>
                        <p>
                          {' '}
                          Have an account?{' '}
                          <LinkComponent
                            to={'/login'}
                            className="forget_password_link">
                            {' '}
                            Login{' '}
                          </LinkComponent>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Col>
            <Col
              sm={{ span: 0 }}
              md={{ span: 12 }}
              className={'act_side_image access_img'}
            />
          </Row>
        </div>
      </>
    </>
  );
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { handleActivation })(Activation);
