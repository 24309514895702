import {
  FETCH_TRANSACTIONS_FAILED,
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS,
} from '../constants';

const INIT_STATE = {
  transactions: [],
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TRANSACTIONS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        transactions: action.payload,
      };
    }
    case FETCH_TRANSACTIONS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
