import React, { useState } from 'react';
import _ from 'lodash';
import { Loading, Missing404 } from '../components';
import { notification } from 'antd';
import { NOTIFICATION_DURATION } from '../appRedux/constants';
import { Redirect, redirectTo } from '@reach/router';
import {
  getSession,
  getSessionJsonWithKey,
  getSessionWithKey,
  setSessionWithValue,
} from '../appRedux/store/cookies';
import {
  getProfileRefreshToken,
  getProfileRefreshTokens,
  userSignOut,
} from '../appRedux/action/Auth';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Activation,
  CreatePassword,
  ForgotPassword,
  Login,
  Register,
  Verification,
} from '../containers';
import { Access } from '../layouts';

function AppComponent(props) {
  // const dispatch = useDispatch;
  const [authUser, setAuthUser] = useState(
    _.isEmpty(getSessionJsonWithKey('user'))
      ? []
      : getSessionJsonWithKey('user'),
  );

  // const {authUser} = useSelector(state => state.auth);
  //console.log('authUser', authUser, !_.isEmpty(authUser));

  let { component, layout, route, auth, location } = props;
  const { path } = route;

  const session = getSession(); // current logged in user token
  const profile = {};

  let componentRoute = Loading;
  const layoutHandler = (lay, comp) => {
    if (typeof comp == 'undefined' && _.isEmpty(layout)) {
      props = { ...props, componentMissing: true };
      return Missing404; // component = Missing404({componentMissing: true})
    } else {
      return typeof lay == 'undefined' && _.isEmpty(lay)
        ? comp
        : React.memo(lay(comp, { profile }));
    }
  };

  // console.log('session', _.isEmpty(session), auth, path);

  // const loginNotice = (notify = true) => {
  //     (notify && notification['error']({
  //         message: 'User not logged in!',
  //         description: 'Error, Please login to continue',
  //         duration: NOTIFICATION_DURATION,
  //     }));
  //     //props.userSignOut();
  // }

  // console.log(path !== '/login', _.isEmpty(authUser) ,typeof authUser === 'undefined' ,authUser === null)

  if (
    (path !== '/login' ||
      path !== '/register' ||
      path !== '/activation' ||
      path !== '/verification' ||
      path !== '/forgot-password' ||
      path !== '/create-password') &&
    (!_.isEmpty(authUser) ||
      typeof authUser === 'undefined' ||
      authUser === null)
  ) {
    // console.log('authUser', authUser);
  }

  if (typeof path !== 'undefined' && path === '/logout') {
    const login = '/login';
    userSignOut();
    return <Redirect from={path} to={login} noThrow />;
  }

  if (typeof auth !== 'undefined' && auth === true) {
    // const session_expiration = getSessionWithKey('___session_expiration');
    // const currentTime = moment().format();
    // console.log(session_expiration);
    // console.log(
    //   '' + 'currenttime-',
    //   moment().format(),
    // );
    // console.log(
    //   'sessiontime-',
    //   session_expiration,
    //   // moment().format() > session_expiration
    // );
    // // console.log(currentTime > session_expiration);
    //
    // // console.log(moment().format(), session_expiration)
    // // if (moment().format() > session_expiration) {
    // const cTime = moment(currentTime, 'h:mma');
    // const sTime = moment(session_expiration, 'h:mma');
    // console.log(cTime, sTime);
    // console.log(cTime.isBefore(sTime));
    // // if (cTime.isBefore(sTime)) {
    //   //     const login = '/login';
    //   //     notification['error']({
    //   //         message: 'Auth!',
    //   //         description: 'Error, Account logged out',
    //   //         duration: NOTIFICATION_DURATION,
    //   //     });
    //   //     return <Redirect from={path} to={login} noThrow/>;
    //   console.log('kkkkkkkkk');
    //   getProfileRefreshTokens(res => {
    //     console.log('path', res, path);
    //     //return redirectTo(path)
    //   });
    // // }

    if (
      _.isEmpty(session) ||
      typeof session === 'undefined' ||
      session === null ||
      authUser === null
    ) {
      notification['error']({
        message: 'User not logged in!',
        description: 'Please login to continue',
        duration: NOTIFICATION_DURATION,
      });

      //console.log('path', path, route, location);
      if (path === '/gift-card/:id' || path === '/airtime/:id') {
        setSessionWithValue(location.pathname, 'urlKeep');
      }

      userSignOut();
      const login = '/login';
      return <Redirect from={path} to={login} noThrow />;
    }

    // else {
    //     if (
    //         _.trim(route.path) === '/login' ||
    //         _.trim(route.path) === '/reset-password' ||
    //         (_.trim(route.path) === '/forgot-password' &&
    //             (_.isEmpty(session) === true || _.isEmpty(props.authUser) === null))
    //     ) {
    //         console.log('getProfile');
    //         // props.getProfile(
    //         //     (response) => _.isEmpty(response.error) && response.error !== true
    //         //         ? console.log(response)
    //         //         : console.log(response) //loginNotice()
    //         // )
    //     }
    //
    //     if (typeof session === 'undefined' || _.isEmpty(session)) {
    //         notification['error']({
    //             message: 'User not logged in!',
    //             description: 'Error, Please login to continue',
    //             duration: NOTIFICATION_DURATION,
    //         });
    //         const login = '/login';
    //         return <Redirect from={path} to={login} noThrow/>;
    //     }
    // }
  }

  return React.createElement(layoutHandler(layout, component), {
    path: path,
    props: props,
    auth_route_checker: auth,
    ...props,
  });
}

export default AppComponent;
