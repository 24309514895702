import {
  COMPLETE_PAYMENT,
  COMPLETE_PAYMENT_FAILED,
  COMPLETE_PAYMENT_START,
  COMPLETE_PAYMENT_SUCCESS,
} from '../constants';
import api from '../api';

export const completePayment = (data, callback) => {
  return dispatch => {
    dispatch({ type: COMPLETE_PAYMENT_START });
    const params = JSON.stringify(data, null, 2);

    api
      .post(COMPLETE_PAYMENT, params)
      .then(({ data }) => {
        dispatch({ type: COMPLETE_PAYMENT_SUCCESS, payload: data });
        return callback(data);
      })
      .catch(e => {
        dispatch({ type: COMPLETE_PAYMENT_FAILED });
        return callback({ error: true, ...e });
      });
  };
};
