import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Layout,
  Row,
  Input,
  Spin,
  Select,
  Divider,
  Empty,
} from 'antd';
import Road from '../../assets/img/road.png';
import LinkComponent from '../../route/LinkComponent';
import { FrontHeader } from '../../components/Header';
import { connect } from 'react-redux';
import {
  getGiftCards,
  getGiftCardsCategories,
  getGiftCardsLocations,
} from '../../appRedux/action/GiftCards';
import _ from 'lodash';
import {
  handleFieldError,
  handleNotify,
  handleNotifyOnly,
} from '../../appRedux/api';
import { FETCH_ERROR_MESSAGE } from '../../appRedux/constants';
import { Loading } from '../../components';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { Search } = Input;

function GiftCards(props) {
  const [filterCats, setFilterCats] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [filterLocations, setFilterLocations] = useState([]);
  const [giftCardsData, setGiftCardsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [filtered, setFiltered] = useState(false);

  let {
    giftCards,
    loading,
    giftCardsCategories,
    loadingCategories,
    giftCardsLocations,
    loadingLocations,
  } = props.giftCards;

  useEffect(() => {
    window.scrollTo(0, 0);

    props.getGiftCards({});
    props.getGiftCardsCategories();
    props.getGiftCardsLocations();
  }, []);

  useEffect(() => {
    setGiftCardsData(giftCards);
    setFilterCats([]);
    setSearchTerm();
  }, [giftCards]);

  useEffect(() => {
    setFiltering(true);
    let filteredList = giftCards;
    if (filterCats.length > 0) {
      filteredList = filteredList.filter(item =>
        filterCats.includes(item.category),
      );
    }

    if (!_.isEmpty(searchTerm)) {
      let term = searchTerm.toLowerCase();
      filteredList = filteredList.filter(item => {
        return (
          item.name.toLowerCase().includes(term) ||
          item.shortDesc.toLowerCase().includes(term) ||
          item.description.toLowerCase().includes(term)
        );
      });
    }

    if (filterLocations.length > 0) {
      filteredList = filteredList.filter(item => {
        let matched = false;
        filterLocations.some(location => {
          const locationData = giftCardsLocations
            .filter(item => item.name === location)
            .reduce((prev, current) => current);

          console.log('item', location);
          if (!_.isEmpty(item.locations)) {
            if (item.locations.includes(locationData.id)) {
              matched = true;
              return true;
            }
          }
        });
        return matched;
      });
    }

    // console.log(filteredList)

    setGiftCardsData(filteredList);
    setTimeout(() => {
      setFiltering(false);
      setFiltered(true);
    }, 100);
  }, [filterCats, searchTerm, filterLocations]);

  const handleRes = useCallback(response => {
    _.isEmpty(response.error) && response.error !== true
      ? (function() {
          // const gfCat = giftCardsCat;
          // const setGiftCardsData = response.map(giftCard => {
          //   gfCat.push(giftCard.category);
          //   return giftCard;
          // });
          // gfCat.filter(gfCatData => (gfCatData !== gfCatData) && gfCatData);
          // console.log(gfCat);
          // setGiftCardsCat(gfCat);
          // setGiftCards(setGiftCardsData);
        })()
      : handleNotifyOnly({ message: FETCH_ERROR_MESSAGE }, () => {});
  });

  const handleSearch = data => {
    setSearchTerm(data);
  };

  const handleCategoryChange = checkedList => {
    setFilterCats(checkedList);
  };

  const handleLocationChange = selected => {
    setFilterLocations(selected);
  };

  const CategoriesOptions = giftCardsCategories.map(cat => cat.name);

  // console.log(giftCardsLocations);
  const LocationsOptions = giftCardsLocations.map((location, index) => (
    <Select.Option key={index} value={location.name}>
      {location.name}
    </Select.Option>
  ));

  const giftList = giftCardsData.map((gift, index) => (
    <Col
      className="gutter-row"
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 8 }}
      key={index}>
      <Card
        hoverable
        style={{ width: 'auto' }}
        cover={<img src={gift.imageUrl} style={{ height: '173px' }} />}>
        <LinkComponent to={'/gift-card/' + gift.giftId}>
          <Button type="primary" shape="round" className="btn-b fid">
            Redeem
          </Button>
        </LinkComponent>
        <Meta title={gift.name} description={gift.shortDesc} />
      </Card>
    </Col>
  ));

  const searchSuffix = (
    <span>
      {searchTerm && searchTerm.length > 0 ? (
        <CloseOutlined onClick={() => handleSearch('')} />
      ) : (
        ''
      )}
    </span>
  );

  return (
    <>
      <Layout className="mask mt-n1 gift_img">
        <div className="layer">
          {/*<FrontHeader />*/}
          <div className={'header_ti'}>
            <div className="container text-center">
              <h3 className="blue"> Convert your points to Gift Cards </h3>
              <p className="px-5 blue">
                Redeem your loyalty points to get gift cards for the best stores
                in Nigeria for yourself or loved ones.
              </p>
            </div>
          </div>
        </div>
      </Layout>

      <div className="container">
        <div>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              md={{ span: 16, offset: 4 }}>
              <div className="search">
                <Search
                  placeholder="input search text"
                  enterButton={
                    <>
                      <SearchOutlined
                        className="d-block d-md-none"
                        style={{ marginTop: '-12px' }}
                      />
                      <span className="d-none d-md-block">Search</span>
                    </>
                  }
                  size="large"
                  onSearch={value => handleSearch(value)}
                  onChange={event => handleSearch(event.target.value)}
                  suffix={searchSuffix}
                  value={searchTerm}
                />
              </div>
            </Col>
          </Row>

          <div className="gift_holder">
            <Row gutter={16}>
              <Col
                className="gutter-row"
                xs={{ span: 24, order: 2 }}
                md={{ span: 24, order: 2 }}
                lg={{ span: 6, order: 1 }}>
                <div className={'check_list'}>
                  <div className="holder">
                    <div>
                      <h3> Categories </h3>
                    </div>
                    <div>
                      <Spin spinning={loadingCategories}>
                        <Checkbox.Group
                          options={CategoriesOptions}
                          value={filterCats}
                          onChange={handleCategoryChange}
                        />
                      </Spin>
                    </div>
                    <Divider />
                    <div>
                      <h3> Locations </h3>
                    </div>
                    <div>
                      <Spin spinning={loadingLocations}>
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Choose location"
                          // value={filterCats}
                          onChange={handleLocationChange}>
                          {LocationsOptions}
                          {/*<Select.Option value="nationwide"> Nationwide </Select.Option>*/}
                        </Select>
                      </Spin>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={{ sapn: 24, order: 1 }}
                md={{ span: 24, order: 1 }}
                lg={{ span: 18, order: 2 }}>
                {loading ? (
                  <Loading />
                ) : (
                  <div>
                    <div className={'gift_list_holder'}>
                      <Spin spinning={filtering}>
                        <Row>
                          {filtered === true && _.isEmpty(giftCardsData) ? (
                            <Empty
                              style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                            />
                          ) : (
                            giftList
                          )}
                        </Row>
                      </Spin>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ giftCards }) => ({
  giftCards,
});

const mapDispatchToProps = {
  getGiftCards,
  getGiftCardsCategories,
  getGiftCardsLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftCards);
