import React from 'react';
import LinkComponent from '../../route/LinkComponent';
import { Row, Col } from 'antd';

function Register(props) {
  return (
    <>
      <div className="text-center top-form">
        <h2 className="f-w-400">Create an account</h2>
      </div>
      <div className={'login_form'}>
        <form action="index.html">
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  First Name:
                </label>
                <input
                  id="first_name"
                  type="text"
                  className="form-control"
                  placeholder="Your first name..."
                />
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Last Name:
                </label>
                <input
                  id="last_name"
                  type="text"
                  className="form-control"
                  placeholder="Your last name..."
                />
              </div>
            </Col>
          </Row>
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              Email Address:
            </label>
            <input
              id="email"
              type="text"
              className="form-control"
              placeholder="Your email address ..."
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="password">
              Password:
            </label>
            <input id="password" type="password" className="form-control" />
          </div>
          <div className="text-center">
            <button className="btn btn-primary btn-sign">Sign Up</button>
          </div>
        </form>

        <p className={'agree_term text-center'}>
          By creating an account, I agree to the{' '}
          <LinkComponent className={'link'} to={'/'}>
            <strong>Terms of Service</strong>
          </LinkComponent>
          ,{' '}
          <LinkComponent className={'link'} to={'/'}>
            <strong>Privacy Policy</strong>
          </LinkComponent>
          , and{' '}
          <LinkComponent className={'link'} to={'/'}>
            <strong>Cookie Policy</strong>
          </LinkComponent>
          .
        </p>
      </div>
      <hr />
      <div className="text-center">
        <p className={'mt-4'}>
          Already have an account?{' '}
          <LinkComponent to={'/login'} className={'link'}>
            Sign In
          </LinkComponent>
        </p>
      </div>
    </>
  );
}

export default Register;
