import React from 'react';
import LinkComponent from '../../route/LinkComponent';
import InnerHeader from '../../components/Header/InnerHeader';
import Footer from '../../components/Footer';
import { Col, Layout } from 'antd';
import { FrontHeader } from '../../components/Header';

const Landing = (Component, props) => innerProps => (
  <>
    <InnerHeader />

    <Layout className={'top-fixed'} style={{ minHeight: '100vh' }}>
      <Component {...props} {...innerProps} />
      <Footer />
    </Layout>
  </>
);

export default Landing;
