import {
  FETCH_ACCOUNT_SUMMARY_FAILED,
  FETCH_ACCOUNT_SUMMARY_START,
  FETCH_ACCOUNT_SUMMARY_SUCCESS,
} from '../constants';

const INIT_STATE = {
  accountSummary: [],
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SUMMARY_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_ACCOUNT_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        accountSummary: action.payload,
      };
    }
    case FETCH_ACCOUNT_SUMMARY_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
