import {
  ACTIVATION_ACCOUNT_NO,
  REGISTER_ACCOUNT,
  GLOBAL_FETCH_START,
  SIGNIN_USER_SUCCESS,
  VALIDATE_ACCOUNT_CODE,
  SIGNOUT_USER_SUCCESS,
  PROFILE_LOADING,
  PROFILE_DONE,
  MAIN_LOADING_ON,
  MAIN_LOADING_OFF,
} from '../constants';
import _ from 'lodash';
import {
  clearSessionWithParams,
  getSessionJsonWithKey,
  setSessionWithValue,
} from '../store/cookies';

const INIT_STATE = {
  // authUser: (!_.isEmpty(localStorage.getItem('user'))) ? JSON.parse(localStorage.getItem('user')) : [],
  // authUser: _.isEmpty(localStorage.getItem('user')) ? [] : JSON.parse(localStorage.getItem('user')),
  authUser: _.isEmpty(getSessionJsonWithKey('user'))
    ? []
    : getSessionJsonWithKey('user'),
  activationAccountNumber: _.isEmpty(
    localStorage.getItem('activation_account_number'),
  )
    ? []
    : JSON.parse(localStorage.getItem('activation_account_number')),
  verificationAccountNumber: _.isEmpty(
    localStorage.getItem('verification_account_number'),
  )
    ? []
    : JSON.parse(localStorage.getItem('verification_account_number')),
  global_loading: false,
  profile_loading: false,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNOUT_USER_SUCCESS: {
      clearSessionWithParams('user');
      localStorage.removeItem('user');
      localStorage.removeItem('activation_account_number');
      return {
        ...state,
        authUser: [],
      };
    }
    case SIGNIN_USER_SUCCESS: {
      // localStorage.setItem('user', JSON.stringify(action.payload));
      setSessionWithValue(action.payload, 'user');
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case ACTIVATION_ACCOUNT_NO: {
      localStorage.setItem(
        'activation_account_number',
        JSON.stringify(action.payload),
      );
      return {
        ...state,
        loader: false,
        activationAccountNumber: action.payload,
      };
    }
    case VALIDATE_ACCOUNT_CODE: {
      localStorage.removeItem('activation_account_number');
      localStorage.setItem(
        'verification_account_number',
        JSON.stringify(action.payload),
      );
      return {
        ...state,
        loader: false,
        verificationAccountNumber: action.payload,
      };
    }
    case REGISTER_ACCOUNT: {
      localStorage.removeItem('verification_account_number');
      //localStorage.setItem('user', JSON.stringify(action.payload));
      setSessionWithValue(action.payload, 'user');
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case PROFILE_LOADING: {
      return {
        ...state,
        profile_loading: true,
      };
    }
    case PROFILE_DONE: {
      return {
        ...state,
        profile_loading: false,
      };
    }
    // case GLOBAL_FETCH_START: {
    //     return {...state, global_loading: true};
    // }
    default:
      return state;
  }
};
