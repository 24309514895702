import React, { useState, useEffect, useRef } from 'react';
import {
  Empty,
  Row,
  Col,
  Button,
  Table,
  InputNumber,
  Skeleton,
  Modal,
  Popconfirm,
  Select,
  notification,
  Card,
  Divider, Alert,
} from 'antd';
import {
  DeleteOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import _, { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';

// internal inputs
import {
  fetchCart,
  checkOutCart,
  editItemFromCart,
  removeItemFromCart,
  clearItemsFromCart,
} from 'appRedux/action/Cart';
import api, { encryptedPassword } from 'appRedux/api';

import './Cart.scss';
import { Loading } from '../../components';
import {
  redirectToCompleted,
  redirectToPayment,
  redirectToProfile,
} from 'route/Redirect';
import LinkComponent from '../../route/LinkComponent';
import { handleFieldError, handleNotify } from 'appRedux/api';
import { NOTIFICATION_DURATION } from 'appRedux/constants';
import { redirectToHome } from '../../route/Redirect';
import { navigate, redirectTo } from '@reach/router';
import { setSessionWithValue } from '../../appRedux/store/cookies';
import { Formik } from 'formik';
import { FormFeedback } from 'reactstrap';
import numberFormatter from 'number-formatter';
import { pointFormat } from '../../helpers';
import * as Yup from 'yup';
import { SendCheckOutOtp } from '../../appRedux/action/Cart';
import { handleNotifyOnly } from '../../appRedux/api';

const { Option } = Select;

const Cart = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);
  const [pointBalance, setPointBalance] = useState(0);
  const [open, setOpen] = useState(false);
  const [not, setNot] = useState(false);


  const [laddaLoading2, setLaddaLoading2] = useState(false);
  const [laddaLoading3, setLaddaLoading3] = useState(false);
  const [laddaProgress2, setLaddaProgress2] = useState(0);
  const [laddaProgress3, setLaddaProgress3] = useState(0);


  const [buyPointVisible, setBuyPointVisible] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [changeItemQtyProgress, setChangeItemQtyProgress] = useState(false);
  const [orderSuccessVisible, setOrderSuccessVisible] = useState(false);
  const [editItemParams, setEditItemParams] = useState({});
  /**
   *
   *   FETCH CART LOGIC here
   *
   */

  const dispatch = useDispatch();
  const allCartItems = useSelector(state => state.cart.cart.items);
  const receiptNumber = useSelector(state => state.cart.cart.receiptNumber);
  const { loading } = useSelector(state => state.cart);
  const totalCartPoint = useSelector(state => state.cart.cart.total);
  const { authUser, profile_loading } = useSelector(state => state.auth);

  const handleQuantityChange = (itemId, value) => {
    let _data = {
      units: value,
      itemId: itemId,
    };
    // old implementation
    // setEditItemParams(_data);
    setChangeItemQtyProgress(true);
    dispatch(
      editItemFromCart(_data, () => {
        setChangeItemQtyProgress(false);
      }),
    );
  };

  useEffect(() => {
    setPointBalance(authUser.points);
  }, [authUser]);

  useEffect(() => {
    dispatch(fetchCart());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   *
   *  TABLE COLUMN LOGIC STARTS here
   *
   */
  const columns = [
    {
      title: 'Item',
      dataIndex: 'decription',
      width: '50%',
      render: (text, record) => (
        <>
          <div className="item-container">
            <div className="item-img">
              {/* placeholder while image is loading */}
              {false ? (
                <span className="skeleton-container">
                  <Skeleton.Input
                    style={{ width: 140 }}
                    shape="square"
                    active
                    size="large"
                  />
                  <Skeleton.Input
                    style={{ width: 140 }}
                    shape="square"
                    active
                    size="large"
                  />
                </span>
              ) : (
                <img
                  className="order-image"
                  src={record.imageUrl}
                  alt="order image"
                />
              )}
            </div>
            <span className="item-details">
              <NumberFormat
                value={record.pricePerUnit}
                displayType={'text'}
                thousandSeparator={true}
                suffix={' pts'}
              />{' '}
              {record.decription}
            </span>
          </div>
        </>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'units',
      width: '20%',
      render: (text, record) => {
        return (
          <>
            <Select
              onChange={value => handleQuantityChange(record.id, value)}
              className="cart-select-input"
              defaultValue={record.units}>
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
              <Option value={4}>4</Option>
              <Option value={5}>5</Option>
              <Option value={6}>6</Option>
              <Option value={7}>7</Option>
              <Option value={8}>8</Option>
              <Option value={9}>9</Option>
              <Option value={10}>10</Option>
            </Select>
          </>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'pricePerUnit',
      width: '20%',
      render: (record, text) => (
        <>
          <span className="item-points">
            <NumberFormat
              value={text.pricePerUnit}
              displayType={'text'}
              thousandSeparator={true}
              suffix={' pts'}
            />
          </span>
        </>
      ),
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (record, item) => (
        <>
          {/* <span
            className="delete-icon"
            onClick={() => confirmDeleteCartItem(item)}>
            <DeleteOutlined />
          </span> */}
          <Popconfirm
            overlayClassName="rounded-pop"
            title={
              <span>
                Do you want to <strong>REMOVE</strong> item from Cart?
              </span>
            }
            onConfirm={() => confirmDeleteCartItemPop(item)}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
            cancelButtonProps={{
              style: {
                width: 75,
                height: 27,
                borderRadius: '4px',
              },
            }}
            okButtonProps={{
              style: {
                width: 75,
                height: 27,
                backgroundColor: '#e02020',
                border: 'none',
                borderRadius: '4px',
              },
            }}>
            <span className="delete-icon">
              <DeleteOutlined/>
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  /**
   *
   *  TABLE COLUMN LOGIC ENDS here
   *
   */

  /**
   *
   *  Confirm Clearing cart
   */
  function confirmClearCart() {
    Modal.confirm({
      width: 600,
      centered: true,
      className: 'sm-modal',
      title: (
        <h4 className="cart-clear-header">Do you want to clear your cart?</h4>
      ),
      icon: <ExclamationCircleOutlined/>,
      content: (
        <div className="my-3 p-3 cart-clear-text">
          You will delete all items in your carts{' '}
        </div>
      ),
      okText: 'Yes, Clear Cart',
      cancelText: 'No, Go back',
      cancelButtonProps: {
        size: 'large',
        border: '1.5px solid #49c33a',
      },
      okButtonProps: {
        size: 'large',

        style: {
          backgroundColor: '#e02020',
          marginLeft: '1.5em',
          border: 'none',
        },
      },
      onOk: () => {
        dispatch(clearItemsFromCart());
      },
    });
  }

  /**
   * confirmm modal end here
   */

  // ⚠️  Tooltip solution to Delete from cart
  function confirmDeleteCartItemPop(item) {
    setChangeItemQtyProgress(true);
    dispatch(
      removeItemFromCart(item.id, () => {
        setChangeItemQtyProgress(false);
      }),
    );
  }

  const BuyPointsModal = () => {
    return (
      <>
        <Modal
          className="sm-modal"
          visible={buyPointVisible}
          onCancel={() => setBuyPointVisible(false)}
          footer={null}>
          <div className="sm-modal-content">
            <CloseCircleOutlined className="icon error"/>
            <p className="title">Insufficient Points</p>
            <p className="text">
              Need <strong>{totalCartPoint - pointBalance}pts</strong> to
              complete this order or Pay
            </p>
            {/* <p className="price">₦15,000.00</p> */}

            {/* <Button
          className="fd-button primary"
          size="large"
          shape="round"
          onClick={() => setShowPayment(true)}>
          PAY WITH CARD
        </Button> */}
          </div>
        </Modal>
      </>
    );
  };

  const handleBuyPayment = () => {
    setBuyPointVisible(true);
  };

  function handleOrderCallbackSuccess() {
    setOrderSuccessVisible(true);
    setLaddaProgress(1);
    // redirectToProfile();

    // redirectTo('/order-completed');
    setSubmitting(false);
  }

  function handleOrderSuccess() {
    setSubmitting(true);
    setLaddaLoading(true);
    setLaddaProgress(0.5);

    // totalCartPoint > pointBalance ? <>Proceed To Payment</> : <>Place Order</>}
    if (totalCartPoint > pointBalance) {
      const d = {
        referenceId: receiptNumber,
        pointBalance: pointBalance,
        totalCartPoint: totalCartPoint,
        outstandingAmount: totalCartPoint - pointBalance,
        ...authUser,
      };
      // console.log(d);
      setSessionWithValue(d, '___order_purchase');
      redirectToPayment();
    } else {

      dispatch(
        SendCheckOutOtp(response =>
          _.isEmpty(response.error) && response.error !== true
            ? (() => {
              setNot(response.message);
              setOpen(true);
              setLaddaLoading(false);
              setLaddaProgress(0.9);
              setSubmitting(false);
            })()
            : handleNotifyOnly(response, () => {
              setLaddaLoading2(false);
              setSubmitting(false);
              setLaddaProgress2(0.9);
            }),
        ),
      );

      // dispatch(
      //   checkOutCart(totalCartPoint, pointBalance, response =>
      //     _.isEmpty(response.error) && response.error !== true
      //       ? handleOrderCallbackSuccess()
      //       : handleNotify(response, setSubmitting, () => {
      //           setLaddaLoading(false);
      //           setLaddaProgress(0.9);
      //         }),
      //   ),
      // );
    }
  }

  const validates = Yup.object().shape({
    activationCode: Yup.number()
    // .test(
    //     'len',
    //     'Activation code must be exactly 6 characters',
    //     val => val && val.toString().length === 6,
    // )
    // .positive('Activation code must be greater than zero')
      .typeError('Checkout code must be a number')
      .required('Checkout code is required'),
  });

  const handleCancelModel = () => {
    setOpen(false)
  };


  return (
    <>
      {/*<Modal*/}
      {/*  className="sm-modal"*/}
      {/*  visible={orderSuccessVisible}*/}
      {/*  onCancel={() => setOrderSuccessVisible(false)}*/}
      {/*  footer={null}>*/}
      {/*  <div className="sm-modal-content">*/}
      {/*    <CheckCircleOutlined className="icon secondary"/>*/}
      {/*    <p className="title">Order Successful</p>*/}
      {/*    <p className="text">*/}
      {/*      Your order has been placed, you will receive*/}
      {/*      notification via email*/}
      {/*    </p>*/}
      {/*    /!*<Button*!/*/}
      {/*    /!*  className="fd-button primary"*!/*/}
      {/*    /!*  size="large"*!/*/}
      {/*    /!*  shape="round">*!/*/}
      {/*    /!*  HOME*!/*/}
      {/*    /!*</Button>*!/*/}
      {/*  </div>*/}
      {/*</Modal>*/}

      <BrowserView>
        <main className="giftcard-view-section">
          <div className="carts-container">
            {loading ? (
              <Loading/>
            ) : _.isEmpty(allCartItems) ? (
              <>
                <Empty
                  imageStyle={{
                    height: 150,
                  }}
                  description={
                    <>
                      <span className="empty-card-header h4">
                        You have no items in your cart
                      </span>{' '}
                      <LinkComponent to={'/gift-cards'}>
                        <Button
                          className="fd-button primary"
                          type="link"
                          shape="round">
                          Shop Giftcards
                        </Button>
                      </LinkComponent>
                    </>
                  }
                />
              </>
            ) : (
              <>
                <Row justify="end">
                  <Col>
                    <div className="points-counter">
                      <span className="text">Point balance:</span>
                      <span className="numbers">
                        &nbsp;
                        <NumberFormat
                          value={pointBalance}
                          displayType={'text'}
                          thousandSeparator={true}
                          suffix={' pts'}
                          className="numbers"
                        />
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="cart-columns">
                  <Col className="cart-column-2" span={24}>
                    <Row className="content-wrapper">
                      <Col span={24}>
                        <div className="cart-title-container">
                          <h2 className="title">
                            Shopping Cart (
                            {allCartItems
                              ? `${allCartItems.length} item${
                                allCartItems.length > 1 ? 's' : ''
                              }`
                              : `0 items`}
                            )
                          </h2>
                          <Button
                            onClick={confirmClearCart}
                            className="clear-cart-btn">
                            clear cart
                          </Button>
                        </div>
                        <div className="cart-table-wrapper">
                          <Table
                            className="cart-table"
                            rowKey={record => record.id}
                            pagination={false}
                            //   showHeader={false}
                            loading={changeItemQtyProgress}
                            size="middle"
                            dataSource={allCartItems}
                            columns={columns}
                          />
                        </div>

                        <div className="summary-footer-wrapper">
                          <div className="summary-row">
                            <p className="text">Total:</p>
                            <p className="text total-points">
                              <NumberFormat
                                value={totalCartPoint}
                                displayType={'text'}
                                thousandSeparator={true}
                                suffix={' pts'}
                                decimalScale={2}
                                className="text total-points"
                              />
                            </p>
                          </div>
                          {totalCartPoint > pointBalance && (
                            <>
                              <div className="summary-row">
                                <p className="text">Points:</p>
                                <p
                                  className="text total-points secondary-color"
                                  style={{ marginLeft: '105px' }}>
                                  <NumberFormat
                                    value={pointBalance - totalCartPoint}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    suffix={' pts'}
                                    decimalScale={2}
                                  />
                                </p>
                              </div>
                              <div className="summary-row">
                                <p className="text">
                                  {`You need additional `}
                                  <span className="accent-red">
                                    <NumberFormat
                                      value={totalCartPoint - pointBalance}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      suffix={' pts'}
                                      decimalScale={2}
                                    />
                                  </span>
                                  {` to complete this order`}
                                </p>
                              </div>
                              {/* <div className="summary-row">
                                <Button
                                  className="btn-outline secondary"
                                  size={'large'}
                                  onClick={handleBuyPayment}>
                                  Buy Points
                                </Button>
                              </div> */}
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="action-button-wrapper" justify="end">
                      <Col>
                        <LinkComponent to={'/gift-cards'}>
                          <Button
                            className="fd-button grey"
                            type="link"
                            shape="round"
                            size={'large'}>
                            Continue Shopping
                          </Button>
                        </LinkComponent>
                        {/* <Button
                        className="fd-button primary"
                        type="link"
                        shape="round"
                        size={'large'}
                        loading={loading}
                        onClick={handleOrderSuccess}
                        disabled={totalCartPoint > pointBalance ? true : false}>
                        Place Order
                      </Button> */}
                        <LaddaButton
                          loading={laddaLoading}
                          progress={laddaProgress}
                          onClick={handleOrderSuccess}
                          data-style={SLIDE_UP}
                          data-spinner-size={30}
                          data-spinner-color="#fff"
                          data-spinner-lines={12}
                          className="ant-btn ant-btn-round fd-button primary"
                          type="submit"
                          disabled={
                            isSubmitting
                            // isSubmitting || totalCartPoint > pointBalance
                          }>
                          {/*Place Order*/}
                          {totalCartPoint > pointBalance ? (
                            <>Proceed To Payment</>
                          ) : (
                            <>Place Order</>
                          )}
                        </LaddaButton>
                      </Col>
                      <BuyPointsModal/>
                      {/* <PaymentModal /> */}
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </main>
      </BrowserView>
      <MobileView>
        <main className="giftcard-view-section">
          <div className="carts-container">
            {loading ? (
              <Loading/>
            ) : _.isEmpty(allCartItems) ? (
              <>
                <Empty
                  imageStyle={{
                    height: 100,
                  }}
                  description={
                    <>
                      <span className="empty-card-header h4">
                        You have no items in your cart
                      </span>
                      <LinkComponent to={'/gift-cards'}>
                        <Button
                          className="fd-button primary"
                          type="link"
                          shape="round">
                          Shop Giftcards
                        </Button>
                      </LinkComponent>
                    </>
                  }
                />
              </>
            ) : (
              <>
                <Row justify="center">
                  <Col>
                    <div className="points-counter">
                      <span className="text">Point balance:</span>
                      <span className="numbers">
                        &nbsp;
                        <NumberFormat
                          value={pointBalance}
                          displayType={'text'}
                          thousandSeparator={true}
                          suffix={' pts'}
                          className="numbers"
                        />
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4" justify="space-between">
                  <Col>
                    <h3 className="mobile-title">
                      Shopping Cart (
                      {allCartItems
                        ? `${allCartItems.length} item${
                          allCartItems.length > 1 ? 's' : ''
                        }`
                        : `0 items`}
                      )
                    </h3>
                  </Col>
                  <Col>
                    <Button
                      onClick={confirmClearCart}
                      className="mobile-clear-cart-btn">
                      clear cart
                    </Button>
                  </Col>
                </Row>

                <Row justify="center" gutter={[0, 8]}>
                  {allCartItems.map((record, index) => (
                    <Col key={index} span={24}>
                      <Card className="mobile-cart-card">
                        <Row type="flex" justify="space-between">
                          <Col span={8}>
                            <div className="item-img">
                              <img
                                className="mobile-cart-image"
                                src={record.imageUrl}
                                alt="order image"
                              />
                            </div>
                          </Col>
                          <Col span={16}>
                            <h5 className="mobile-item-details">
                              <NumberFormat
                                value={record.pricePerUnit}
                                displayType={'text'}
                                thousandSeparator={true}
                                suffix={' pts'}
                              />{' '}
                              {record.decription}
                            </h5>
                            <h5 className="mobile-item-price">{`${record.pricePerUnit} pts`}</h5>
                          </Col>
                        </Row>
                        <Divider></Divider>
                        <div className="mobile-footer-wrapper">
                          <div>
                            Quantity
                            <InputNumber
                              className="mobile-cart-input"
                              min={1}
                              max={10}
                              defaultValue={record.units}
                              onChange={value =>
                                handleQuantityChange(record.id, value)
                              }
                            />
                          </div>
                          <Popconfirm
                            overlayClassName="rounded-pop"
                            title={
                              <span>
                                Do you want to <strong>REMOVE</strong> item from
                                Cart?
                              </span>
                            }
                            onConfirm={() => confirmDeleteCartItemPop(record)}
                            placement="topRight"
                            okText="Yes"
                            cancelText="No"
                            cancelButtonProps={{
                              style: {
                                width: 70,
                                height: 24,
                                borderRadius: '4px',
                              },
                            }}
                            okButtonProps={{
                              style: {
                                width: 70,
                                height: 24,
                                backgroundColor: '#e02020',
                                border: 'none',
                                borderRadius: '4px',
                              },
                            }}>
                            <div className="mobile-delete">
                              Delete
                              <DeleteOutlined className="delete-icon"/>
                            </div>
                          </Popconfirm>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <div className="mobile-summary-footer-wrapper">
                  <div className="summary-row">
                    <p className="text">Total:</p>
                    <p className="text total-points">
                      {
                        <NumberFormat
                          value={totalCartPoint}
                          displayType={'text'}
                          thousandSeparator={true}
                          suffix={' pts'}
                          className="text total-points"
                        />
                      }
                    </p>
                  </div>
                  {totalCartPoint > pointBalance && (
                    <>
                      <div className="summary-row">
                        <p className="text">Points:</p>
                        <p className="text total-points secondary-color">
                          <NumberFormat
                            value={pointBalance - totalCartPoint}
                            displayType={'text'}
                            thousandSeparator={true}
                            suffix={' pts'}
                            decimalScale={2}
                          />
                        </p>
                      </div>
                      <div className="summary-row">
                        <p className="text">
                          {`You  need additional `}
                          <span className="accent-red">
                            <NumberFormat
                              value={totalCartPoint - pointBalance}
                              displayType={'text'}
                              thousandSeparator={true}
                              suffix={' pts'}
                              decimalScale={2}
                            />
                          </span>
                          {` to complete this order`}
                        </p>
                      </div>
                      {/* <div className="summary-row">
                        <Button
                          className="btn-outline secondary"
                          size={'large'}
                          onClick={handleBuyPayment}>
                          Buy Points
                        </Button>
                      </div> */}
                    </>
                  )}
                </div>
                <Row
                  gutter={[16, 0]}
                  className="action-button-wrapper"
                  justify="end">
                  <Col>
                    <LaddaButton
                      loading={laddaLoading}
                      progress={laddaProgress}
                      onClick={handleOrderSuccess}
                      data-style={SLIDE_UP}
                      data-spinner-size={30}
                      data-spinner-color="#fff"
                      data-spinner-lines={12}
                      className="ant-btn ant-btn-round fd-button primary extra-btn-width"
                      type="submit"
                      disabled={
                        isSubmitting
                        //    isSubmitting || totalCartPoint > pointBalance
                      }>
                      {totalCartPoint > pointBalance ? (
                        <>Proceed To Payment</>
                      ) : (
                        <>Place Order</>
                      )}
                    </LaddaButton>
                  </Col>
                  <Col>
                    <LinkComponent to={'/gift-cards'}>
                      <Button
                        className="fd-button grey extra-btn-width"
                        type="link"
                        shape="round"
                        size={'large'}>
                        Continue Shopping
                      </Button>
                    </LinkComponent>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </main>
      </MobileView>


      <Modal
        className="sm-modal"
        title="Checkout OTP"
        visible={open}
        // maskClosable={false}
        onCancel={handleCancelModel}
        footer={null}>
        <div className="sm-modal-content" style={{ padding: '4px', textAlign: 'left' }}>
          <div>
            {not && (
              <>
                <Alert message={not} type="info" /> <br />{' '}
              </>
            )}
            <Formik
              initialValues={{
                activationCode: '',
              }}
              validationSchema={validates}
              onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                setLaddaLoading3(true);
                setLaddaProgress3(0.5);

                // values.referenceId = receiptNumber;
                // values.verificationCode = encryptedPassword(
                //   values.verificationCode,
                // );
                console.log(values);

                dispatch(
                  checkOutCart(totalCartPoint, pointBalance, values.activationCode, response =>
                    _.isEmpty(response.error) && response.error !== true
                      ? handleOrderCallbackSuccess()
                      : handleNotify(response, setSubmitting, () => {
                        setLaddaProgress3(1.0);
                        setLaddaLoading3(false);
                        resetForm();
                      }),
                  ),
                );

                // props.checkOutCartVerifyPayment(values, response =>
                //   _.isEmpty(response.error) && response.error !== true
                //     ? (() => {
                //       // setStep(1);
                //       // notification['success']({
                //       //   message: 'Transaction Completed',
                //       //   description: 'Bank Account Payment Successful',
                //       //   duration: NOTIFICATION_DURATION,
                //       // });
                //       // setOpen(false);
                //       // setLaddaLoading3(false);
                //       // setLaddaProgress3(1.0);
                //       // setSubmitting(false);
                //       // moveOut('success', response);
                //     })()
                //     : (() => {
                //       // moveOut('error', response);
                //       // handleNotify(response, setSubmitting, () => {
                //       setLaddaProgress3(1.0);
                //       setLaddaLoading3(false);
                //       resetForm();
                //       handleFieldError(response.validationErrors, setErrors);
                //       // });
                //     })(),
                // );

              }}>
              {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                <form onSubmit={handleSubmit} className={'login_form '}>
                  <div className={'form_holder form-group'}>
                    <div className="form-label-group-holder">
                      <div className="form-label-group">
                        {/*<label> Verification Code</label>*/}
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter checkout OTP Code"
                          name="activationCode"
                          id="inputAccount"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.activationCode}
                        />

                        {errors.activationCode &&
                        touched.activationCode && (
                          <FormFeedback>
                            {errors.activationCode}
                          </FormFeedback>
                        )}
                      </div>
                    </div>
                  </div>

                  <LaddaButton
                    loading={laddaLoading3}
                    progress={laddaProgress3}
                    data-style={SLIDE_UP}
                    data-spinner-size={30}
                    data-spinner-color="#fff"
                    data-spinner-lines={12}
                    className="btn-submit btn btn-primary btn-style"
                    type="submit"
                    disabled={isSubmitting}>
                    Place Order
                  </LaddaButton>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default Cart;
