import React, { useState, useEffect } from 'react';
import LinkComponent from '../../route/LinkComponent';
import { Formik } from 'formik';
import { FormFeedback, FormGroup } from 'reactstrap';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { Row, Col, Input, Button, Alert, notification } from 'antd';
import { Link } from '@reach/router';
import _ from 'lodash';

import createPwdImg from '../../images/create-password.jpg';
import * as Yup from 'yup';
import { NOTIFICATION_DURATION } from '../../appRedux/constants';
import { handleRegister } from '../../appRedux/action/Auth';
import { connect } from 'react-redux';
import {
  redirectToActivation,
  redirectToProfile,
  redirectToLogin,
} from '../../route/Redirect';
import { encryptedPassword, handleNotify } from '../../appRedux/api';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

function CreatePassword(props) {
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);
  const [verification, setVerification] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(false);
  const [notificationType, setNotificationType] = useState('error');

  useEffect(() => {
    const { verificationAccountNumber } = props;
    if (_.isEmpty(props.verificationAccountNumber)) {
      notification['error']({
        message: 'Invalid account!',
        description: 'Please request for activation',
        duration: NOTIFICATION_DURATION,
      });
      redirectToActivation();
    }

    setVerification(verificationAccountNumber);
  }, []);

  const handleIn = () => {
    notification['success']({
      message: 'Account created!',
      description: 'Account created successfully',
      duration: NOTIFICATION_DURATION,
    });
    setLaddaProgress(0.9);
    redirectToProfile();
    // redirectToLogin();
  };

  const onClose = e => {};

  const passwordSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    // .matches(
    //   /^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    // ),
    confirm_password: Yup.string()
      .required('Confirm Password is required')
      .when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Both password need to be the same',
        ),
      }),
  });

  return (
    <>
      <>
        <div className="hero-box">
          <Row className={'hero-box-row'}>
            <Col
              sm={{ span: 24 }}
              md={{ span: 12 }}
              className={'padding_inner'}>
              <div>
                <>
                  <div className="top-form">
                    <div>
                      <h2 className="card_inner_title"> Create Password </h2>
                      <p className="card_inner_sub_title">
                        {' '}
                        Create a password with minimum of 8 characters{' '}
                      </p>
                    </div>
                    <div>
                      <Formik
                        initialValues={{ password: '', confirm_password: '' }}
                        validationSchema={passwordSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          setLaddaLoading(true);
                          setLaddaProgress(0.4);

                          props.handleRegister(
                            verification,
                            encryptedPassword(values.password),
                            response =>
                              _.isEmpty(response.error) &&
                              response.error !== true
                                ? handleIn()
                                : handleNotify(response, setSubmitting, () => {
                                    setLaddaProgress(1.0);
                                    setLaddaLoading(false);
                                  }),
                          );
                        }}>
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            className={'login_form '}>
                            <div
                              className={'form_holder float_label form-group'}>
                              <div className="form-label-group-holder">
                                <div className="form-label-group">
                                  <div className={'antinput-password'}>
                                    <Input.Password
                                      className="form-control"
                                      type="password"
                                      name="password"
                                      id="inputPassword"
                                      placeholder="New Password"
                                      iconRender={visible =>
                                        visible ? (
                                          <EyeTwoTone />
                                        ) : (
                                          <EyeInvisibleOutlined />
                                        )
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                    />

                                    {/*iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}*/}

                                    <label htmlFor="inputPassword">
                                      {' '}
                                      New Password{' '}
                                    </label>
                                  </div>
                                  {errors.password && touched.password && (
                                    <FormFeedback>
                                      {errors.password}
                                    </FormFeedback>
                                  )}
                                </div>
                              </div>
                              <div className="form-label-group-holder">
                                <div className="form-label-group">
                                  <div className={'antinput-password'}>
                                    <Input.Password
                                      className="form-control"
                                      type="password"
                                      name="confirm_password"
                                      id="inputConfirmPassword"
                                      placeholder="Confirm Password"
                                      iconRender={visible =>
                                        visible ? (
                                          <EyeTwoTone />
                                        ) : (
                                          <EyeInvisibleOutlined />
                                        )
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.confirm_password}
                                    />
                                    <label htmlFor="inputConfirmPassword">
                                      {' '}
                                      Confirm Password{' '}
                                    </label>
                                  </div>
                                  {errors.confirm_password &&
                                    touched.confirm_password && (
                                      <FormFeedback>
                                        {errors.confirm_password}
                                      </FormFeedback>
                                    )}
                                </div>
                              </div>
                            </div>

                            <LaddaButton
                              loading={laddaLoading}
                              progress={laddaProgress}
                              data-style={SLIDE_UP}
                              data-spinner-size={30}
                              data-spinner-color="#fff"
                              data-spinner-lines={12}
                              className="btn-submit btn btn-primary btn-style"
                              type="submit"
                              disabled={isSubmitting}>
                              Continue
                            </LaddaButton>
                          </form>
                        )}
                      </Formik>
                    </div>

                    <div className={'text-center'}>
                      <div className="dot" />
                      <div className="text-center" style={{ marginTop: 28 }}>
                        <p>
                          {' '}
                          Have an account?{' '}
                          <LinkComponent
                            to={'/login'}
                            className="forget_password_link">
                            {' '}
                            Login{' '}
                          </LinkComponent>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Col>
            <Col
              sm={{ span: 0 }}
              md={{ span: 12 }}
              className={'pass_side_image access_img'}
            />
          </Row>
        </div>
      </>
    </>
  );
}

export default connect(
  ({ auth }) => {
    const { verificationAccountNumber } = auth;
    return { verificationAccountNumber };
  },
  { handleRegister },
)(CreatePassword);
