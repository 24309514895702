import {
  FETCH_GIFT_CARDS_FAILED,
  FETCH_GIFT_CARDS_START,
  FETCH_GIFT_CARDS_SUCCESS,
  SEND_FRIEND_GCARD_FAILED,
  SEND_FRIEND_GCARD_START,
  SEND_FRIEND_GCARD_SUCCESS,
  FETCH_GIFT_CARDS_CATEGORIES_START,
  FETCH_GIFT_CARDS_CATEGORIES_SUCCESS,
  FETCH_GIFT_CARDS_CATEGORIES_FAILED,
  FETCH_SINGLE_GIFT_CARDS_FAILED,
  FETCH_SINGLE_GIFT_CARDS_SUCCESS,
  FETCH_SINGLE_GIFT_CARDS_START,
  ADD_GCARD_TO_WLIST_START,
  ADD_GCARD_TO_WLIST_SUCCESS,
  ADD_GCARD_TO_WLIST_FAILED,
  FETCH_GIFT_CARDS_FEATURE_SUCCESS,
  FETCH_GIFT_CARDS_FEATURE_START,
  FETCH_GIFT_CARDS_FEATURE_FAILED,
  FETCH_GIFT_CARDS_LOCATIONS_START,
  FETCH_GIFT_CARDS_LOCATIONS_SUCCESS,
  FETCH_GIFT_CARDS_LOCATIONS_FAILED,
  CART_CHECKOUT_FAILED,
  CART_PAYMENT_INFO_START,
} from '../constants';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const INIT_STATE = {
  giftCards: [],
  featureGiftCards: [],
  giftCard: [],
  giftCardsCategories: [],
  giftCardsLocations: [],
  cart: [],
  loading: false,
  featureloading: false,
  loadingGiftCard: false,
  loadingCategories: false,
  loadingLocations: false,
};

const GiftCardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_GCARD_TO_WLIST_START:
    case SEND_FRIEND_GCARD_START:
    case FETCH_GIFT_CARDS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_GIFT_CARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        giftCards: action.payload,
      };
    }
    case FETCH_GIFT_CARDS_FEATURE_START: {
      return {
        ...state,
        featureloading: true,
      };
    }
    case FETCH_GIFT_CARDS_FEATURE_SUCCESS: {
      return {
        ...state,
        featureloading: false,
        featureGiftCards: action.payload,
      };
    }
    case FETCH_GIFT_CARDS_FEATURE_FAILED: {
      return {
        ...state,
        featureloading: false,
      };
    }
    case FETCH_SINGLE_GIFT_CARDS_START: {
      return {
        ...state,
        loadingGiftCard: true,
      };
    }
    case FETCH_SINGLE_GIFT_CARDS_SUCCESS: {
      return {
        ...state,
        loadingGiftCard: false,
        giftCard: action.payload,
      };
    }
    case FETCH_SINGLE_GIFT_CARDS_FAILED: {
      return {
        ...state,
        loadingGiftCard: false,
      };
    }

    case ADD_GCARD_TO_WLIST_SUCCESS:
    case SEND_FRIEND_GCARD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_GCARD_TO_WLIST_FAILED:
    case SEND_FRIEND_GCARD_FAILED:
    case FETCH_GIFT_CARDS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_GIFT_CARDS_CATEGORIES_START: {
      return {
        ...state,
        loadingCategories: true,
      };
    }
    case FETCH_GIFT_CARDS_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loadingCategories: false,
        giftCardsCategories: action.payload,
      };
    }
    case FETCH_GIFT_CARDS_CATEGORIES_FAILED: {
      return {
        ...state,
        loadingCategories: false,
      };
    }
    case FETCH_GIFT_CARDS_LOCATIONS_START: {
      return {
        ...state,
        loadingLocations: true,
      };
    }
    case FETCH_GIFT_CARDS_LOCATIONS_SUCCESS: {
      return {
        ...state,
        loadingLocations: false,
        giftCardsLocations: action.payload,
      };
    }
    case FETCH_GIFT_CARDS_LOCATIONS_FAILED: {
      return {
        ...state,
        loadingLocations: false,
      };
    }
    // case ADD_GIFT_TO_CART: {
    //   return {
    //     ...state,
    //   cart: [...state.cart, action.payload]
    //   }
    // }
    default:
      return state;
  }
};

const persistConfig = {
  key: 'giftCards',
  storage,
  whitelist: ['giftCards'],
};

export default persistReducer(persistConfig, GiftCardReducer);
