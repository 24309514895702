import React, { useEffect } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Result } from 'antd';
import LinkComponent from '../../route/LinkComponent';
import _ from 'lodash';
import {
  clearSessionWithParams,
  getSessionJsonWithKey,
} from '../../appRedux/store/cookies';
import { redirectToHome } from '../../route/Redirect';
import { PAYMENT_FAILED, PAYMENT_SUCCESSFUL } from '../../appRedux/constants';
import { handleError } from '../../appRedux/action/Common';

function OrderCompleted(props) {
  useEffect(() => {
    const order = getSessionJsonWithKey('___order_completed');
    if (_.isEmpty(order)) {
      redirectToHome();
      return;
    }

    if (!_.isEmpty(order)) {
      clearSessionWithParams('___order_completed');
    }
  }, []);

  return (
    <>
      <div className={'container dpp'}>
        <div className="text-center">
          <Result
            status="success"
            title="Order Successful"
            subTitle="Your order has been placed successfully"
            extra={
              <LinkComponent to={'/'}>
                <Button className="fd-button primary" type="link" shape="round">
                  Back to Homepage
                </Button>
              </LinkComponent>
            }
          />
        </div>
      </div>
    </>
  );
}

export default OrderCompleted;
