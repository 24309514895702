import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Table, Statistic } from 'antd';

import {
  InnerHeader,
  Footer,
  AccountsVerticalMenu,
  Loading,
  PointBalance,
} from 'components';
import { connect } from 'react-redux';
import { getAccountSummary } from '../../appRedux/action/AccountSummary';
import './Summary.scss';
import _ from 'lodash';

const dataSource = [
  {
    key: '1',
    name: 'Bonus Points',
    points: '15,000',
  },
  {
    key: '2',
    name: 'Spend Points',
    points: 40002,
  },
  {
    key: '3',
    name: 'Purchased Points',
    points: 42000,
  },
];

const columns = [
  {
    dataIndex: 'name',
    key: 'name',
    width: '25%',
  },
  {
    dataIndex: 'points',
    key: 'points',
    width: '75%',
    render: (record, text) => (
      <>
        <span className="earning-points">{`${text.points} pts`}</span>
      </>
    ),
  },
];

const sideMenu = [
  {
    title: 'Summary',
    isActive: true,
    url: '/summary',
  },
  {
    title: 'Point History',
    isActive: false,
    url: '/points-history',
  },
  {
    title: ' Order History',
    isActive: false,
    url: '/order-history',
  },
  {
    title: 'Profile',
    isActive: false,
    url: '/profile',
  },
  {
    title: 'Wishlist',
    isActive: false,
    url: '/wishlist',
  },
  {
    title: 'Logout',
    isActive: false,
    url: '/logout',
  },
];

const Summary = props => {
  const { points, fullName, accountNumber } = props.authUser;

  const [menu, setMenu] = useState(false);
  let { accountSummary, loading } = props.accountSummary;

  useEffect(() => {
    props.getAccountSummary();
  }, []);

  return (
    <>
      <main className="giftcard-view-section">
        <div className="accounts-container">
          <Button
            onClick={() => setMenu(true)}
            color="secondary"
            className="tstyle"
            size={'large'}
            shape="round">
            {' '}
            Account Side Menu{' '}
          </Button>
          <Row justify="end">
            <Col>
              <PointBalance />
            </Col>
          </Row>

          <Row className="account-columns" gutter={64}>
            <Col
              className={`account-column-1 ${menu &&
                'mobile_menu_profile_holder'}`}
              span={6}>
              <AccountsVerticalMenu
                menuList={sideMenu}
                setMenuOff={() => setMenu(false)}
              />
            </Col>
            <Col
              className="account-column-2"
              sm={{ span: 24 }}
              md={{ span: 18 }}>
              {loading && _.isEmpty(accountSummary) ? (
                <Loading />
              ) : (
                <Row className="content-wrapper">
                  <Col span={24}>
                    <div className="profile-name-container">
                      <Row style={{ width: '100%' }} gutter={16}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                          <div className="profile-name-wrapper">
                            <h3 className="desc">Name:</h3>
                            <h2 className="title">{`${fullName}`}</h2>
                          </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                          <div className="profile-name-wrapper">
                            <h3 className="desc">Account Number:</h3>
                            <h2 className="title">{accountNumber}</h2>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="stats-wrapper">
                      <Row gutter={16} style={{ width: '100%' }}>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                          <Card className="stats-card">
                            <Statistic
                              title={
                                <h4 className="stats-title">Total Points:</h4>
                              }
                              value={accountSummary.totalPoints}
                              valueStyle={{ color: '#9B9B9B' }}
                              suffix="pts"
                            />
                          </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                          <Card className="stats-card">
                            <Statistic
                              title={
                                <h4 className="stats-title">
                                  Redeemed Points:
                                </h4>
                              }
                              value={accountSummary.redeemedPoints}
                              valueStyle={{ color: '#9B9B9B' }}
                              suffix="pts"
                            />
                          </Card>{' '}
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                          <Card className="stats-card">
                            <Statistic
                              title={
                                <h4 className="stats-title">Point balance:</h4>
                              }
                              value={accountSummary.pointsBalance}
                              valueStyle={{ color: '#FFB200' }}
                              suffix="pts"
                            />
                          </Card>
                        </Col>
                      </Row>
                    </div>
                    {/*
                                            <div className="earnings-title">
                                              <h4 className="earnings">Earnings:</h4>
                                            </div>
                                            <div className="earnings-table-wrapper">
                                              <Table
                                                className="earnings-table"
                                                rowKey={record => record.points}
                                                pagination={false}
                                                showHeader={false}
                                                size="middle"
                                                dataSource={dataSource}
                                                columns={columns}
                                              />
                                            </div>
                                        */}

                    {/*<div className="summary-footer-wrapper">*/}
                    {/*    <p className="text">Have another Account?</p>*/}
                    {/*    <Button*/}
                    {/*        className="fd-button primary"*/}
                    {/*        type="link"*/}
                    {/*        shape="round"*/}
                    {/*        size={'large'}>*/}
                    {/*        Link another Account*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = ({ auth, accountSummary }) => ({
  authUser: auth.authUser,
  accountSummary,
});

const mapDispatchToProps = {
  getAccountSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
