import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Alert,
  Divider,
  Descriptions,
  notification,
  Spin,
  Empty,
  Button,
  Modal,
} from 'antd';
import CryptoJS from 'crypto-js';
import { Loading } from 'components';

import { getSessionWithKey, setSessionWithValue } from 'appRedux/store/cookies';
import numberFormatter from 'number-formatter';
import { pointFormat } from '../../helpers';
import { NOTIFICATION_DURATION } from '../../appRedux/constants';
import _ from 'lodash';
import {CreditCardOutlined, BankOutlined} from "@ant-design/icons"
import { redirectToHome, redirectToProfile } from '../../route/Redirect';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import {
  checkOutCart,
  checkOutCartAccount,
  checkOutCartFromAccount,
  checkOutCartFromPayment,
  checkOutCartVerifyPayment,
  getPaymentInfo,
} from '../../appRedux/action/Cart';
import {
  encryptedPassword,
  handleFieldError,
  handleNotify,
  handleNotifyOnly,
} from '../../appRedux/api';
import { connect, useDispatch, useSelector } from 'react-redux';
import LinkComponent from '../../route/LinkComponent';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';
import { navigate, Redirect, redirectTo } from '@reach/router';
import { BrowserView } from 'react-device-detect';

//method to generate random 16bit characters
function generatePrivateKey() {
  let result = '';
  let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (let i = 16; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

const Payment = props => {
  const [not, setNot] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [refOrderId, setRefOrderId] = useState('');
  const [refAmt, setRefAmt] = useState({});
  const [refMercId, setRefMercId] = useState('');
  const [error, setError] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  // const [parseOrderDetails, setParseOrderDetails] = useState({});

  const [hidden, setHidden] = useState(true);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [laddaLoading, setLaddaLoading] = useState(false);
  const [laddaLoading2, setLaddaLoading2] = useState(false);
  const [laddaLoading3, setLaddaLoading3] = useState(false);
  const [laddaLoading4, setLaddaLoading4] = useState(false);
  const [laddaProgress, setLaddaProgress] = useState(0);
  const [laddaProgress2, setLaddaProgress2] = useState(0);
  const [laddaProgress3, setLaddaProgress3] = useState(0);
  const [laddaProgress4, setLaddaProgress4] = useState(0);

  const dispatch = useDispatch();

  // return;
  // let parseOrderDetails = "";

  const [tempHash, setTempHash] = useState('');
  const [tempEncrypt, setTempEncrypt] = useState('');

  // const refMercId = useRef('05947');
  const refCurrCode = useRef('566');
  const payWithCard = useRef();

  /**
   email: "oqogg@yahoo.muf"
   orderStatusId: 1
   outstandingAmount: 892.09
   productName: "Order purchase"
   referenceId: "246edc43-d8ab-4eac-b00a-08d87be7f2aa"

   // "orderStatusId": 1,
   // "outstandingAmount": 100.00,
   // "email": "ujxya@yahoo.xms",
   // "productName": "Order purchase",
   // "referenceId": "0cee5d2f-9ac7-436e-500a-08d89045ddb4",
   "requestUrl": "http://paygatetest.fidelitybank.ng/CIPGForCard/MerchantServices/MakePayment.aspx",
   "merchantId": "07405",
   "publicKey": "s4G4UAKAPv1X2vEK"
   */

  const merchantId = sessionStorage.getItem('MerchantId');
  const requestUrl = sessionStorage.getItem('RequestUrl');
  const publicKey = sessionStorage.getItem('PublicKey');

  useEffect(() => {
    props.getPaymentInfo(response =>
      _.isEmpty(response.error) && response.error !== true
        ? (() => {
            handleEncrypt(response);
            window.scrollTo(0, 0);
          })()
        : (() => {
            handleError();
          })(),
    );
  }, []);

  // amountPayable: 2000
  // outstandingAmount: 1432
  // receiptNumber: "ef2dd253-7585-4273-a2fe-bd4e7c53dc53"
  // walletBalance: 568.57

  const handleError = () => {
    setError(true);
  };

  const {
    amountPayable,
    outstandingAmount,
    receiptNumber,
    walletBalance,
  } = props.payment_info;
  // const parseOrderDetails = JSON.parse(getSessionWithKey('___order_purchase'));
  // if (_.isEmpty(parseOrderDetails) || typeof parseOrderDetails === 'undefined') {
  //   handleError();
  // }

  // setRefOrderId(.receiptNumber);
  // setRefMercId(merchantId);

  // set the value of all the refs used
  // refAmt_current = parseOrderDetail.outstandingAmount;
  // refCurrCode_current = 566;
  // refMercId_current = '05947';
  // refOrderId_current = parseOrderDetail.referenceId;

  // setRefAmt({
  //     refAmt_current: parseOrderDetail.outstandingAmount,
  //     refCurrCode_current: '566',
  //     refMercId_current: parseOrderDetail.merchantId,
  //     refOrderId_current: parseOrderDetail.referenceId,
  // });

  const handleEncrypt = ({ outstandingAmount, receiptNumber }) => {
    let _hash = encrypt(
      // parseOrderDetail.publicKey,
      // parseOrderDetail.merchantId,
      publicKey,
      merchantId,
      outstandingAmount,
      receiptNumber,
    );

    setTempHash(_hash.hash);
    setTempEncrypt(_hash.encryptedString);
  };

  function encrypt(publicKey, refMercId, refAmt, refOrderId) {
    let encryptedString;
    let privateKey = generatePrivateKey();
    // let publicKey = 'NbtLM1vHiOMVwQXm';
    let Securekey = CryptoJS.enc.Utf8.parse(publicKey);
    let iv = CryptoJS.enc.Utf8.parse(publicKey);
    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(privateKey),
      Securekey,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
      },
    );
    encryptedString = encrypted.toString();

    // let amt = refAmt.current;
    // let mercId = refMercId.current;
    // let orderId = refOrderId.current;
    // let currCode = refCurrCode.current;
    let amt = refAmt;
    let mercId = refMercId;
    let orderId = refOrderId;
    let currCode = '566';
    let hash = CryptoJS.SHA256(mercId + orderId + amt + currCode + privateKey);

    // console.log(
    //   'publicKey',
    //   publicKey,
    //   refMercId,
    //   refAmt,
    //   refOrderId,
    //   hash.hash,
    //   encryptedString,
    // );

    return { hash, encryptedString };
  }

  // console.log(_.isEmpty(orderDetails));
  // console.log('orderDetails', orderDetails, !_.isEmpty(orderDetails));
  // console.log('parseOrderDetails', parseOrderDetail);

  // setLoading(true);

  // const totalCartPoint = parseOrderDetails.totalCartPoint;
  // const pointBalance = parseOrderDetails.pointBalance;

  const triggerButtonClickAccount = () => {
    setLaddaLoading4(true);
    setLaddaProgress4(0.5);

    props.checkOutCartFromAccount(response =>
      _.isEmpty(response.error) && response.error !== true
        ? (() => {
            setOrderDetails(response);
            setLaddaProgress4(0.8);
            // setAccountLoading(true);
            setOpen(true);
          })()
        : (() => {
            // console.log(response);
            notification['error']({
              message: 'Error checking out!',
              description: 'message',
              duration: NOTIFICATION_DURATION,
            });
          })(),
    );
  };

  const triggerButtonClick = () => {
    setLaddaLoading2(true);
    setLaddaProgress2(0.5);

    props.checkOutCartFromPayment(response =>
      _.isEmpty(response.error) && response.error !== true
        ? (() => {
            // setHidden(false);
            // console.log(response);
            payWithCard.current.click();
          })()
        : (() => {
            // console.log(response);
            notification['error']({
              message: 'Error checking out!',
              description: 'message',
              duration: NOTIFICATION_DURATION,
            });
          })(),
    );
  };

  const showError = () => {
    return (
      <>
        <Col className="cart-column-2" span={24}>
          <div className="container">
            <main className="giftcard-view-section">
              <div className="container">
                <Empty
                  imageStyle={{
                    height: 150,
                  }}
                  description={
                    <>
                      <div>
                        <span className="empty-card-header h4">
                          {' '}
                          You have no payment order{' '}
                        </span>{' '}
                        <LinkComponent to={'/gift-cards'}>
                          <Button
                            className="fd-button primary"
                            type="link"
                            shape="round">
                            Shop Giftcards
                          </Button>
                        </LinkComponent>
                      </div>
                    </>
                  }
                />
              </div>
            </main>
          </div>
        </Col>
      </>
    );
  };

  // const submitPayToCard = () => {
  //   return <LaddaButton
  //     loading={laddaLoading}
  //     progress={laddaProgress}
  //     data-style={SLIDE_UP}
  //     data-spinner-size={30}
  //     data-spinner-color="#fff"
  //     data-spinner-lines={12}
  //     className={`fd-button primary btn-round`}
  //     name="submit"
  //     type="submit">
  //     PAY WITH CARD
  //   </LaddaButton>;
  // };

  const validate = Yup.object({
    accountNumber: Yup.string()
      .length(
        10,
        'Account Number must be exactly 10 digits or Enter a valid Account Number',
      )
      .matches(/[0-9]+/gi, 'Account number must be a number')
      //.positive('Activation code must be greater than zero')
      .required('Account number is required'),
  });

  const validates = Yup.object().shape({
    verificationCode: Yup.number()
      // .test(
      //     'len',
      //     'Activation code must be exactly 6 characters',
      //     val => val && val.toString().length === 6,
      // )
      // .positive('Activation code must be greater than zero')
      .typeError('Activation code must be a number')
      .required('Activation code is required'),
  });

  const moveOut = (type, response) => {
    // navigate(`/verify-payment?TransactionReference=${receiptNumber}&OrderID=${receiptNumber}`);
    // console.log('---', {...response, type})
    setSessionWithValue({...response, type}, '___order_account_purchase');
    navigate(`/complete-payment`);
  };

  const handleCancelModel = () => {
    navigate(`/failed-payment`);
    // setOpen(false)
  };

  const accountNumberModal = () => {
    return (
      <>
        <Modal
          className="sm-modal"
          title="Account Payment"
          visible={open}
          maskClosable={false}
          onCancel={handleCancelModel}
          footer={null}>
          <div
            className="sm-modal-content"
            style={{ padding: '4px', textAlign: 'left' }}>
            {/*<CloseCircleOutlined className="icon error"/>*/}

            {/*<div>*/}
            {/*  <h3 className="blue">Account Payment</h3>*/}
            {/*  <br />*/}
            {/*</div>*/}

            <div className={`${step === 1 ? 'show' : 'hidden'}`}>
              {accountLoading ? (
                <div>
                  {' '}
                  <Spin />{' '}
                </div>
              ) : (
                <div>
                  {/*<p>Enter your account number </p>*/}
                  <Row>
                    {/*amountPayable: 1010*/}
                    {/*fee: 10*/}
                    {/*onePipeData: {referenceId: "cbdbc053-143d-4570-955c-a194b839caff"}*/}
                    {/*orderStatus: "Checkedout"*/}
                    {/*orderStatusId: 1*/}
                    {/*outstandingAmount: 1000*/}
                    {/*{console.log('----orderDetails', orderDetails)}*/}
                    <Col span={12}>
                      <h3 className={'det'}>
                        {' '}
                        <small>Outstanding Amount</small> <br />{' '}
                        NGN {numberFormatter(
                          pointFormat,
                          orderDetails.outstandingAmount,
                        )}{' '}
                      </h3>
                    </Col>
                    <Col span={12}>
                      <h3 className={'det'}>
                        {' '}
                        <small>Fee</small> <br />{' '}
                        NGN {numberFormatter(pointFormat, orderDetails.fee)}{' '}
                      </h3>
                    </Col>
                    <Col span={24}>
                      <h3 className={'det'}>
                        {' '}
                        <small>Amount Payable</small> <br />{' '}
                        NGN {numberFormatter(
                          pointFormat,
                          orderDetails.amountPayable,
                        )}{' '}
                      </h3>
                    </Col>
                  </Row>
                  <Formik
                    initialValues={{
                      accountNumber: '',
                    }}
                    validationSchema={validate}
                    onSubmit={(
                      values,
                      { setSubmitting, setErrors, resetForm },
                    ) => {
                      setLaddaLoading(true);
                      setLaddaProgress(0.5);

                      values.referenceId = receiptNumber;
                      values.accountNumber = encryptedPassword(
                        values.accountNumber,
                      );
                      // console.log(values);

                      props.checkOutCartAccount(values, response =>
                        _.isEmpty(response.error) && response.error !== true
                          ? (() => {
                              setStep(2);
                              setNot(response.message);

                              // notification['success']({
                              //   message: '',
                              //   description: response.message,
                              //   duration: NOTIFICATION_DURATION,
                              // });
                              setLaddaProgress(1.0);
                              setLaddaLoading(false);
                              setSubmitting(false);
                              resetForm();
                            })() :
                          (() => {
                            moveOut('error', response);
                            // handleNotify(response, setSubmitting, () => {
                              setLaddaProgress(1.0);
                              setLaddaLoading(false);
                              handleFieldError(
                                response.validationErrors,
                                setErrors,
                              );
                              resetForm();
                            // })
                          })()
                      );

                      // props.checkOutCartFromAccount(values, response =>
                      //   _.isEmpty(response.error) && response.error !== true
                      //     ? handleIn()
                      //     : handleNotify(response, setSubmitting, () => {
                      //       setLaddaProgress(1.0);
                      //       setLaddaLoading(false);
                      //       handleFieldError(
                      //         response.validationErrors,
                      //         setErrors,
                      //       );
                      //     }),
                      // );
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit} className={'login_form '}>
                        <div className={'form_holder form-group'}>
                          <div className="form-label-group-holder">
                            <div className="form-label-group">
                              {/*<label> Account Number</label>*/}
                              <input
                                className="form-control"
                                type="text"
                                name="accountNumber"
                                id="inputAccount"
                                placeholder="Enter your account number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.accountNumber}
                              />

                              {errors.accountNumber &&
                                touched.accountNumber && (
                                  <FormFeedback>
                                    {errors.accountNumber}
                                  </FormFeedback>
                                )}
                            </div>
                          </div>
                        </div>

                        <LaddaButton
                          loading={laddaLoading}
                          progress={laddaProgress}
                          data-style={SLIDE_UP}
                          data-spinner-size={30}
                          data-spinner-color="#fff"
                          data-spinner-lines={12}
                          className="btn-submit btn btn-primary btn-style"
                          type="submit"
                          disabled={isSubmitting}>
                          Pay
                        </LaddaButton>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
            <div className={`${step === 2 ? 'show' : 'hide'}`}>
              {/*<p>Enter account enter OTP Code </p>*/}
              {not && (
                <>
                  <Alert message={not} type="info" /> <br />{' '}
                </>
              )}
              <Formik
                initialValues={{
                  verificationCode: '',
                }}
                validationSchema={validates}
                onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                  setLaddaLoading3(true);
                  setLaddaProgress3(0.5);

                  values.referenceId = receiptNumber;
                  values.verificationCode = encryptedPassword(
                    values.verificationCode,
                  );
                  // console.log(values);

                  props.checkOutCartVerifyPayment(values, response =>
                    _.isEmpty(response.error) && response.error !== true
                      ? (() => {
                          setStep(1);
                          // notification['success']({
                          //   message: 'Transaction Completed',
                          //   description: 'Bank Account Payment Successful',
                          //   duration: NOTIFICATION_DURATION,
                          // });
                          setOpen(false);
                          // setLaddaLoading3(false);
                          // setLaddaProgress3(1.0);
                          // setSubmitting(false);
                          moveOut('success', response);
                        })()
                      : (() => {
                          moveOut('error', response);
                          // handleNotify(response, setSubmitting, () => {
                            setLaddaProgress(1.0);
                            setLaddaLoading(false);
                            resetForm();
                            handleFieldError(
                              response.validationErrors,
                              setErrors,
                            );
                          // });
                        })(),
                  );
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className={'login_form '}>
                    <div className={'form_holder form-group'}>
                      <div className="form-label-group-holder">
                        <div className="form-label-group">
                          {/*<label> Verification Code</label>*/}
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter OTP Code"
                            name="verificationCode"
                            id="inputAccount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.verificationCode}
                          />

                          {errors.verificationCode &&
                            touched.verificationCode && (
                              <FormFeedback>
                                {errors.verificationCode}
                              </FormFeedback>
                            )}
                        </div>
                      </div>
                    </div>

                    <LaddaButton
                      loading={laddaLoading3}
                      progress={laddaProgress3}
                      data-style={SLIDE_UP}
                      data-spinner-size={30}
                      data-spinner-color="#fff"
                      data-spinner-lines={12}
                      className="btn-submit btn btn-primary btn-style"
                      type="submit"
                      disabled={isSubmitting}>
                      Pay NGN {numberFormatter(pointFormat, orderDetails.amountPayable)}{' '}
                    </LaddaButton>
                  </form>
                )}
              </Formik>
            </div>

            {/*<p className="title">Insufficient Points</p>*/}
            {/*<p className="text">*/}
            {/*  Need <strong></strong> to*/}
            {/*  complete this order or Pay*/}
            {/*</p>*/}
            {/* <p className="price">₦15,000.00</p> */}

            {/* <Button
          className="fd-button primary"
          size="large"
          shape="round"
          onClick={() => setShowPayment(true)}>
          PAY WITH CARD
        </Button> */}
          </div>
        </Modal>
      </>
    );
  };

  return (
    <main className="giftcard-view-section">
      <div className="carts-container">
        <Row className="cart-columns">
          {error ? (
            showError()
          ) : (
            <>
              {!props.payment_loading ? (
                <Loading />
              ) : (
                <Col className="cart-column-2" span={24}>
                  <div className="container">
                    <h2 className="blue">Payment page</h2>
                    <p>
                      You do not have enough points to purchase order. Proceed
                      to payment.
                    </p>
                    {/* <Alert  message="You do not have enough points to purchase order. Proceed to payment." type="info"    showIcon /> */}
                    <Divider />

                    <>
                      <Descriptions
                        title="Order Purchase"
                        layout="vertical"
                        className="descriptions">
                        <Descriptions.Item label="Email">
                          {props.profile_loading ? (
                            <Spin />
                          ) : (
                            <>{props.authUser.email}</>
                          )}
                          {/*{parseOrderDetails.email}*/}
                        </Descriptions.Item>
                        <Descriptions.Item label="Order Id">
                          {receiptNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label="Outstanding Amount">
                          {/*{parseOrderDetails.outstandingAmount}*/}
                          NGN {numberFormatter(pointFormat, outstandingAmount)}
                        </Descriptions.Item>
                      </Descriptions>

                      <form
                        method="POST"
                        id="upay_form"
                        name="upay_form"
                        // action="https://fidelitypaygate.fidelitybank.ng/cipg/MerchantServices/MakePayment.aspx"
                        action={requestUrl}
                        target="_top">
                        <input
                          type="hidden"
                          name="mercId"
                          id="mercId"
                          //value="05947"
                          value={merchantId}
                        />
                        <input
                          type="hidden"
                          name="currCode"
                          id="currCode"
                          value="566"
                        />
                        <input
                          //ref={refAmt}
                          type="hidden"
                          name="amt"
                          id="amt"
                          // value="100"
                          value={outstandingAmount}
                        />
                        <input
                          //ref={refOrderId}
                          type="hidden"
                          name="orderId"
                          id="orderId"
                          value={receiptNumber}
                        />
                        <input
                          type="hidden"
                          name="prod"
                          id="prod"
                          value="Order purchase"
                        />
                        <input
                          type="hidden"
                          name="email"
                          id="email"
                          value={props.authUser.email}
                        />
                        ​ ​
                        <input
                          type="hidden"
                          name="hash"
                          id="hash"
                          value={tempHash}
                        />
                        <input
                          type="hidden"
                          name="encryptedKey"
                          id="encryptedKey"
                          value={tempEncrypt}
                        />
                        <input
                          type="submit"
                          name="submit"
                          value="PAY WITH CARD"
                          ref={payWithCard}
                          //   onClick={}
                          className="fd-button primary btn-round hide"
                        />
                        {/*{!hidden && submitPayToCard()}*/}
                        <LaddaButton
                          loading={laddaLoading2}
                          progress={laddaProgress2}
                          onClick={triggerButtonClick}
                          data-style={SLIDE_UP}
                          data-spinner-size={30}
                          data-spinner-color="#fff"
                          data-spinner-lines={12}
                          className="ant-btn make_payment ant-btn-round fd-button primary"
                          type="submit">
                          <CreditCardOutlined style={{verticalAlign: 'top', margin: '0 8px'}}/>{' '}
                          PAY WITH CARD{' '}
                        </LaddaButton>{' '}
                        {accountNumberModal()}

                        <LaddaButton
                          loading={laddaLoading4}
                          progress={laddaProgress4}
                          onClick={triggerButtonClickAccount}
                          data-style={SLIDE_UP}
                          data-spinner-size={30}
                          data-spinner-color="#fff"
                          data-spinner-lines={12}
                          className="ladda-button ant-btn ant-btn-round fd-button primary"
                          type="button">
                          <BankOutlined style={{verticalAlign: 'top', margin: '0 8px'}} />{' '}
                          PAY WITH ACCOUNT NUMBER{' '}
                        </LaddaButton>
                        {/*<Button*/}
                        {/*  onClick={triggerButtonClickAccount}*/}
                        {/*  className={'ladda-button ant-btn ant-btn-round fd-button primary'}> PAY WITH ACCOUNT*/}
                        {/*  NUMBER </Button>*/}
                      </form>
                    </>

                    <div>
                      {/*<Button>  </Button> className="ant-btn ant-btn-round fd-button primary"*/}
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </Col>
              )}
            </>
          )}
        </Row>
      </div>
    </main>
  );
};

const mapStateToProps = ({ cart, auth }) => {
  const { payment_info, payment_loading } = cart;
  const { authUser, profile_loading } = auth;

  return { payment_info, payment_loading, authUser, profile_loading };
};

export default connect(mapStateToProps, {
  getPaymentInfo,
  checkOutCartFromPayment,
  checkOutCartFromAccount,
  checkOutCartAccount,
  checkOutCartVerifyPayment,
})(Payment);
