import { MAIN_LOADING_OFF, MAIN_LOADING_ON } from '../constants';

const INIT_STATE = {
  main_loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAIN_LOADING_ON: {
      return {
        ...state,
        main_loading: true,
      };
    }
    case MAIN_LOADING_OFF: {
      return {
        ...state,
        main_loading: false,
      };
    }
    default:
      return state;
  }
};
