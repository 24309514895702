import {
  ADD_AIRTME_TO_WLIST_FAILED,
  ADD_AIRTME_TO_WLIST_START,
  ADD_AIRTME_TO_WLIST_SUCCESS,
  FETCH_AIRTIME_FAILED,
  FETCH_AIRTIME_START,
  FETCH_AIRTIME_SUCCESS,
  FETCH_GIFT_CARDS_FAILED,
  FETCH_GIFT_CARDS_START,
  FETCH_SINGLE_AIRTIME_FAILED,
  FETCH_SINGLE_AIRTIME_START,
  FETCH_SINGLE_AIRTIME_SUCCESS,
  SEND_FRIEND_AIRTME_FAILED,
  SEND_FRIEND_AIRTME_START,
  SEND_FRIEND_AIRTME_SUCCESS,
} from '../constants';

const INIT_STATE = {
  airtime_loading: false,
  init_loading: false,
  loading: false,
  airtimes: [],
  single_airtime: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_AIRTIME_START: {
      return {
        ...state,
        airtime_loading: true,
      };
    }
    case FETCH_AIRTIME_SUCCESS: {
      return {
        ...state,
        airtimes: action.payload,
        airtime_loading: false,
      };
    }
    case FETCH_AIRTIME_FAILED: {
      return {
        ...state,
        airtime_loading: false,
      };
    }
    case FETCH_SINGLE_AIRTIME_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_SINGLE_AIRTIME_SUCCESS: {
      return {
        ...state,
        single_airtime: action.payload,
        loading: false,
      };
    }
    case FETCH_SINGLE_AIRTIME_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_AIRTME_TO_WLIST_START:
    case SEND_FRIEND_AIRTME_START:
    case FETCH_GIFT_CARDS_START: {
      return {
        ...state,
        init_loading: true,
      };
    }

    case ADD_AIRTME_TO_WLIST_SUCCESS:
    case SEND_FRIEND_AIRTME_SUCCESS: {
      return {
        ...state,
        init_loading: false,
      };
    }
    case ADD_AIRTME_TO_WLIST_FAILED:
    case SEND_FRIEND_AIRTME_FAILED:
    case FETCH_GIFT_CARDS_FAILED: {
      return {
        ...state,
        init_loading: false,
      };
    }
    default:
      return state;
  }
};
