// Module constants
export const LOADING_MAIN = 'LOADING_MAIN';
export const LOADING_MAIN_DONE = 'LOADING_MAIN_DONE';
export const GLOBAL_FETCH_START = 'GLOBAL_FETCH_START';
export const GLOBAL_FETCH_DONE = 'GLOBAL_FETCH_DONE';
export const FETCH_ERROR_MESSAGE = `Error!, unable to access the serve. Please try again`;
export const LIMIT = 20;
export const OPEN_MENU = 'OPEN_MENU';

// Module COOKIES_TIME
export const COOKIES_TIME = 7;
export const NOTIFICATION_DURATION = 4;

//Payment
export const PAYMENT_SUCCESSFUL = 1;
export const PAYMENT_FAILED = 2;

// Module auth
export const MAIN_LOADING_ON = 'MAIN_LOADING_ON';
export const MAIN_LOADING_OFF = 'MAIN_LOADING_OFF';

// Module auth
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const ACTIVATION_ACCOUNT_NO = 'ACTIVATION_ACCOUNT_NO';
export const VALIDATE_ACCOUNT_CODE = 'VALIDATE_ACCOUNT_CODE';
export const REGISTER_ACCOUNT = 'REGISTER_ACCOUNT';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_DONE = 'PROFILE_DONE';

// Module cart
export const UPDATE_CART = 'UPDATE_CART';
export const FETCH_CART_START = 'FETCH_CART_START';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAILED = 'FETCH_CART_FAILED';
export const REMOVE_CART_ITEM_START = 'REMOVE_CART_ITEM_START';
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
export const REMOVE_CART_ITEM_FAILED = 'REMOVE_CART_ITEM_FAILED';
export const EDIT_CART_ITEM_START = 'EDIT_CART_ITEM_START';
export const EDIT_CART_ITEM_SUCCESS = 'EDIT_CART_ITEM_SUCCESS';
export const EDIT_CART_ITEM_FAILED = 'EDIT_CART_ITEM_FAILED';
export const CLEAR_CART_START = 'CLEAR_CART_START';
export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS';
export const CLEAR_CART_FAILED = 'CLEAR_CART_FAILED';
export const CART_CHECKOUT_START = 'CART_CHECKOUT_START';
export const CART_CHECKOUT_SUCCESS = 'CART_CHECKOUT_SUCCESS';
export const CART_CHECKOUT_FAILED = 'CART_CHECKOUT_FAILED';
export const CART_PAYMENT_INFO_START = 'CART_PAYMENT_INFO_START';
export const CART_PAYMENT_INFO_SUCCESS = 'CART_PAYMENT_INFO_SUCCESS';
export const CART_PAYMENT_INFO_FAILED = 'CART_PAYMENT_INFO_FAILED';

//Module products
export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const FETCH_PRODUCTS_CATEGORIES_START =
  'FETCH_PRODUCTS_CATEGORIES_START';
export const FETCH_PRODUCTS_CATEGORIES_SUCCESS =
  'FETCH_PRODUCTS_CATEGORIES_SUCCESS';
export const FETCH_PRODUCTS_CATEGORIES_FAILED =
  'FETCH_PRODUCTS_CATEGORIES_FAILED';
export const FETCH_SINGLE_PRODUCT_START = 'FETCH_SINGLE_PRODUCT_START';
export const FETCH_SINGLE_PRODUCT_SUCCESS = 'FETCH_SINGLE_PRODUCT_SUCCESS';
export const FETCH_SINGLE_PRODUCT_FAILED = 'FETCH_SINGLE_PRODUCT_FAILED';

//Module Airtime
export const FETCH_AIRTIME_START = 'FETCH_AIRTIME_START';
export const FETCH_AIRTIME_SUCCESS = 'FETCH_AIRTIME_SUCCESS';
export const FETCH_AIRTIME_FAILED = 'FETCH_AIRTIME_FAILED';
export const FETCH_SINGLE_AIRTIME_START = 'FETCH_SINGLE_AIRTIME_START';
export const FETCH_SINGLE_AIRTIME_SUCCESS = 'FETCH_SINGLE_AIRTIME_SUCCESS';
export const FETCH_SINGLE_AIRTIME_FAILED = 'FETCH_SINGLE_AIRTIME_FAILED';
export const SEND_FRIEND_AIRTME_START = 'SEND_FRIEND_AIRTME_START';
export const SEND_FRIEND_AIRTME_SUCCESS = 'SEND_FRIEND_AIRTME_SUCCESS';
export const SEND_FRIEND_AIRTME_FAILED = 'SEND_FRIEND_AIRTME_FAILED';
export const SEND_SELF_AIRTME_START = 'SEND_SELF_AIRTME_START';
export const SEND_SELF_AIRTME_SUCCESS = 'SEND_SELF_AIRTME_SUCCESS';
export const SEND_SELF_AIRTME_FAILED = 'SEND_SELF_AIRTME_FAILED';
export const ADD_AIRTME_TO_WLIST_START = 'ADD_AIRTME_TO_WLIST_START';
export const ADD_AIRTME_TO_WLIST_SUCCESS = 'ADD_AIRTME_TO_WLIST_SUCCESS';
export const ADD_AIRTME_TO_WLIST_FAILED = 'ADD_AIRTME_TO_WLIST_FAILED';

//Module gift cards
export const FETCH_SINGLE_GIFT_CARDS_START = 'FETCH_SINGLE_GIFT_CARDS_START';
export const FETCH_SINGLE_GIFT_CARDS_SUCCESS =
  'FETCH_SINGLE_GIFT_CARDS_SUCCESS';
export const FETCH_SINGLE_GIFT_CARDS_FAILED = 'FETCH_SINGLE_GIFT_CARDS_FAILED';
export const FETCH_GIFT_CARDS_START = 'FETCH_GIFT_CARDS_START';
export const FETCH_GIFT_CARDS_SUCCESS = 'FETCH_GIFT_CARDS_SUCCESS';
export const FETCH_GIFT_CARDS_FAILED = 'FETCH_GIFT_CARDS_FAILED';
export const SEND_FRIEND_GCARD_START = 'SEND_FRIEND_GCARD_START';
export const SEND_FRIEND_GCARD_SUCCESS = 'SEND_FRIEND_GCARD_SUCCESS';
export const SEND_FRIEND_GCARD_FAILED = 'SEND_FRIEND_GCARD_FAILED';
export const SEND_SELF_GCARD_START = 'SEND_SELF_GCARD_START';
export const SEND_SELF_GCARD_SUCCESS = 'SEND_SELF_GCARD_SUCCESS';
export const SEND_SELF_GCARD_FAILED = 'SEND_SELF_GCARD_FAILED';
export const ADD_GCARD_TO_WLIST_START = 'ADD_GCARD_TO_WLIST_START';
export const ADD_GCARD_TO_WLIST_SUCCESS = 'ADD_GCARD_TO_WLIST_SUCCESS';
export const ADD_GCARD_TO_WLIST_FAILED = 'ADD_GCARD_TO_WLIST_FAILED';
export const FETCH_GIFT_CARDS_FEATURE_START = 'FETCH_GIFT_CARDS_FEATURE_START';
export const FETCH_GIFT_CARDS_FEATURE_SUCCESS =
  'FETCH_GIFT_CARDS_FEATURE_SUCCESS';
export const FETCH_GIFT_CARDS_FEATURE_FAILED =
  'FETCH_GIFT_CARDS_FEATURE_FAILED';

// Modlue wishlist
export const FETCH_WISHLIST_START = 'FETCH_WISHLIST_START';
export const FETCH_WISHLIST_SUCCESS = 'FETCH_WISHLIST_SUCCESS';
export const FETCH_WISHLIST_FAILED = 'FETCH_WISHLIST_FAILED';
export const REMOVE_WISHLIST_ITEM_START = 'REMOVE_WISHLIST_ITEM_START';
export const REMOVE_WISHLIST_ITEM_SUCCESS = 'REMOVE_WISHLIST_ITEM_SUCCESS';
export const REMOVE_WISHLIST_ITEM_FAILED = 'REMOVE_WISHLIST_ITEM_FAILED';

export const SEND_GIFT_CARD_TO_SELF = 'SEND_GIFT_CARD_TO_SELF';
export const ADD_SINGLE_GIFT_TO_CART = 'ADD_SINGLE_GIFT_TO_CART';
export const FETCH_GIFT_CARDS_CATEGORIES_START =
  'FETCH_GIFT_CARDS_CATEGORIES_START';
export const FETCH_GIFT_CARDS_CATEGORIES_SUCCESS =
  'FETCH_GIFT_CARDS_CATEGORIES_SUCCESS';
export const FETCH_GIFT_CARDS_CATEGORIES_FAILED =
  'FETCH_GIFT_CARDS_CATEGORIES_FAILED';
export const FETCH_GIFT_CARDS_LOCATIONS_START =
  'FETCH_GIFT_CARDS_LOCATIONS_START';
export const FETCH_GIFT_CARDS_LOCATIONS_SUCCESS =
  'FETCH_GIFT_CARDS_LOCATIONS_SUCCESS';
export const FETCH_GIFT_CARDS_LOCATIONS_FAILED =
  'FETCH_GIFT_CARDS_LOCATIONS_FAILED';

//Module orders
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED';
export const FETCH_ORDER_START = 'FETCH_ORDER_START';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILED = 'FETCH_ORDER_FAILED';

//Module transactions
export const FETCH_TRANSACTIONS_START = 'FETCH_TRANSACTIONS_START';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILED = 'FETCH_TRANSACTIONS_FAILED';

//Module account summary
export const FETCH_ACCOUNT_SUMMARY_START = 'FETCH_ACCOUNT_SUMMARY_START';
export const FETCH_ACCOUNT_SUMMARY_SUCCESS = 'FETCH_ACCOUNT_SUMMARY_SUCCESS';
export const FETCH_ACCOUNT_SUMMARY_FAILED = 'FETCH_ACCOUNT_SUMMARY_FAILED';

//Module payments
export const COMPLETE_PAYMENT_START = 'COMPLETE_PAYMENT_START';
export const COMPLETE_PAYMENT_SUCCESS = 'COMPLETE_PAYMENT_SUCCESS';
export const COMPLETE_PAYMENT_FAILED = 'COMPLETE_PAYMENT_FAILED';

// links
export const LOGIN = 'login';
export const FORGOT_PASSWORD = '/Account/ForgotPassword';
export const SEND_ACTIVATION_CODE = '/Account/SendActivationCode';
export const VALIDATION = '/Account/ValidateActivateCode';
export const REGISTER = '/Account/Register';
export const UPDATE_PROFILE = '/Account/UpdateProfile';
export const CHANGE_PASSWORD = '/Account/ChangePassword';
export const GET_TOKEN = '/Account/GetToken';
export const GET_REFRESH_TOKEN = '/Account/RefereshToken';
export const GET_PROFILE = '/Account/GetProfile';
export const GET_PRODUCTS = '/Orders/GetProducts';
export const GET_PRODUCTS_CATEGORIES = '/Orders/GetProductCategories';
export const GET_AIRTIME = '/Orders/GetAirtimes';
export const GET_GIFT_CARDS = '/Orders/GetGiftCards';
export const GET_FEATURE_GIFT_CARDS = '/Orders/GetFeatureGiftCards';
export const GET_GIFT_CARDS_CATEGORIES = '/Orders/GetGiftsCategories';
export const GET_GIFT_CARDS_LOCATIONS = '/Orders/GetLocations';
export const GET_ORDERS = '/Orders/GetOrders';
export const GET_TRANSACTIONS = '/Transactions/GetTransactions';
export const GET_ACCOUNT_SUMMARY = '/Transactions/GetAccountSummary';
export const COMPLETE_PAYMENT = '/Payments/CompletePayment';
export const CART_PAYMENT_INFO = '/Orders/GetCartPaymentInfo';
