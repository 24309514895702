import React from 'react';
import _ from 'lodash';
import { Button, Result } from 'antd';
import LinkComponent from '../../route/LinkComponent';

function Missing404(props) {
  return (
    <div>
      <div className="container error-container">
        <div style={{ marginTop: 140, display: 'block' }}>
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-6  text-center">
              <div>
                <Result
                  status="404"
                  title="Oops! 404"
                  subTitle="Sorry, the page you visited does not exist. Don't worry though, everything is STILL AWESOME!"
                  extra={
                    <LinkComponent to={'/'}>
                      <Button
                        className="fd-button primary"
                        type="link"
                        shape="round">
                        Back to Homepage
                      </Button>
                    </LinkComponent>
                  }
                />
                {/*<h2>Missing404 {!_.isEmpty(props.componentMissing) && props.componentMissing === true && 'Added Component Missing'}</h2>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Missing404);
