import React from 'react';
import { Select, Table, Tag, Input, Button, Space } from 'antd';
import {
  MoreOutlined,
  SearchOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { totalUsers, activeUsers } from 'images';

function Users(props) {
  const { Option } = Select;

  // Dummy table
  const columns = [
    {
      title: 'Order No.',
      dataIndex: 'order',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: status => (
        <span>
          {() => {
            let color = status ? 'green' : 'volcano';
            return (
              <Tag color={color} key={status}>
                {status.toUpperCase()}
              </Tag>
            );
          }}
        </span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Account No.',
      dataIndex: 'acct',
    },
    {
      title: '',
      dataIndex: 'id',
      render: id => (
        <>
          <MoreOutlined />
        </>
      ),
    },
  ];
  const data = [
    {
      key: '1',
      order: 'SDFOP56',
      name: 'John Brown',
      status: true,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      order: 'OIUHG67',
      name: 'Jim Green',

      status: true,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      order: 'DUKEN90',
      name: 'Joe Black',

      status: true,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '4',
      order: 'PUYTFV54',
      name: 'Disabled User',
      status: false,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '1',
      order: 'SDFOP56',
      name: 'John Brown',
      status: true,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      order: 'OIUHG67',
      name: 'Jim Green',

      status: true,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      order: 'DUKEN90',
      name: 'Joe Black',

      status: true,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '4',
      order: 'PUYTFV54',
      name: 'Disabled User',
      status: false,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '1',
      order: 'SDFOP56',
      name: 'John Brown',
      status: true,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      order: 'OIUHG67',
      name: 'Jim Green',

      status: true,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      order: 'DUKEN90',
      name: 'Joe Black',

      status: true,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '4',
      order: 'PUYTFV54',
      name: 'Disabled User',
      status: false,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '1',
      order: 'SDFOP56',
      name: 'John Brown',
      status: true,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      order: 'OIUHG67',
      name: 'Jim Green',

      status: true,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      order: 'DUKEN90',
      name: 'Joe Black',

      status: true,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '4',
      order: 'PUYTFV54',
      name: 'Disabled User',
      status: false,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '1',
      order: 'SDFOP56',
      name: 'John Brown',
      status: true,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      order: 'OIUHG67',
      name: 'Jim Green',

      status: true,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      order: 'DUKEN90',
      name: 'Joe Black',

      status: true,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '4',
      order: 'PUYTFV54',
      name: 'Disabled User',
      status: false,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '1',
      order: 'SDFOP56',
      name: 'John Brown',
      status: true,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      order: 'OIUHG67',
      name: 'Jim Green',

      status: true,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      order: 'DUKEN90',
      name: 'Joe Black',

      status: true,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '4',
      order: 'PUYTFV54',
      name: 'Disabled User',
      status: false,
      address: 'Sidney No. 1 Lake Park',
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   'selectedRows: ',
      //   selectedRows,
      // );
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <div className="orders">
      <div className="top-stats">
        <div className="top-stats__single">
          <img src={totalUsers} alt="" />
          <div>
            <p className="top-stats__single-title">
              <b>74.5M</b>
            </p>
            <p>Total Users</p>
          </div>
        </div>
        <div className="top-stats__single">
          <img src={activeUsers} alt="" />
          <div>
            <p className="top-stats__single-title">
              <b>74.5M</b>
            </p>
            <p>Active Users</p>
          </div>
        </div>
      </div>
      <div className="recent-orders">
        <div className="recent-orders__header">
          <div className="flex">
            <p className="recent-orders__header-title">All Users</p>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              className="search"
            />
          </div>
          <Button shape="round" icon={<DownloadOutlined />} size={'default'}>
            Export
          </Button>
        </div>
        <Space style={{ marginBottom: 16 }}>
          <Select
            defaultValue="Name"
            style={{ width: 120 }}
            onChange={console.log('CHANGED')}>
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="Yiminghe">yiminghe</Option>
          </Select>
          <Select
            defaultValue="Accounts"
            style={{ width: 120 }}
            onChange={console.log('CHANGED')}>
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="Yiminghe">yiminghe</Option>
          </Select>
          <Select
            defaultValue="Status"
            style={{ width: 120 }}
            onChange={console.log('CHANGED')}>
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="Yiminghe">yiminghe</Option>
          </Select>
          <Select
            defaultValue="Last Updated"
            style={{ width: 120 }}
            onChange={console.log('CHANGED')}>
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="Yiminghe">yiminghe</Option>
          </Select>
        </Space>
        <hr />
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          pagination={{
            position: ['topRight', 'none'],
            showTotal: (total, range) =>
              `Viewing ${range[0]}-${range[1]} of ${total}`,
          }}
        />
      </div>
    </div>
  );
}

export default Users;
