import React, { useState, useEffect } from 'react';
import { Link, useLocation } from '@reach/router';
import { Avatar, Badge, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from 'reactstrap';
import LinkComponent from '../../route/LinkComponent';
import { ReactComponent as Cart } from '../../assets/svg/cart.svg';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchCart, updateCart } from '../../appRedux/action/Cart';
import _ from 'lodash';
import { Logo } from '../index';

function NavInterface(props) {
  const [isOpen, setIsOpen] = useState(false);

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: ' fd-menu-item active' } : {};
  };
  const isActive = ({ isCurrent }) => {
    return isCurrent ? { className: 'fd-menu-item active' } : {};
  };

  const dispatch = useDispatch();
  const allCartItems = useSelector(state => state.cart.cart.items);
  const { loading } = useSelector(state => state.cart);
  const { authUser, profile_loading } = useSelector(state => state.auth);
  const link = new URL(window.location.href);

  useEffect(() => {
    // console.log(link, authUser);
    if (
      !_.isEmpty(authUser) &&
      !link.href.includes(`TransactionReference`) &&
      link.pathname !== '/' &&
      link.pathname !== '/activation' &&
      link.pathname !== '/create-password' &&
      link.pathname !== '/order-completed' &&
      link.pathname !== '/airtimes' &&
      link.pathname !== '/gift-cards'
    ) {
      dispatch(fetchCart());
    }
  }, []);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="web_menu rel full">
        <ul className="fd-menu">
          <li className="nav-item">
            <Link getProps={isActive} className="fd-menu-item" to="/">
              Home
            </Link>
          </li>
          {/*<li className="nav-item">*/}
          {/*  <Link*/}
          {/*    getProps={isPartiallyActive}*/}
          {/*    className="fd-menu-item"*/}
          {/*    to="/bills">*/}
          {/*    Pay Bills*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li className="nav-item">
            <Link
              getProps={isPartiallyActive}
              className="fd-menu-item"
              to="/airtimes">
              Airtime
            </Link>
          </li>
          <li className="nav-item">
            <Link
              getProps={isPartiallyActive}
              className="fd-menu-item"
              to="/gift-cards">
              Gift Cards
            </Link>
          </li>
          {/*<li className="nav-item">*/}
          {/*  <Link*/}
          {/*    getProps={isPartiallyActive}*/}
          {/*    className="fd-menu-item"*/}
          {/*    to="/products">*/}
          {/*    Products*/}
          {/*  </Link>*/}
          {/*</li>*/}
          {/*<li className="nav-item">*/}
          {/*    <a*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener"*/}
          {/*        className="fd-menu-item"*/}
          {/*        href="https://www.fidelitybank.ng/online-banking/">*/}
          {/*        E-Banking*/}
          {/*    </a>*/}
          {/*</li>*/}
          {/*<li className="nav-item">*/}
          {/*    <a*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener"*/}
          {/*        className="fd-menu-item"*/}
          {/*        href="https://www.fidelitybank.ng/retail-banking/savings-account/">*/}
          {/*        Savings Account*/}
          {/*    </a>*/}
          {/*</li>*/}
          {/*<li className="nav-item">*/}
          {/*    <a*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener"*/}
          {/*        className="fd-menu-item"*/}
          {/*        href="https://www.fidelitybank.ng/retail-banking/current-account/">*/}
          {/*        Current Account*/}
          {/*    </a>*/}
          {/*</li>*/}
        </ul>
      </div>
      <NavbarToggler onClick={toggle} />
      <div
        className={`${
          _.isEmpty(props.authUser) ? 'port_holder' : 'port_holder_in'
        }`}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: 56,
        }}>
        {/*<div style={{marginRight: 10}}>*/}
        {/*    <Avatar size="large" icon={<UserOutlined/>}/>*/}
        {/*</div> cart.svg*/}
        {_.isEmpty(props.authUser) ? (
          <Link
            getProps={isPartiallyActive}
            className="fd-menu-item login"
            to="/login">
            Login
          </Link>
        ) : (
          <>
            <div>
              <div
                style={{ margin: '26px 26px 38px 21px' }}
                className="cart_holder_style">
                {link.pathname === '/' &&
                link.pathname === '/activation' &&
                link.pathname === '/gift-cards' ? (
                  <Link to="/cart">
                    {!_.isEmpty(allCartItems) ? (
                      <Badge count={allCartItems.length}>
                        <Cart style={{ width: '24px', height: '24px' }} />
                      </Badge>
                    ) : (
                      <Cart style={{ width: '23px', height: '24px' }} />
                    )}
                  </Link>
                ) : (
                  <>
                    {loading ? (
                      <>
                        {!_.isEmpty(authUser) &&
                          !link.href.includes(`TransactionReference`) &&
                          // link.pathname !== '/' &&
                          link.pathname !== '/activation' &&
                          link.pathname !== '/create-password' &&
                          link.pathname !== '/order-completed' && (
                            // link.pathname !== '/airtimes' &&
                            // link.pathname !== '/gift-cards'
                            <>
                              {' '}
                              <Link to="/cart">
                                <Cart
                                  style={{ width: '23px', height: '24px' }}
                                />
                              </Link>
                              {/*<Spin/>*/}
                            </>
                          )}{' '}
                      </>
                    ) : (
                      <Link to="/cart">
                        {!_.isEmpty(allCartItems) ? (
                          <Badge count={allCartItems.length}>
                            <Cart style={{ width: '24px', height: '24px' }} />
                          </Badge>
                        ) : (
                          <Cart style={{ width: '23px', height: '24px' }} />
                        )}
                      </Link>
                    )}{' '}
                  </>
                )}
              </div>
            </div>
            <UncontrolledDropdown nav inNavbar className="main_nav">
              <DropdownToggle nav caret>
                <Avatar
                  size="large"
                  icon={<UserOutlined twoToneColor="#eb2f96" />}
                />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <LinkComponent to={'/summary'}>My Account</LinkComponent>
                </DropdownItem>
                <DropdownItem>
                  <LinkComponent to={'/order-history'}>
                    Order History
                  </LinkComponent>
                </DropdownItem>
                <DropdownItem>
                  <LinkComponent to={'/points-history'}>
                    Point History
                  </LinkComponent>
                </DropdownItem>
                <DropdownItem>
                  <LinkComponent to={'/wishlist'}>Wishlist</LinkComponent>
                </DropdownItem>
                <DropdownItem>
                  <LinkComponent to={'/logout'}>
                    <span style={{ color: 'red' }}>Log Out</span>
                  </LinkComponent>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        )}
      </div>
      <Logo style={{ marginTop: 20, marginLeft: 0 }} />
      <Collapse isOpen={isOpen} navbar className="mobile_menu">
        <ul className="fd-menu">
          <li className="nav-item">
            <Link getProps={isActive} className="fd-menu-item" to="/">
              Home
            </Link>
          </li>
          {/*<li className="nav-item">*/}
          {/*  <Link*/}
          {/*    getProps={isPartiallyActive}*/}
          {/*    className="fd-menu-item"*/}
          {/*    to="/bills">*/}
          {/*    Pay Bills*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li className="nav-item">
            <Link
              getProps={isPartiallyActive}
              className="fd-menu-item"
              to="/airtimes">
              Airtime
            </Link>
          </li>
          <li className="nav-item">
            <Link
              getProps={isPartiallyActive}
              className="fd-menu-item"
              to="/gift-cards">
              Gift Cards
            </Link>
          </li>
          {/*<li className="nav-item">*/}
          {/*  <Link*/}
          {/*    getProps={isPartiallyActive}*/}
          {/*    className="fd-menu-item"*/}
          {/*    to="/products">*/}
          {/*    Products*/}
          {/*  </Link>*/}
          {/*</li>*/}
          {/*<li className="nav-item">*/}
          {/*    <a*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener"*/}
          {/*        className="fd-menu-item"*/}
          {/*        href="https://www.fidelitybank.ng/online-banking/">*/}
          {/*        E-Banking*/}
          {/*    </a>*/}
          {/*</li>*/}
          {/*<li className="nav-item">*/}
          {/*    <a*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener"*/}
          {/*        className="fd-menu-item"*/}
          {/*        href="https://www.fidelitybank.ng/retail-banking/savings-account/">*/}
          {/*        Savings Account*/}
          {/*    </a>*/}
          {/*</li>*/}
          {/*<li className="nav-item">*/}
          {/*    <a*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener"*/}
          {/*        className="fd-menu-item"*/}
          {/*        href="https://www.fidelitybank.ng/retail-banking/current-account/">*/}
          {/*        Current Account*/}
          {/*    </a>*/}
          {/*</li>*/}
        </ul>
      </Collapse>
    </>
  );
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { updateCart })(NavInterface);
