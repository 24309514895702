import React from 'react';
import { Logo } from '../index';
import { Row, Col, Spin } from 'antd';
import FidelityLoader from '../../assets/img/fidelity-Loader.gif';

function Loading(props) {
  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <div style={{ width: '100%' }} className="loading_holder">
            <div className="text-center">
              {/*<div>*/}
              {/*    <Spin/>*/}
              {/*</div>*/}
              {/*<br/>*/}
              {/*<div className="">*/}
              {/*    <div className="spinner">*/}
              {/*        <div className="bounce1"/>*/}
              {/*        <div className="bounce2"/>*/}
              {/*        <div className="bounce3"/>*/}
              {/*    </div>*/}
              {/*</div>*/}

              <img src={FidelityLoader} />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Loading;
