import _ from 'lodash';
import { notification } from 'antd';

import { GLOBAL_FETCH_START, GLOBAL_FETCH_DONE } from 'appRedux/constants';

export const handleError = errorMessage => {
  // console.log("Error****:", errorMessage);
  openNotificationWithIcon(errorMessage, `Error!`, 'error');
  return { type: GLOBAL_FETCH_DONE, payload: errorMessage };
};

export const openNotificationWithIcon = (
  message,
  title = null,
  type = 'success',
) => {
  const key = 'updatable';

  //Cannot read property 'status' of undefined
  if (!_.isEmpty(message) && !message.includes('Cannot read property')) {
    if (title != null) {
      notification[type]({
        key,
        message: title,
        description: message,
        duration: 5,
      });
    } else {
      notification[type]({
        key,
        description: message,
        duration: 5,
      });
    }
  }
};
