import React, { useState } from 'react';
import { Link } from '@reach/router';
import { InnerHeader, FrontHeader, Footer } from 'components';
import {
  Card,
  Row,
  Col,
  Tabs,
  Checkbox,
  Radio,
  Collapse,
  Divider,
  Button,
  Form,
  Input,
} from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import BgImage from 'images/activation-image.png';
import EkedcImage from 'images/ekedc.png';
import GenesisImage from 'images/genesis.png';
import JumiaImage from 'images/jumia.png';
import SamsungImage from 'images/samsung.png';
import SparImage from 'images/spar.png';
import StartimesImage from 'images/startimes.png';

import './Activation.scss';

const validationSchema = Yup.object({
  activationCode: Yup.number().required('User name is required'),
});

const Activation = () => {
  const [radio, setRadio] = useState(true);

  const changeRadio = () => {
    setRadio(!radio);
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      activationCode: '',
    },
    validationSchema,
    onSubmit(values) {
      const data = {
        ...values,
      };
      resetForm();
    },
  });

  return (
    <div>
      <div className="front-header-wrapper">
        <FrontHeader />
      </div>

      <Row className="auth-card-wrapper">
        <Col span={12} className="auth-card-section-1">
          <h2 className="title">Activation</h2>
          <p className="subtitle">
            Enter your Fidelity account no. or debit/credit to activate your
            membership
          </p>

          <Radio.Group
            className="fd-radios"
            name="radiogroup"
            onChange={changeRadio}
            defaultValue={true}>
            <Radio value={true}>Account No.</Radio>
            <Radio value={false}>Debit/Credit Card</Radio>
          </Radio.Group>

          <Form className="auth-card-form-wrapper">
            <Form.Item
              validateStatus={errors.activationCode ? 'error' : null}
              help={
                errors.activationCode && touched.activationCode
                  ? errors.activationCode
                  : null
              }>
              <Input
                className="fd-input"
                size="large"
                name="activationCode"
                onChange={handleChange}
                value={values.activationCode}
                placeholder="Enter Account Number"
              />
            </Form.Item>

            <Button
              className="fd-button secondary"
              type="submit"
              shape="round"
              size={'large'}
              onClick={handleSubmit}
              block>
              Continue
            </Button>
          </Form>
          <div className="auth-card-footer-extra">
            <Divider dashed></Divider>

            <p className="text">
              Have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </Col>
        <Col span={12} className="auth-card-section-2">
          <img
            className="page-image"
            src={BgImage}
            alt="background image"
            srcset=""
          />
        </Col>
      </Row>

      <div className="partners-section">
        <h2 className="title">Some Partners</h2>

        <p className="subtitle">
          To be successful you’ve got to work hard, to make history, simple,
          you’ve got to make it. I told you all this before, when you have a
          swimming pool.
        </p>

        <div className="partners-card-group">
          <div className="logo-wrapper">
            <img className="partners-logo" src={JumiaImage} alt="Jumia image" />

            <img className="partners-logo" src={SparImage} alt="SparImage" />
            <img
              className="partners-logo"
              src={SamsungImage}
              alt="SamsungImage "
            />
            <img
              className="partners-logo"
              src={StartimesImage}
              alt="StartimesImage"
            />
            <img
              className="partners-logo"
              src={GenesisImage}
              alt="GenesisImage"
            />
            <img className="partners-logo" src={EkedcImage} alt="EkedcImage" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Activation;
