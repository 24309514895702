import React from 'react';
import { Table } from 'antd';

function FaqTable(props) {
  const dataSource = [
    {
      number: '1',
      pro: 'Interbank Funds Transfer (Instant Banking)',
      pa: '1 point',
    },
    {
      number: '2',
      pro: 'Bills Payment',
      pa: '1 point',
    },
    {
      number: '3',
      pro: 'Debit Cards Issuance',
      pa: '5 point',
    },
    {
      number: '4',
      pro: 'Debit Cards On-Us Transactions (for Cards and ATM services team)',
      pa: '5 points per band',
    },
    {
      number: '5',
      pro: 'mVisa Transactions',
      pa: '1 point',
    },
    {
      number: '6',
      pro: 'Debit Cards POS/WEB',
      pa: '1 point',
    },
    {
      number: '7',
      pro: 'Monthly Average Balance (Savings and Current Account)',
      pa:
        '1 point for every Ten Thousand naira monthly average balance with a cap of Five hundred Thousand naira i.e 1 point = N10,000 with a cap of 50 points',
    },
  ];

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'PRODUCT',
      dataIndex: 'pro',
      key: 'pro',
    },
    {
      title: 'Points Allotted',
      dataIndex: 'pa',
      key: 'pa',
    },
  ];

  return (
    <div>
      <Table
        className="faq-table"
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
}

export default FaqTable;
