import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Input, DatePicker, Button } from 'antd';

import { AccountsVerticalMenu, PointBalance } from 'components';
import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';
import { getOrders } from '../../appRedux/action/Orders';
import numberFormatter from 'number-formatter';
import { pointFormat } from 'helpers';
import { LIMIT } from 'appRedux/constants';
import _ from 'lodash';
import moment from 'moment';

//
// {
//   title: "Approval Status",
//     dataIndex: "approvalStatus",
//   render: (approvalStatus, original) => (
//   <span>
//           {(() => {
//             let color =
//               original.approvalStatus === "Approved" ? "#00bd00" : (original.approvalStatus === 'Pending') ? "#f7b500" : "#ff5722";
//             return (
//               <p style={{color}}>{original.approvalStatus.toUpperCase()}</p>
//             );
//           })()}
// Checkedout Delivered Failed

const columns = [
  {
    title: 'Order No.',
    dataIndex: 'orderNumebr',
    key: 'orderNumebr',
    render: (record, text) => (
      <>
        <span>{`${text.orderNumebr}`}</span>
      </>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    render: (record, text) => (
      <>
        {(() => {
          let color =
            text.orderStatus === 'Delivered'
              ? '#00bd00'
              : text.orderStatus === 'Checkedout'
              ? '#f7b500'
              : '#ff5722';
          return <p style={{ color }}>{text.orderStatus.toUpperCase()}</p>;
        })()}
        {/*{let color = text.orderStatus === "Delivered" ? "#00bd00" : (text.orderStatus === 'Checkedout') ? "#f7b500" : "#ff5722";}*/}
        {/*<span style={{color}}>{`${text.orderStatus}`}</span>*/}
      </>
    ),
  },
  {
    title: 'Points',
    dataIndex: 'points',
    key: 'points',
    render: (record, text) => (
      <>
        <span className="">{`${numberFormatter(
          pointFormat,
          text.points,
        )} pts`}</span>
      </>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    render: date => <>{moment(date).format('YYYY-MM-DD, h:mm:ss a')}</>,
  },
];

const sideMenu = [
  {
    title: 'Summary',
    isActive: false,
    url: '/summary',
  },
  {
    title: 'Point History',
    isActive: false,
    url: '/points-history',
  },
  {
    title: ' Order History',
    isActive: true,
    url: '/order-history',
  },
  {
    title: 'Profile',
    isActive: false,
    url: '/profile',
  },
  {
    title: 'Wishlist',
    isActive: false,
    url: '/wishlist',
  },
  {
    title: 'Logout',
    isActive: false,
    url: '/logout',
  },
];

const { Search } = Input;
const { RangePicker } = DatePicker;

const OrderHistory = props => {
  const [menu, setMenu] = useState(false);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [pagination, setPagination] = useState({});
  const [paginationSortCurrent, setPaginationSortCurrent] = useState(1);
  const { userId, points } = props.authUser;

  let { orders, loading } = props.orders;
  let dataSource = orders.items || [];
  useEffect(() => {
    props.getOrders({ UserId: userId, Take: LIMIT });
  }, []);

  const handleSearch = params => {
    if (_.isEmpty(params)) {
      props.getOrders({ UserId: userId, Take: LIMIT });
    } else {
      props.getOrders({ UserId: userId, Search: params, Take: LIMIT });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPaginationSortCurrent(pagination.current);
    pagination.current -= 1;
    paginateSort(start, end, pagination);
    setPagination(pager);
  };

  const paginateSort = (s, e, pag, pageSize) => {
    if (_.isEmpty(s) && _.isEmpty(e)) {
      props.getOrders({
        UserId: userId,
        // Take: pageSize,
        Take: LIMIT,
        Skip: LIMIT * pag.current,
        //Skip: pag.current,
      });
    } else {
      const pagCurrent = pag.current - 1;
      props.getOrders({
        UserId: userId,
        // Take: pageSize,
        Take: LIMIT,
        Skip: pag.current === 1 ? 0 : LIMIT * pagCurrent,
        //Skip: LIMIT * current,
        //Skip: pag.current,
        DateFrom: s,
        DateTo: e,
      });
    }
  };

  function onDateChange(value, dateString) {
    setStart(dateString[0]);
    setEnd(dateString[1]);
    paginateSort(dateString[0], dateString[1], 1, LIMIT);
  }

  const pagData = {
    // position: ['topRight'],
    showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
    total: orders.total,
    current: paginationSortCurrent,
    pageSize: LIMIT,
  };

  return (
    <main className="giftcard-view-section">
      <div className="accounts-container">
        <Button
          onClick={() => setMenu(true)}
          color="secondary"
          className="tstyle"
          size={'large'}
          shape="round">
          {' '}
          Account Side Menu{' '}
        </Button>
        <Row justify="end">
          <Col>
            <PointBalance />
          </Col>
        </Row>

        <Row className="account-columns" gutter={64}>
          <Col
            className={`account-column-1 ${menu &&
              'mobile_menu_profile_holder'}`}
            span={6}>
            <AccountsVerticalMenu menuList={sideMenu} />
          </Col>
          <Col
            className="account-column-2"
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 18 }}>
            <Row className="content-wrapper">
              <Col span={24}>
                <div className="history-header">
                  <Search
                    className="search"
                    placeholder="input search text"
                    onSearch={value => handleSearch(value)}
                  />
                  <div className={'date_range_holder'}>
                    <RangePicker
                      format="YYYY-MM-DD HH:mm"
                      onChange={onDateChange}
                    />
                  </div>
                </div>
                <Table
                  className="history-table"
                  rowClassName="history-table-row"
                  rowKey={record => record.orderNumebr}
                  size="middle"
                  dataSource={dataSource}
                  columns={columns}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        navigate(`/order/${record.orderNumebr}`);
                        // window.location.href = `${window.location.origin}${window.location.pathname}#/order/${record.orderNumebr}`;
                      },
                    };
                  }}
                  onChange={handleTableChange}
                  pagination={pagData}
                  loading={loading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </main>
  );
};

const mapStateToProps = ({ auth, orders }) => ({
  authUser: auth.authUser,
  orders,
});

const mapDispatchToProps = {
  getOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
