import React, { useState } from 'react';
import { Link } from '@reach/router';
import LoadingBar from 'react-redux-loading-bar';

import { Logo } from 'components';

import './InnerHeader.scss';
import NavInterface from '../../NavInterface';
import { Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';

function InnerHeader(props) {
  return (
    <div className="header-holder">
      <LoadingBar
        style={{
          backgroundColor: '#49c33a',
          height: '5px',
          // position: 'fixed',
          // top: 0,
          zIndex: 999999999999,
        }}
      />

      {/*<header className="header">*/}
      {/*    <div*/}
      {/*        className="container"*/}
      {/*        style={{display: 'flex', justifyContent: 'space-between'}}>*/}
      <Navbar className="header navbar-light" expand="md">
        <div className="container">
          <NavInterface />
          {/*<Logo style={{marginTop: 20, marginLeft: 0}}/>*/}
        </div>
      </Navbar>
      {/*    </div>*/}
      {/*</header>*/}
    </div>
  );
}

export default InnerHeader;
