import React, { useEffect, useState } from 'react';
import {
  clearSession,
  clearSessionWithParams,
  getSessionWithKey,
} from '../../appRedux/store/cookies';
import LinkComponent from '../../route/LinkComponent';
import { Button, notification, Result } from 'antd';
import { redirectToCart } from '../../route/Redirect';
import { NOTIFICATION_DURATION } from '../../appRedux/constants';
import _ from 'lodash';

function FailedPayment(props) {
  return (
    <div>
      <div>
        <div className="container error-container">
          <div style={{ marginTop: 30, display: 'block' }}>
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-md-6  text-center">
                <div>
                  <>
                    <Result
                      status="error"
                      title="Error processing payment"
                      subTitle="Sorry, Your payment failed!"
                      extra={
                        <LinkComponent to={'/'}>
                          <Button
                            className="fd-button primary"
                            type="link"
                            shape="round">
                            Back to Homepages
                          </Button>
                        </LinkComponent>
                      }
                    />
                  </>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FailedPayment;
