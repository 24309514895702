import React, { useState } from 'react';
import { Link } from '@reach/router';
import { Row, Col, Collapse } from 'antd';

import { DownOutlined } from '@ant-design/icons';

import './HomepageFAQ.scss';
import FaqTable from './FaqTable';

const { Panel } = Collapse;

const HomepageFAQ = () => {
  const [open, setOpen] = useState(false);
  const tab = params => {
    setOpen(params);
  };

  const faqList = [
    {
      index: 1,
      title: 'Does Fidelity Bank have a Rewards/Loyalty System in place?',
      content: `Yes`,
    },
    {
      index: 2,
      title: 'How do I participate in this Reward/Loyalty system?',
      content: `Simply open a fidelity bank account and do your transactions to become eligible to participate. `,
    },
    {
      index: 3,
      title: 'Who can benefit from this point-based loyalty system?',
      content: `Staff and customers (To commence with a pilot test - This phase is designed for staff - core and non-core staff)`,
    },
    {
      index: 4,
      title: 'How does the Point Based Loyalty platform work?',
      content: `It is designed to generate and assign points on specific banking services carried out by customers/staff.`,
    },
    {
      index: 5,
      title:
        'What specific banking services will the point based loyalty scheme commence with?',
      content: `Interbank Funds Transfer (Instant Banking), Bills Payments,Debit Cards Issuance, Debit Cards On-Us Transactions,  mVisaTransactions,   Debit   Cards   POS/WEB   transactions,   MonthlyAverage Balance (Savings and Current Account)`,
    },
    {
      index: 6,
      title: 'How does the Point Based Loyalty platform work?',
      content: `The   Point-Based   Loyalty   System   is   designed   to   allow customers   earn   points   from   carrying   out   daily   banking transactions via e-channels, retaining funds in their accounts,reactivating dormant accounts etc, these would translate into points/rewards.`,
    },
  ];

  const faqListMore = [
    {
      index: 7,
      title: 'How would staff/customers redeem their points accrued?',
      content: `Points   accrued   are   redeemed   from   a   wide   range   of designated stores.`,
    },
    {
      index: 8,
      title:
        'What designated stores/merchant locations can loyalty points be redeemed?',
      content: `Jumia, Hubmart, Spar, Game, Samsung, FilmHouse Cinemas,Grocery Bazaar, Chicken Paradise, Smile, EKEDC, StarTimes and ahost of other outlets nationwide.`,
    },
    {
      index: 9,
      title:
        'When can customers/staff start redeeming their points that have been accrued?',
      content: `Customers/staff will be eligible to redeem their points/reward(s) after a certain number of points have been accumulated (as from 100 points)`,
    },
    {
      index: 10,
      title:
        'Will individual and corporate customers benefit from the scheme in this initial kick-off phase/stage?',
      content: `No, at the initial kick-off stage, only individual customers(including all staff) will benefit. Businesses/corporates would be included soon.`,
    },
    {
      index: 11,
      title: 'How are the points accumulated/accrued by staff and customers?',
      content: ``,
    },
    {
      index: 12,
      title: 'How long are the accrued points valid?',
      content: `One (1) calendar year`,
    },
    {
      index: 13,
      title: 'Can my points be converted to cash?',
      content: `No, you can only redeem your points at designated centers through purchasing of goods or services`,
    },
    {
      index: 14,
      title:
        'What if my points are not enough to make a purchase, can I complement it with cash at the redemption center?',
      content: `Yes, you can make up/complement your points to purchase your desired goods and services by purchasing additional points on the platform directly`,
    },
  ];

  return (
    <div className="container faq-wrapper my-5">
      <Row>
        {faqList.map((elem, index) => (
          <Col
            className={'w100'}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            key={index}
            style={{ padding: 10, width: '100%' }}>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              className="home-faq-collapse"
              expandIconPosition="right">
              <Panel
                className="home-faq-panel"
                header={<h4 className="collapse-header">{elem.title}</h4>}
                key="1">
                <p className="collapse-body">{elem.content}</p>
              </Panel>
            </Collapse>
          </Col>
        ))}
      </Row>

      {open ? (
        <div>
          <Row style={{ marginBottom: 40 }}>
            {faqListMore.map((elem, index) => (
              <Col
                className={'w100'}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                key={index}
                style={{ padding: 10, width: '100%' }}>
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  className="home-faq-collapse"
                  expandIconPosition="right">
                  <Panel
                    className="home-faq-panel"
                    header={<h4 className="collapse-header">{elem.title}</h4>}
                    key="1">
                    {elem.index === 11 ? (
                      <div className="collapse-body">
                        {' '}
                        <FaqTable />{' '}
                      </div>
                    ) : (
                      <p className="collapse-body">{elem.content}</p>
                    )}
                  </Panel>
                </Collapse>
              </Col>
            ))}
          </Row>
          <span onClick={() => tab(false)} className="my-5 faq-link pointer">
            Close FAQs
          </span>
        </div>
      ) : (
        <span onClick={() => tab(true)} className="my-5 faq-link pointer">
          See all FAQs
        </span>
      )}
    </div>
  );
};

export default HomepageFAQ;
