import React from 'react';
import { Location } from '@reach/router';
import { RouteComponent } from './route';

function App() {
  return (
    <Location>
      {({ location }) => <RouteComponent location={location} />}
    </Location>
  );
}

export default App;
