import {
  FETCH_ORDERS_FAILED,
  FETCH_ORDERS_START,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDER_FAILED,
  FETCH_ORDER_START,
  FETCH_ORDER_SUCCESS,
  GET_ORDERS,
} from '../constants';
import api from '../api';

export const getOrders = payload => {
  return dispatch => {
    dispatch({ type: FETCH_ORDERS_START });

    api
      .get(GET_ORDERS, {
        params: payload,
      })
      .then(({ data }) => {
        dispatch({ type: FETCH_ORDERS_SUCCESS, payload: data });
      })
      .catch(e => {
        dispatch({ type: FETCH_ORDERS_FAILED });

        // callback({ error: true, ...e });
      });
  };
};

export const getOrder = id => {
  return dispatch => {
    dispatch({ type: FETCH_ORDER_START });

    api
      .get(GET_ORDERS + `/${id}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_ORDER_SUCCESS, payload: data });
      })
      .catch(e => {
        dispatch({ type: FETCH_ORDER_FAILED });

        // callback({ error: true, ...e });
      });
  };
};
