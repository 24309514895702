import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { getProfile } from '../../appRedux/action/Auth';
import numberFormatter from 'number-formatter';
import { handleNotifyOnly } from 'appRedux/api';
import { FETCH_ERROR_MESSAGE } from 'appRedux/constants';
import { pointFormat } from 'helpers';
import { Col, Row, Spin } from 'antd';

const PointBalance = props => {
  const { points } = props.authUser;

  useEffect(() => {
    props.getProfile(response => {
      response.error &&
        handleNotifyOnly({ message: FETCH_ERROR_MESSAGE }, () => {});
    });
  }, []);

  return (
    <>
      <div className="points-counter">
        <span className="text">Point balance: </span>
        <span className="numbers">
          &nbsp;{' '}
          {props.profile_loading ? (
            <Spin />
          ) : (
            `${numberFormatter(pointFormat, points)}pts`
          )}
        </span>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  authUser: auth.authUser,
  profile_loading: auth.profile_loading,
});

const mapDispatchToProps = {
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(PointBalance);
