import React, { useState } from 'react';
import Footer from '../../components/Footer';
import InnerHeader from '../../components/Header/InnerHeader';
import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'reactstrap';
import { AccountsVerticalMenu } from '../../components';
import { useDispatch } from 'react-redux';
import { OPEN_MENU } from '../../appRedux/constants';

// const Inner = (Component, props) => {
//     // const dispatch = useDispatch();
//
//     const innerProps = () => {
//         return (
//             <>
//                 <InnerHeader/>
//                 <div className={`container`}>
//                     <div>
//                         <Component {...props} {...innerProps} />
//                     </div>
//                 </div>
//                 <Footer/>
//             </>)
//     }
//     return innerProps
// }

const Inner = (Component, props) => innerProps => (
  <>
    <InnerHeader />
    <div className="container top-fixed">
      <Component {...props} {...innerProps} />
    </div>
    <Footer />
  </>
);

export default Inner;
