import {
  COMPLETE_PAYMENT_FAILED,
  COMPLETE_PAYMENT_START,
  COMPLETE_PAYMENT_SUCCESS,
} from '../constants';

const INIT_STATE = {
  payment: {},
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMPLETE_PAYMENT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case COMPLETE_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        payment: action.payload,
      };
    }
    case COMPLETE_PAYMENT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
