import { navigate } from '@reach/router';
import {
  clearSessionWithParams,
  getSessionWithKey,
} from '../appRedux/store/cookies';

export const redirectActivation = () => {
  navigate('/activation');
  // window.location.href = `${window.location.origin}${window.location.pathname}#/activation`;
};

export const redirectVerification = () => {
  navigate('/verification');
  // window.location.href = `${window.location.origin}${window.location.pathname}#/verification`;
};

export const redirectToActivation = () => {
  redirectActivation();
};

export const redirectToCreatePassword = () => {
  navigate('/create-password');
  //window.location.href = `${window.location.origin}${window.location.pathname}#/create-password`;
};

export const redirectToProfile = () => {
  navigate('/profile');
  //window.location.href = `${window.location.origin}${window.location.pathname}#/profile`;
};

export const redirectToCompleted = () => {
  navigate('/order-completed');
  //window.location.href = `${window.location.origin}${window.location.pathname}#/order-completed`;
};

export const redirectToLogin = () => {
  navigate('/login');
  // window.location.href = `${window.location.origin}${window.location.pathname}#/login`;
};

export const redirectToGiftCards = () => {
  navigate('/gift-cards');
  //window.location.href = `${window.location.origin}${window.location.pathname}#/gift-cards`;
};

export const redirectToAirtimes = () => {
  navigate('/airtimes');
  //window.location.href = `${window.location.origin}${window.location.pathname}#/airtimes`;
};

export const redirectToProducts = () => {
  navigate('/products');
  //window.location.href = `${window.location.origin}${window.location.pathname}#/products`;
};

export const redirectToPayment = () => {
  navigate('/payment');
  //window.location.href = `${window.location.origin}${window.location.pathname}#/payment`;
};

export const redirectToHome = () => {
  navigate('/');
  //window.location.href = `${window.location.origin}${window.location.pathname}#/`;
};

export const redirectToCart = () => {
  navigate('/cart');
  // window.location.href = `${window.location.origin}${window.location.pathname}#/cart`;
};

export const redirectToParams = () => {
  const path = getSessionWithKey('urlKeep');
  clearSessionWithParams('urlKeep');
  navigate(path);
  //window.location.href = `${window.location.origin}${window.location.pathname}#${path}`;
};
