import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import {
  UPDATE_CART,
  FETCH_CART_FAILED,
  FETCH_CART_START,
  FETCH_CART_SUCCESS,
  REMOVE_CART_ITEM_FAILED,
  REMOVE_CART_ITEM_START,
  REMOVE_CART_ITEM_SUCCESS,
  EDIT_CART_ITEM_FAILED,
  EDIT_CART_ITEM_START,
  EDIT_CART_ITEM_SUCCESS,
  CLEAR_CART_FAILED,
  CLEAR_CART_START,
  CLEAR_CART_SUCCESS,
  CART_CHECKOUT_FAILED,
  CART_CHECKOUT_START,
  CART_CHECKOUT_SUCCESS,
  CART_PAYMENT_INFO_START,
  CART_PAYMENT_INFO_SUCCESS,
  CART_PAYMENT_INFO_FAILED,
} from '../constants';

const INIT_STATE = {
  cart: [],
  payment_info: {},
  payment_loading: false,
  loading: false,
};

const CartReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case UPDATE_CART: {
    //   return {
    //     ...state,
    //     loading: true,
    //     cart: action.payload,
    //   };
    // }

    case CART_CHECKOUT_START:
    case EDIT_CART_ITEM_START:
    case REMOVE_CART_ITEM_START: {
      return {
        ...state,
      };
    }

    case CLEAR_CART_START:
    case FETCH_CART_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CART_PAYMENT_INFO_FAILED:
    case CART_PAYMENT_INFO_START: {
      return {
        ...state,
        payment_loading: false,
      };
    }
    case CART_PAYMENT_INFO_SUCCESS: {
      return {
        ...state,
        payment_loading: true,
        payment_info: action.payload,
      };
    }

    case CART_CHECKOUT_SUCCESS: {
      return {
        ...state,
        cart: action.payload,
        loading: false,
      };
    }
    case CLEAR_CART_SUCCESS:
    case EDIT_CART_ITEM_SUCCESS:
    case REMOVE_CART_ITEM_SUCCESS:
    case FETCH_CART_SUCCESS: {
      return {
        ...state,
        cart: action.payload,
        loading: false,
      };
    }

    case CART_CHECKOUT_FAILED:
    case CLEAR_CART_FAILED:
    case EDIT_CART_ITEM_FAILED:
    case REMOVE_CART_ITEM_FAILED:
    case FETCH_CART_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

// export default CartReducer;

const persistConfig = {
  key: 'cart',
  storage,
  whitelist: ['cart'],
};

export default persistReducer(persistConfig, CartReducer);
