import React, { useEffect } from 'react';
import { Row, Col, Skeleton } from 'antd';

import { AccountsVerticalMenu, Loading, PointBalance } from 'components';
import { connect } from 'react-redux';
import { getOrder } from '../../appRedux/action/Orders';
import _ from 'lodash';
import { Link, navigate } from '@reach/router';

import GenesisImage from 'images/genesis.png';
import SparImage from 'images/spar.png';
import StartimesImage from 'images/startimes.png';

import './OrderHistoryView.scss';
import { pointFormat } from 'helpers';
import numberFormatter from 'number-formatter';

const sideMenu = [
  {
    title: 'Summary',
    isActive: false,
    url: '/summary',
  },
  {
    title: 'Point History',
    isActive: false,
    url: '/points-history',
  },
  {
    title: ' Order History',
    isActive: true,
    url: '/order-history',
  },
  {
    title: 'Profile',
    isActive: false,
    url: '/profile',
  },
  {
    title: 'Wishlist',
    isActive: false,
    url: '/wishlist',
  },
  {
    title: 'Logout',
    isActive: false,
    url: '/logout',
  },
];

const OrderHistoryView = props => {
  let { order, loadingOrder } = props.orders;

  useEffect(() => {
    props.getOrder(props.orderNo);
  }, []);

  const statusClass = status => {
    let classText = '';
    status = status.replace(/ /g, '');
    switch (status.toLowerCase()) {
      case 'delivered':
        classText = 'delivered';
        break;

      case 'canceled':
        classText = 'failed';
        break;

      default:
        classText = 'pending';
        break;
    }
    return classText;
  };

  return (
    <main className="giftcard-view-section">
      <div className="accounts-container">
        <Row justify="end">
          <Col>
            <PointBalance />
          </Col>
        </Row>

        <Row className="account-columns" gutter={64}>
          <Col className="account-column-1" span={6}>
            <AccountsVerticalMenu menuList={sideMenu} />
          </Col>
          <Col className="account-column-2" span={18}>
            <Row className="content-wrapper">
              <Col span={24}>
                {_.isEmpty(order) || loadingOrder ? (
                  <Loading />
                ) : (
                  <>
                    <div className="profile-name-container">
                      <div className="profile-name-wrapper">
                        <h2 className="title">Order No.{props.orderNo}</h2>
                      </div>
                    </div>
                    <div className="stats-wrapper">
                      <div className="order-details-wrapper">
                        <div className="image">
                          {/* placeholder while image is loading */}
                          {false ? (
                            <span className="skeleton-container">
                              <Skeleton.Input
                                style={{ width: 140 }}
                                shape="square"
                                active
                                size="large"
                              />
                              <Skeleton.Input
                                style={{ width: 140 }}
                                shape="square"
                                active
                                size="large"
                              />
                            </span>
                          ) : (
                            <img
                              className="order-image"
                              src={order.imageUrl}
                              alt="order image"
                            />
                          )}
                        </div>
                        <div className="description">
                          <h4 className="title ">{order.description}</h4>
                          <div className="points">
                            {numberFormatter(pointFormat, order.points)} pts
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="earnings-title">
                      <h4 className="earnings">Delivery Details</h4>
                    </div>
                    <div className="details-wrapper">
                      <h4 className="order-subtitle">Recipient’s Full Name</h4>
                      <h3 className="order-title">{order.fullName}</h3>
                      <h4 className="order-subtitle">Recipient’s Email</h4>
                      <h3 className="order-title">{order.email}</h3>
                      <h4 className="order-subtitle">Personal Message</h4>
                      <p className="order-paragraph">{order.personalMessage}</p>
                      <h4 className="order-subtitle mt-3">Status</h4>

                      {/* 3 status colors className: "delivered", "pending", "failed" */}
                      {/* check .scss file, you can change to API response */}
                      <h3
                        className={`order-title ${statusClass(
                          order.orderStatus,
                        )}`}>
                        {order.orderStatus}
                      </h3>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </main>
  );
};

const mapStateToProps = ({ auth, orders }) => ({
  authUser: auth.authUser,
  orders,
});

const mapDispatchToProps = {
  getOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryView);
