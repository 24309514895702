import axios from 'axios';
import { getSession } from 'appRedux/store/cookies';
import _ from 'lodash';
import { notification } from 'antd';
import {
  FETCH_ERROR_MESSAGE,
  GET_REFRESH_TOKEN,
  NOTIFICATION_DURATION,
} from './constants';
// import {useDispatch} from 'react-redux';
import CryptoJS from 'crypto-js';

import { getProfileRefreshToken, onUnauthenticated } from './action/Auth';
import { getSessionWithKey, setSessionWithValue } from './store/cookies';
import { redirectToLogin } from '../route/Redirect';

// const dispatch = useDispatch;
export const settings = {
  //baseURL: `http://loyalty.suregifts.com.ng/customer/api`,
  //timeout: 1000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    // 'accept-encoding': 'gzip, deflate, br',
    // 'sec-fetch-mode': 'cors',
    // 'sec-fetch-site': 'same-origin',
    Accept: 'application/json',
    // 'Content-Type': 'application/json',
    // 'Content-Type': 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Expose-Headers': 'access_token',
    //  // 'Access-Control-Request-Headers': 'authorization',
    //  'Authorization': `Bearer ${getSession()}`,
    //  'common': {
    //      'access-token': `Bearer ${getSession()}`,
    //      'Authorization': `Bearer ${getSession()}`,
    //  }
  },
};

const instance = axios.create(settings);
const token = getSession();
if (token != null && !_.isEmpty(token)) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${getSession()}`;
}

const getURL = url => {
  const baseURL = sessionStorage.getItem('BASE_URL');
  return `${baseURL}${url}`;
};

// const onResponseSuccess = response => {
//   // console.log('response success', response);
//   return response;
// };
//
// const onResponseFail = error => {
//   // console.log('response error', error);
//   const status = error.status || error.response.status;
//   if (status === 403 || status === 401) {
//     const checkInUse = _.isEmpty(getSessionWithKey('__checkInUse'))
//       ? false
//       : getSessionWithKey('__checkInUse');
//     if (checkInUse === false) {
//       return onUnauthenticated();
//     }
//   } else {
//     return Promise.reject(error);
//   }
// };

const onResponseSuccess = response => {
  if (!_.isEmpty(response.headers.access_token)) {
    setToken(response.headers.access_token);
  }
  return response;
};

const onResponseFail = error => {
  const status = error.status || error.response.status;
  if (status === 403 || status === 401) {
    const checkInUse = _.isEmpty(getSessionWithKey('__checkInUse'))
      ? false
      : getSessionWithKey('__checkInUse');
    if (checkInUse === false) {
      setSessionWithValue(true, '__checkInUse');
      return redirectToLogin();
      // return onUnauthenticated();
    }
  } else {
    return Promise.reject(error);
  }
};

instance.interceptors.response.use(onResponseSuccess, onResponseFail);

export default {
  get(url, request) {
    return instance
      .get(getURL(url), request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response.data, error.response));
  },
  post(url, request) {
    return instance
      .post(getURL(url), request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response.data, error.response));
  },
  put(url, request) {
    return instance
      .put(getURL(url), request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response.data, error.response));
  },
  patch(url, request) {
    return instance
      .patch(getURL(url), request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response.data, error.response));
  },
  delete(url, request) {
    return instance
      .delete(getURL(url), request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response.data, error.response));
  },
};

export const setToken = token => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

async function catchResponse(error) {
  if (error.response.status === 401 || error.response.status === 403) {
   // console.log('datadatadatadatadata', error.response.status);
    // return await get(GET_REFRESH_TOKEN).then(({data}) => {
    //   console.log('datadatadatadatadata', data);
    //   // handleUserAccessGranted(data, 'login', dispatchAbleResponse => {
    //   //     dispatch(dispatchAbleResponse);
    //   //     dispatch({type: PROFILE_DONE});
    //   //     return callback(data);
    //   // });
    // })
  } else {
    return Promise.reject(error.response.data, error.response);
  }
}

/**
 * @ref https://www.thetopsites.net/article/52986962.shtml
 * @param errorInstance
 * @param setErrors
 */
export const handleFieldError = (errorInstance, setErrors) => {
  let errors = {};
  for (let key in errorInstance) {
    errors[key] =
      errorInstance[key].length === 1
        ? errorInstance[key][0]
        : errorInstance[key].join(` & `);
  }
  let errorCount = Object.keys(errors).length;
  if (errorCount > 0) {
    setErrors(errors);
  }
};

export const handleNotify = (res, setSubmitting, callback) => {
  const message = res.message;

  notification['error']({
    message: 'Validation error!',
    description: message,
    duration: NOTIFICATION_DURATION,
  });

  setSubmitting(false);
  return callback();
};

export const handleNotifyOnly = (res, callback) => {
  const message = res.message;

  if (message !== FETCH_ERROR_MESSAGE) {
    notification['error']({
      message: 'Validation error!',
      description: message,
      duration: NOTIFICATION_DURATION,
    });
  }

  return callback();
};

export const encryptedPassword = password => {
  let Securekey = CryptoJS.enc.Utf8.parse(sessionStorage.getItem('KEY')); //sessionStorage.getItem('KEY');
  let iv = CryptoJS.enc.Utf8.parse(sessionStorage.getItem('IV')); //sessionStorage.getItem('IV');
  let encrypted = CryptoJS.AES.encrypt(password, Securekey, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encrypted.toString();
};
