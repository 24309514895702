import {
  UPDATE_CART,
  FETCH_CART_FAILED,
  FETCH_CART_START,
  FETCH_CART_SUCCESS,
  REMOVE_CART_ITEM_FAILED,
  REMOVE_CART_ITEM_START,
  REMOVE_CART_ITEM_SUCCESS,
  EDIT_CART_ITEM_FAILED,
  EDIT_CART_ITEM_START,
  EDIT_CART_ITEM_SUCCESS,
  CLEAR_CART_FAILED,
  CLEAR_CART_START,
  CLEAR_CART_SUCCESS,
  CART_CHECKOUT_FAILED,
  CART_CHECKOUT_START,
  CART_CHECKOUT_SUCCESS,
  CART_PAYMENT_INFO,
  CART_PAYMENT_INFO_SUCCESS,
  CART_PAYMENT_INFO_FAILED,
  CART_PAYMENT_INFO_START,
} from '../constants';
import api from '../api';
import { openNotificationWithIcon } from './Common';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { setSessionWithValue } from '../store/cookies';
import { redirectToCompleted, redirectToPayment } from 'route/Redirect';
import LaddaButton from 'react-ladda';
import React from 'react';

export const updateCart = item => {
  return dispatch => {
    //dispatch({type: GLOBAL_FETCH_START});
  };
};

export const fetchCart = data => {
  return dispatch => {
    dispatch({ type: FETCH_CART_START });
    const url = `/Orders/GetCart`;

    api
      .get(url)
      .then(({ data }) => {
        dispatch({
          type: FETCH_CART_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        // openNotificationWithIcon(
        //     'Something went wrong. Try again',
        //     `Error!`,
        //     'error',
        // );
        // dispatch({type: FETCH_CART_FAILED});
      });
  };
};

export const removeItemFromCart = (id, callback) => {
  return dispatch => {
    dispatch({ type: REMOVE_CART_ITEM_START });
    dispatch(showLoading());
    const url = `/Orders/RemoveItem`;
    const data = { itemId: id };
    const params = JSON.stringify(data, null, 2);

    api
      .post(url, params)
      .then(({ data }) => {
        openNotificationWithIcon(
          'Continue shopping or move to checkout',
          `Product was removed from cart!`,
          'success',
        );
        dispatch({ type: REMOVE_CART_ITEM_SUCCESS, payload: data });
        dispatch(hideLoading());
        return callback();
      })
      .catch(error => {
        openNotificationWithIcon(
          error.message,
          'Item cannot be removed. Try again',
          'error',
        );
        dispatch({ type: REMOVE_CART_ITEM_FAILED });
        dispatch(hideLoading());
        return callback();
      });
  };
};

export const editItemFromCart = (data, callback) => {
  return dispatch => {
    dispatch({ type: EDIT_CART_ITEM_START });
    dispatch(showLoading());
    const url = `/Orders/EditItem`;
    const params = JSON.stringify(data, null, 2);

    api
      .post(url, params)
      .then(({ data }) => {
        openNotificationWithIcon(
          'Continue shopping or move to checkout',
          `Item quantity has been updated!`,
          'success',
        );
        dispatch({ type: EDIT_CART_ITEM_SUCCESS, payload: data });
        dispatch(hideLoading());
        return callback(true);
      })
      .catch(error => {
        openNotificationWithIcon(
          error.message,
          `Item quantity cannot be updated`,
          'error',
        );
        dispatch({ type: EDIT_CART_ITEM_FAILED });
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};

export const clearItemsFromCart = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CART_START });
    dispatch(showLoading());
    const url = `/Orders/ClearCart`;

    api
      .post(url)
      .then(({ data }) => {
        openNotificationWithIcon(
          'Empty cart. Add items to cart',
          `Successfully cleared`,
          'success',
        );
        dispatch({ type: CLEAR_CART_SUCCESS, payload: data });
        dispatch(hideLoading());
      })
      .catch(error => {
        openNotificationWithIcon(
          'Cart cannot be cleared at this moment. Try again',
          `Something went wrong`,
          'error',
        );
        dispatch({ type: CLEAR_CART_FAILED });
        dispatch(hideLoading());
      });
  };
};

export const checkOutCart = (totalCartPoint, pointBalance, activationCode, callback) => {
  return dispatch => {
    dispatch({ type: CART_CHECKOUT_START });
    dispatch(showLoading());
    const url = `/Orders/CheckOut`;
    const params = JSON.stringify({ paymentMethodId: 1, 'ActivationCode': activationCode }, null, 2); // this is a normal checkout

    // { Wallet = 1, FidelityPay = 2, BankAccount=3 }
    api
      .post(url, params)
      .then(resp => {
        if (totalCartPoint > pointBalance) {
          openNotificationWithIcon(
            'Thank you for your order.',
            `Please go ahead to payment`,
            'success',
          );
        } else {
          openNotificationWithIcon(
            'Thank you for your order.',
            `Successful Checkout`,
            'success',
          );
        }
        dispatch({ type: CART_CHECKOUT_SUCCESS, payload: resp.data });
        dispatch(hideLoading());
        if (resp.data.outstandingAmount > 0) {
          setSessionWithValue(resp.data, '___order_purchase');
          redirectToPayment();
        } else {
          setSessionWithValue(resp.data, '___order_completed');
          redirectToCompleted();
        }
        return callback(resp.data);
      })
      .catch(error => {
        dispatch({ type: CART_CHECKOUT_FAILED });
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};

export const SendCheckOutOtp = (callback) => {
  return dispatch => {
    dispatch(showLoading());
    const url = `/Orders/SendCheckoutOtp`;

    api.post(url, {}).then(resp => {
      dispatch(hideLoading());
      return callback(resp.data);
    }).catch(error => {
      dispatch(hideLoading());
      return callback({ error: true, ...error });
    });
  };
};

export const checkOutCartFromPayment = callback => {
  return dispatch => {
    dispatch({ type: CART_CHECKOUT_START });
    dispatch(showLoading());
    const params = JSON.stringify({ paymentMethodId: 2 }, null, 2); // this is a normal checkout
    const url = `/Orders/CheckOut`;

    api
      .post(url, params)
      .then(resp => {
        dispatch({ type: CART_CHECKOUT_SUCCESS, payload: resp.data });
        dispatch(hideLoading());
        return callback(resp.data);
      })
      .catch(error => {
        dispatch({ type: CART_CHECKOUT_FAILED });
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};

export const checkOutCartFromAccount = callback => {
  return dispatch => {
    dispatch({ type: CART_CHECKOUT_START });
    dispatch(showLoading());
    const params = JSON.stringify({ paymentMethodId: 3 }, null, 2); // this is a normal checkout
    const url = `/Orders/CheckOut`;

    api
      .post(url, params)
      .then(resp => {
        dispatch({ type: CART_CHECKOUT_SUCCESS, payload: resp.data });
        dispatch(hideLoading());
        return callback(resp.data);
      })
      .catch(error => {
        dispatch({ type: CART_CHECKOUT_FAILED });
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};

export const checkOutCartAccount = (data, callback) => {
  return dispatch => {
    dispatch(showLoading());
    const url = `/Payments/SendPaymentVerificationCode`;

    api
      .post(url, data)
      .then(resp => {
        dispatch(hideLoading());
        return callback(resp.data);
      })
      .catch(error => {
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};

export const checkOutCartVerifyPayment = (data, callback) => {
  return dispatch => {
    dispatch(showLoading());
    const url = `/Payments/VerifyPaymentCode`;

    api
      .post(url, data)
      .then(resp => {
        dispatch(hideLoading());
        return callback(resp.data);
      })
      .catch(error => {
        dispatch(hideLoading());
        return callback({ error: true, ...error });
      });
  };
};

export const getPaymentInfo = callback => {
  return dispatch => {
    dispatch({ type: CART_PAYMENT_INFO_START });

    api
      .get(CART_PAYMENT_INFO)
      .then(resp => {
        dispatch({ type: CART_PAYMENT_INFO_SUCCESS, payload: resp.data });
        return callback(resp.data);
      })
      .catch(error => {
        dispatch({ type: CART_PAYMENT_INFO_FAILED });
        return callback({ error: true, ...error });
      });
  };
};
