import React from 'react';
import './Logo.scss';
import LinkComponent from '../../route/LinkComponent';

const Logo = () => {
  return (
    <LinkComponent to={'/'}>
      <span className="logo" />
    </LinkComponent>
  );
};

export default Logo;
