import {
  FETCH_PRODUCTS_CATEGORIES_FAILED,
  FETCH_PRODUCTS_CATEGORIES_START,
  FETCH_PRODUCTS_CATEGORIES_SUCCESS,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_SINGLE_PRODUCT_START,
  FETCH_SINGLE_PRODUCT_SUCCESS,
  FETCH_SINGLE_PRODUCT_FAILED,
} from '../constants';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const INIT_STATE = {
  products: [],
  productsCategories: [],
  singleProduct: {},
  loading: false,
  loadingCategories: false,
  loadingSingleProduct: false,
};

const ProductsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    }
    case FETCH_PRODUCTS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_PRODUCTS_CATEGORIES_START: {
      return {
        ...state,
        loadingCategories: true,
      };
    }
    case FETCH_PRODUCTS_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loadingCategories: false,
        productsCategories: action.payload,
      };
    }
    case FETCH_PRODUCTS_CATEGORIES_FAILED: {
      return {
        ...state,
        loadingCategories: false,
      };
    }
    case FETCH_SINGLE_PRODUCT_START: {
      return { ...state, loadingSingleProduct: true };
    }
    case FETCH_SINGLE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loadingSingleProduct: false,
        singleProduct: action.payload,
      };
    }
    case FETCH_SINGLE_PRODUCT_FAILED: {
      return { ...state, loadingSingleProduct: false };
    }
    default:
      return state;
  }
};

const persistConfig = {
  key: 'products',
  storage,
  whitelist: ['products'],
};

export default persistReducer(persistConfig, ProductsReducer);
